import styled from '@emotion/styled'
import { FlexColumn } from './Flex'

export const FullPage = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.card.primary};
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  width: 100%;
`
