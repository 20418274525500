/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingSession } from '../models/BillingSession';
import type { Error } from '../models/Error';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountService {
    /**
     * Get billing information
     * Get billing information for the authenticated user
     * @returns BillingSession Billing information session
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2AccountGetBillingSession({
        returnUrl,
    }: {
        returnUrl?: string,
    }): CancelablePromise<BillingSession | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/billing',
            query: {
                'return_url': returnUrl,
            },
        });
    }
}
