import config from './config'
import * as Sentry from '@sentry/react'
import { ApiError } from '../api/coreapi'
import isEmpty from 'lodash/isEmpty'

const sanitizeBody = (body: any) => (body instanceof Blob ? 'Blob' : body)

const stringify = (obj: any) => JSON.stringify(obj, null, 2)

function toBreadcrumb(name: string, data: any): Sentry.Breadcrumb {
  return {
    type: 'error',
    category: name,
    level: 'error',
    message: stringify(data),
  }
}

const enrichScope = (scope: Sentry.Scope, name: string, obj: any | undefined) => {
  if (!isEmpty(obj)) {
    scope.addBreadcrumb(toBreadcrumb(name, obj))
  }
}

export const sentry = {
  init: () => {
    if (config.ENVIRONMENT !== 'localhost') {
      Sentry.init({
        dsn: config.SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampleRate: 1.0,
        environment: config.ENVIRONMENT,
        release: config.APP_VERSION,
        debug: config.IS_DEV_ENV,
        attachStacktrace: true,
        initialScope: {
          tags: {
            auth: config.AUTH_DOMAIN,
            api: config.API_DOMAIN,
          },
        },
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
        beforeSend: (event: Sentry.Event, hint: Sentry.EventHint) => {
          if (hint.originalException?.toString().includes('ChunkLoadError')) {
            return null
          }
          const mechanism = event.exception?.values?.[0]?.mechanism
          if (hint.originalException instanceof ApiError && mechanism?.type === 'onunhandledrejection') {
            return null
          }
          if (hint.originalException && mechanism?.handled === false) {
            return {
              ...event,
              breadcrumbs: [...(event.breadcrumbs || []), toBreadcrumb('exception', hint.originalException)],
            }
          }
          return event
        },
      })
    }
  },

  captureException: (error: any, tags: Record<string, string>, additionalInfo?: Record<string, any>) => {
    Sentry.withScope((scope) => {
      if (error instanceof ApiError) {
        const errorStatus = error.statusText || error.status.toString()
        tags = {
          ...tags,
          fullUrl: error.url,
          rawUrl: error.request.url,
          status: errorStatus,
        }
        enrichScope(scope, 'requestBody', sanitizeBody(error.request.body))
        enrichScope(scope, 'responseBody', sanitizeBody(error.body))
        scope.setFingerprint(['{{ default }}', error.request.url, errorStatus])
        scope.setTags(tags)
      }
      enrichScope(scope, 'enrichment', additionalInfo)
      enrichScope(scope, 'exception', error)
      Sentry.captureException(error)
    })
  },

  captureMessage: (message: string, tags: Record<string, string>, contexts: Record<string, any>) => {
    Sentry.withScope((scope) => {
      enrichScope(scope, 'context', contexts)
      Sentry.captureMessage(message, { tags })
    })
  },
}
