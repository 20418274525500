import * as React from 'react'
import { ReactElement, useCallback, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import styled from '@emotion/styled'
import { RefNameText, TextSmall } from '../base/TextStyle'
import { CopyIcon } from '../base/CopyIcon'
import { CopyToClipboardAction, useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { osName, OsTypes } from 'react-device-detect'
import config from '../../env/config'
import { FlexColumn, FlexRow, FlexRowStyle } from '../base/Flex'
import { IntroBody } from './IntroPage'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { Anchor } from '../base/Anchor'

interface TabPanelProps {
  isSelected: boolean
  content: ReactElement
}

const StyledTabs = styled(Tabs)`
  margin-bottom: 1rem;
  .Mui-selected {
    color: ${({ theme }) => theme.colors.blue.primary} !important;
  }
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.blue.primary};
  }
  .MuiButtonBase-root {
    ${FlexRowStyle};
    gap: 0.5rem;
  }
`

const DevIcon = styled.i`
  display: inline;
  font-size: 1.2rem;
`

const TAB_WINDOWS = 0
const TAB_MAC = 1
const TAB_LINUX = 2

const tabToName: Record<number, string> = {
  [TAB_WINDOWS]: 'Windows',
  [TAB_MAC]: 'Mac',
  [TAB_LINUX]: 'Linux',
}

const defaultTab =
  {
    [OsTypes.Windows]: TAB_WINDOWS,
    [OsTypes.MAC_OS]: TAB_MAC,
    Linux: TAB_LINUX,
  }[osName] ?? TAB_MAC

const BASH_HINT = 'Copy and paste this script in your terminal'
const BASH_SCRIPT = 'curl -sL https://get.diversion.dev/unix | bash ; export PATH="$HOME/.diversion/bin:$PATH"'
const WINDOWS_INSTALLER_URL =
  'https://get.diversion.dev/win'

const InstallerContent = styled(FlexColumn)`
  width: 100%;
  gap: 0.5rem;
`

const ScriptHint = styled(FlexRow)`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.black.secondary};
  a {
    color: ${({ theme }) => theme.colors.black.primary};
    cursor: pointer;
  }
`

const InstallerContentStyle = styled.div`
  background-color: ${({ theme }) => theme.colors.blue.hover};
  color: ${({ theme }) => theme.colors.blue.primary};
  border-radius: 0.5rem;
`

const Script = styled(InstallerContentStyle)`
  ${RefNameText};
  width: 100%;
  padding: ${({ theme }) => theme.padding.l}rem ${({ theme }) => theme.padding.m}rem;
  white-space: nowrap;
  overflow-x: auto;
`

const InstallButton = styled(InstallerContentStyle)`
  border: ${({ theme }) => theme.colors.blue.hover} 1px solid;
  padding: ${({ theme }) => theme.padding.l}rem ${({ theme }) => theme.padding.l}rem;
  cursor: pointer;
  width: max-content;
`

const StyledCopyIcon = styled(CopyIcon)`
  color: ${({ theme }) => theme.colors.black.secondary};
  display: inline;
`

const ScriptInstaller = ({
  installScript,
  hint,
  copyToClipboard,
}: {
  installScript: string
  hint: string
  copyToClipboard: CopyToClipboardAction
}) => (
  <>
    <ScriptHint>
      {hint}
      <StyledCopyIcon copyToClipboard={copyToClipboard} text={installScript} title="Copy to clipboard" />
    </ScriptHint>
    <Script>{installScript}</Script>
  </>
)

const FileDownloadInstaller = ({ text, link, onClicked }: { text: string; link: string; onClicked: () => void }) => (
  <InstallButton title={text} onClick={onClicked}>
    <Anchor target={link} isDownload description="Download Windows CLI">
      {text}
    </Anchor>
  </InstallButton>
)

const TabPanel = ({ isSelected, content }: TabPanelProps) => (
  <div role="tabpanel" hidden={!isSelected}>
    {isSelected && <InstallerContent>{content}</InstallerContent>}
  </div>
)

export const InstallerTabs = () => {
  const [selected, setSelected] = useState(defaultTab)
  const copyToClipboard = useCopyToClipboardAction()
  const postAnalytics = useAnalytics()
  const copyToClipboardWithAnalytics = useCallback(
    (value: string, message: string) => {
      postAnalytics('UnixInstallerCopied', {})
      copyToClipboard(value, message)
    },
    [copyToClipboard, postAnalytics]
  )
  const setSelectedWithAnalytics = useCallback(
    (newValue: number) => {
      setSelected(newValue)
      postAnalytics('InstallerTabSelected', { installer_tab: tabToName[newValue] })
    },
    [postAnalytics]
  )
  return (
    <>
      <StyledTabs value={selected} onChange={(_, newValue) => setSelectedWithAnalytics(newValue)}>
        <Tab
          label={
            <>
              Windows <DevIcon className="devicon-windows8-original" />
            </>
          }
        />
        <Tab
          label={
            <>
              Mac <DevIcon className="devicon-apple-original" />
            </>
          }
        />
        <Tab
          label={
            <>
              Linux <DevIcon className="devicon-linux-plain" />
            </>
          }
        />
      </StyledTabs>
      <TabPanel
        isSelected={selected === TAB_WINDOWS}
        content={
          <>
            <FileDownloadInstaller
              text="Download Windows Installer"
              link={WINDOWS_INSTALLER_URL}
              onClicked={() => postAnalytics('WindowsInstallerClicked', {})}
            />
            <IntroBody>After downloading, run installer and follow instructions.</IntroBody>
          </>
        }
      />
      <TabPanel
        isSelected={selected === TAB_MAC}
        content={
          <ScriptInstaller
            installScript={BASH_SCRIPT}
            hint={BASH_HINT}
            copyToClipboard={copyToClipboardWithAnalytics}
          />
        }
      />
      <TabPanel
        isSelected={selected === TAB_LINUX}
        content={
          <ScriptInstaller
            installScript={BASH_SCRIPT}
            hint={BASH_HINT}
            copyToClipboard={copyToClipboardWithAnalytics}
          />
        }
      />
      <ScriptHint>
        By installing Diversion you agree to the&nbsp;
        <Anchor target={config.TOS_LINK} description="Diversion ToS">
          Terms of Service
        </Anchor>
      </ScriptHint>
    </>
  )
}
