import { Outlet } from 'react-router'
import { useUrlState } from '../../hooks/useUrlState'
import { MergeHeader } from './MergeHeader'
import styled from '@emotion/styled'
import { MergeConflictsList } from './MergeConflictsList'
import isNil from 'lodash/isNil'
import { FlexColumn, FlexRow } from '../base/Flex'
import { SidePanel } from '../sidepanel/SidePanel'

const Container = styled(FlexColumn)`
  position: fixed;
  width: 100%;
  height: 100%;
`

const Content = styled(FlexRow)`
  width: 100%;
  height: 100%;
`

const OverlayFiller = styled.div`
  background-color: ${({ theme }) => theme.colors.overlay.primary};
  height: 100%;
  width: 100%;
`

export const MergesConflictsRoute = () => {
  const { repoId, mergeId, conflictId } = useUrlState()
  return (
    <Container>
      <MergeHeader repoId={repoId!} mergeId={mergeId!} />
      <Content>
        <SidePanel contextName={'mergeConflicts'} hasOutletContent>
          <MergeConflictsList repoId={repoId!} mergeId={mergeId!} selectedConflictId={conflictId} />
        </SidePanel>
        {isNil(conflictId) ? <OverlayFiller /> : <Outlet />}
      </Content>
    </Container>
  )
}
