import { TitledGenericFileEditor } from './TitledGenericFileEditor'
import { getWorkspaceBaseCommitId, useWorkspace } from '../../hooks/api/useWorkspace'
import styled from '@emotion/styled'
import { useVerifyPathOnServer } from './useVerifyPathOnServer'
import { useFileAndBaseFile } from './useFileAndBaseFile'
import { useContext, useEffect } from 'react'
import { WorkspaceRevisionContext } from '../workspace/useWorkspaceRevisionUpdater'
import { FlexColumn } from '../base/Flex'

type Props = {
  repoId: string
  workspaceId: string
  filePath: string
}

const Container = styled(FlexColumn)`
  height: 100%;
  width: 100%;
`

export const WorkspaceFileViewer = ({ repoId, workspaceId, filePath }: Props) => {
  const { workspace, workspaceLoading } = useWorkspace(repoId, workspaceId)
  const baseCommitId = getWorkspaceBaseCommitId(workspace)
  const { workspaceRevision } = useContext(WorkspaceRevisionContext)
  const {
    file,
    baseFile,
    loading: filesLoading,
    refreshFile,
  } = useFileAndBaseFile(repoId, filePath, workspace?.workspace_id, baseCommitId, workspaceRevision)
  useEffect(() => {
    workspaceRevision && refreshFile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceRevision])
  useVerifyPathOnServer(file, baseFile)
  return (
    <Container>
      <TitledGenericFileEditor
        loading={workspaceLoading || filesLoading}
        repoId={repoId!}
        file={file}
        baseFile={baseFile}
        hasParentRelationship
        showFileHistory
      />
    </Container>
  )
}
