import { useItemListApi } from '../useApi'
import { Commit, RepositoryCommitManipulationService } from '../../api/coreapi'

export const fetchCommitsPageAsync = async (
  repoId: string,
  refId: string,
  limit: number,
  skip?: number,
  query?: string
) =>
  (await RepositoryCommitManipulationService.srcHandlersv2CommitListAll({
    repoId,
    refIds: [refId],
    limit,
    skip,
    query,
  })) as { items: Commit[] }

export const useCommits = (repoId: string, refId: string | undefined, limit: number, skip?: number, query?: string) => {
  return useItemListApi<Commit>(
    ['repos', repoId, 'commits', 'refId', refId || '', limit?.toString(), skip?.toString(), query],
    refId ? () => fetchCommitsPageAsync(repoId, refId, limit, skip, query) : null
  )
}
