import styled from '@emotion/styled'
import { RefNameText } from './TextStyle'
import { css } from '@emotion/react'

export const CodeRef = styled.span<{ clickable?: boolean }>`
  ${RefNameText};
  color: ${({ theme }) => theme.colors.blue.primary};
  background-color: ${({ theme }) => theme.colors.blue.hover};
  padding: ${({ theme }) => theme.padding.s}rem;
  border-radius: 6px;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`
