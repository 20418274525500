/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientUpdateResults } from '../models/ClientUpdateResults';
import type { ClientUpdates } from '../models/ClientUpdates';
import type { Error } from '../models/Error';
import type { MergeId } from '../models/MergeId';
import type { NeedLogsOutput } from '../models/NeedLogsOutput';
import type { NewResourceId } from '../models/NewResourceId';
import type { NewWorkspace } from '../models/NewWorkspace';
import type { PathsList } from '../models/PathsList';
import type { RefsFilesStatus } from '../models/RefsFilesStatus';
import type { ResetStatus } from '../models/ResetStatus';
import type { Workspace } from '../models/Workspace';
import type { WorkspaceCheckout } from '../models/WorkspaceCheckout';
import type { WorkspaceJournalOrdinalId } from '../models/WorkspaceJournalOrdinalId';
import type { WorkspacePreferences } from '../models/WorkspacePreferences';
import type { WorkspaceRename } from '../models/WorkspaceRename';
import type { WorkspaceStatus } from '../models/WorkspaceStatus';
import type { WorkspaceUpdates } from '../models/WorkspaceUpdates';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RepositoryWorkspaceManipulationService {
    /**
     * Create a workspace
     * Create a new workspace
     * @returns Error An error occurred
     * @returns NewResourceId Resource created
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceCreateWorkspace({
        repoId,
        xDvClientId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The unique id of the client making this request
         */
        xDvClientId: string,
        requestBody: NewWorkspace,
    }): CancelablePromise<Error | NewResourceId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/workspaces',
            path: {
                'repo_id': repoId,
            },
            headers: {
                'X-Dv-Client-Id': xDvClientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Workspace with the same name already exists`,
            },
        });
    }
    /**
     * List user's workspaces
     * @returns any Success
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceListAllWorkspaces({
        repoId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
    }): CancelablePromise<{
        object: 'Workspace';
        items: Array<Workspace>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/workspaces',
            path: {
                'repo_id': repoId,
            },
        });
    }
    /**
     * Get a workspace object details
     * @returns Workspace Workspace details returned
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceGetWorkspace({
        repoId,
        workspaceId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
    }): CancelablePromise<Workspace | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/workspaces/{workspace_id}',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Deletes a workspace
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceDelete({
        repoId,
        workspaceId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/repos/{repo_id}/workspaces/{workspace_id}',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
        });
    }
    /**
     * Update workspace name
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceRenameWorkspace({
        repoId,
        workspaceId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        requestBody: WorkspaceRename,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/rename',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Workspace with the same name already exists`,
            },
        });
    }
    /**
     * Workspace checkout ref
     * Set the workspace to follow a different branch or commit (checkout). Currently supports only moving to a branch with an identical commit as the workspace's current branch, or moving to any branch/commit if the workspace has no working changes.
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceCheckout({
        repoId,
        workspaceId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        requestBody: WorkspaceCheckout,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/checkout',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Operation failed due to pending changes on the resource`,
                412: `The specified resource is no longer up to date`,
            },
        });
    }
    /**
     * Should the agent upload the logs
     * @returns NeedLogsOutput Checks whether a workspace was marked to upload its client and agent logs
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceGetClientShouldUploadLogs({
        repoId,
        workspaceId,
        xDvClientId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        /**
         * The unique id of the client making this request
         */
        xDvClientId: string,
    }): CancelablePromise<NeedLogsOutput | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/should_upload_logs',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            headers: {
                'X-Dv-Client-Id': xDvClientId,
            },
        });
    }
    /**
     * Get a list agents that are currently uploading to this workspace
     * @returns any A list of host names (or client ID if not available) that have pending uploads
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceGetUploadingAgents({
        repoId,
        workspaceId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
    }): CancelablePromise<{
        uploaders?: Array<string>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/uploading_agents',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
        });
    }
    /**
     * Set a temporary indication that the workspace is currently uploading changes
     * @returns void
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceSetUploading({
        repoId,
        workspaceId,
        xDvClientId,
        xDvHasUploads,
        xDvHostName,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        /**
         * The unique id of the client making this request
         */
        xDvClientId: string,
        /**
         * Indicating that the client has unfinished uploads
         */
        xDvHasUploads?: boolean,
        /**
         * Client's host name
         */
        xDvHostName?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/set_uploading',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            headers: {
                'X-Dv-Client-Id': xDvClientId,
                'X-Dv-Has-Uploads': xDvHasUploads,
                'X-Dv-Host-Name': xDvHostName,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Get updates needed to be applied by the client to this workspace
     * @returns WorkspaceUpdates The changes made to this workspace since the commit it's based upon.
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceGetWorkspaceUpdates({
        repoId,
        workspaceId,
        xDvClientId,
        fromOrdinalId,
        xDvHasUploads,
        xDvHostName,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        /**
         * The unique id of the client making this request
         */
        xDvClientId: string,
        /**
         * Returns all change history above this ordinal ID.
         */
        fromOrdinalId: WorkspaceJournalOrdinalId,
        /**
         * Indicating that the client has unfinished uploads
         */
        xDvHasUploads?: boolean,
        /**
         * Client's host name
         */
        xDvHostName?: string,
    }): CancelablePromise<WorkspaceUpdates | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/updates',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            headers: {
                'X-Dv-Client-Id': xDvClientId,
                'X-Dv-Has-Uploads': xDvHasUploads,
                'X-Dv-Host-Name': xDvHostName,
            },
            query: {
                'from_ordinal_id': fromOrdinalId,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Apply a batch of updates to the workspace
     * @returns Error An error occurred
     * @returns ClientUpdateResults Mixed results
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceApplyClientUpdates({
        repoId,
        workspaceId,
        xDvClientId,
        requestBody,
        xDvHasUploads,
        xDvHostName,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        /**
         * The unique id of the client making this request
         */
        xDvClientId: string,
        requestBody: ClientUpdates,
        /**
         * Indicating that the client has unfinished uploads
         */
        xDvHasUploads?: boolean,
        /**
         * Client's host name
         */
        xDvHostName?: string,
    }): CancelablePromise<Error | ClientUpdateResults> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/updates',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            headers: {
                'X-Dv-Client-Id': xDvClientId,
                'X-Dv-Has-Uploads': xDvHasUploads,
                'X-Dv-Host-Name': xDvHostName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get status of changes in workspace
     * @returns WorkspaceStatus The changes made to this workspace since the commit it's based upon.
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceGetStatus({
        repoId,
        workspaceId,
        detailItems = true,
        limit = 1500,
        skip,
        recurse = true,
        pathPrefix,
        allowTrim = true,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        /**
         * Should detail all the changed items in status response
         */
        detailItems?: boolean,
        /**
         * Limit the number of entries returned from walk
         */
        limit?: number,
        /**
         * Filters the first entries returned from walk
         */
        skip?: number,
        /**
         * Specifies if to recursively iterate file tree to next directory levels
         */
        recurse?: boolean,
        /**
         * A path prefix in the file tree to walk under
         */
        pathPrefix?: string,
        /**
         * Specifies if the results should be full or readable by a user
         */
        allowTrim?: boolean,
    }): CancelablePromise<WorkspaceStatus | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/status',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            query: {
                'detail_items': detailItems,
                'limit': limit,
                'skip': skip,
                'recurse': recurse,
                'path_prefix': pathPrefix,
                'allow_trim': allowTrim,
            },
        });
    }
    /**
     * Reset changes in workspace
     * @returns ResetStatus The updated status of the workspace
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceReset({
        repoId,
        workspaceId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        requestBody: {
            all?: boolean;
            paths?: PathsList;
            delete_added?: boolean;
            write_to_journal?: boolean;
        },
    }): CancelablePromise<ResetStatus | Error> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/reset',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cherry-pick a commit into the workspace
     * @returns Error An error occurred
     * @returns MergeId The cherry-pick is in progress. It has conflicts requiring active input from the user for conflict resolution. The response contains a merge ID, which can be used for querying /repos/{repo_id}/merges/{merge_id}
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceCherryPick({
        repoId,
        workspaceId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        requestBody: {
            /**
             * The commit to cherry-pick
             */
            ref_id: string;
        },
    }): CancelablePromise<Error | MergeId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/cherry-pick',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Merge base branch into workspace
     * @returns any Request completed successfully
     * @returns MergeId The merge is in progress. It has merge conflicts requiring active input from the user for conflict resolution. The response contains a merge ID, which can be used for querying /repos/{repo_id}/merges/{merge_id}
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceForwardWorkspace({
        repoId,
        workspaceId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
    }): CancelablePromise<any | MergeId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/forward',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            errors: {
                412: `Workspace is detached`,
                503: `Service unavailable`,
            },
        });
    }
    /**
     * Get preferences of the current user
     * @returns WorkspacePreferences Preferences of the workspace for the user
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceGetPreferences({
        repoId,
        workspaceId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
    }): CancelablePromise<WorkspacePreferences | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/preferences',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
        });
    }
    /**
     * Update the preferences of the current user
     * @returns Error An error occurred
     * @returns any Request completed successfully
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceSetPreferences({
        repoId,
        workspaceId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        requestBody: WorkspacePreferences,
    }): CancelablePromise<Error | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/preferences',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Cannot save preferences`,
            },
        });
    }
    /**
     * Get list of potential clashes with files in other users' workspaces and branches
     * @returns RefsFilesStatus By path description of file statuses in other workspaces and branches
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceGetOtherStatuses({
        repoId,
        workspaceId,
        pathPrefix,
        pathPrefixes,
        limit = 1500,
        skip,
        recurse = true,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        /**
         * A path prefix in the file tree to walk under
         */
        pathPrefix?: string,
        /**
         * A list of prefixes in the file tree to walk under
         */
        pathPrefixes?: Array<string>,
        /**
         * Limit the number of entries returned from walk
         */
        limit?: number,
        /**
         * Filters the first entries returned from walk
         */
        skip?: number,
        /**
         * Specifies if to recursively iterate file tree to next directory levels
         */
        recurse?: boolean,
    }): CancelablePromise<RefsFilesStatus | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/other_statuses',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            query: {
                'path_prefix': pathPrefix,
                'path_prefixes': pathPrefixes,
                'limit': limit,
                'skip': skip,
                'recurse': recurse,
            },
        });
    }
}
