import { CalculateFileSha } from '../../../../hooks/useFileShaWorker'
import { log } from '../../../../utils/log'
import { uploadFileToRepoAsync } from '../../../../api/upload'
import { ClientUpdate, RepositoryWorkspaceManipulationService } from '../../../../api/coreapi'
import { clientId } from '../../../../api/configure'
import { FILE_MODE_DIRECTORY, FILE_MODE_FILE } from '../../../../models/fileMode'
import { infoToast } from '../../../../utils/toast'
import { pluralize } from '../../../../utils/textUtils'
import { useCallback, useContext } from 'react'
import { callAsync, SetLoading } from '../../../../utils/callAsync'
import { useAnalytics } from '../../../../hooks/api/useAnalytics'
import { PublishApiErrorContext } from '../../../../App'
import pLimit from 'p-limit'
import { getUniqueParentDirectories } from '../../../../utils/pathUtils'
import { ObjectStatusValues } from '../../../../models/ChangeType'
import { v4 as uuidv4 } from 'uuid'

// returns the relative path of a file, given its base directory
const relPath = (file: File, baseDir: string) => {
  const fileRelPath = file.webkitRelativePath || file.name
  return baseDir ? `${baseDir}/${fileRelPath}` : fileRelPath
}

// computes the parent directories of the files and prepares ClientUpdate for creating each
const prepareParentDirUpdates = (files: File[], targetDirectoryPath: string) => {
  const relativePaths = files.map((file) => relPath(file, targetDirectoryPath))
  const ancestorDirs = getUniqueParentDirectories(relativePaths)
  const updates: ClientUpdate[] = ancestorDirs
    .filter((dir) => dir > targetDirectoryPath) // targetDirectoryPath and its ancestors are assumed to exist
    .map((dir) => ({
      transaction_id: uuidv4(),
      file_entry: {
        path: dir,
        status: ObjectStatusValues.Added,
        mode: FILE_MODE_DIRECTORY,
        mtime: new Date().toISOString(),
      },
    }))
  return updates
}

const uploadFilesAsync = async (
  repoId: string,
  workspaceId: string,
  targetDirectoryPath: string,
  files: File[],
  fileShaWorker: CalculateFileSha,
  onFileProgress: (progressBytes: number) => void,
  onFileCompleted: () => void
) => {
  const maxUpdateBatchSize = 128
  const uploadConcurrencyLimit = pLimit(256)
  const updateConcurrencyLimit = pLimit(1)

  // enqueue ClientUpdates for the parent directories of the files
  const updatesQueue = prepareParentDirUpdates(files, targetDirectoryPath)
  let fileUploadCount = 0

  await Promise.all(
    files.map(async (file) => {
      await uploadConcurrencyLimit(async () => {
        // upload up to `uploadConcurrencyLimit` files concurrently to S3
        log.info('uploading file', relPath(file, targetDirectoryPath))
        let progress = 0
        const { storage_backend, storage_uri, sha1, size } = await uploadFileToRepoAsync(
          repoId,
          file,
          fileShaWorker,
          (progressPercentage) => {
            const nextProgress = (file.size * progressPercentage) / 100
            const diff = nextProgress - progress
            onFileProgress(diff)
            progress = nextProgress
          }
        )
        // enqueue the uploaded file in updatesQueue
        const clientUpdate = {
          transaction_id: uuidv4(),
          file_entry: {
            path: relPath(file, targetDirectoryPath),
            status: ObjectStatusValues.Added,
            mode: FILE_MODE_FILE,
            mtime: new Date().toISOString(),
            blob: { storage_uri, storage_backend, size, sha: sha1 },
          },
        }

        updatesQueue.push(clientUpdate)
        fileUploadCount++

        while (updatesQueue.length >= maxUpdateBatchSize || fileUploadCount === files.length) {
          // dequeue a batch of updates from updatesQueue
          let batch = updatesQueue.splice(0, maxUpdateBatchSize)
          if (batch.length === 0) {
            break
          }
          await updateConcurrencyLimit(async () => {
            // apply the batch of updates to mongodb (`updateConcurrencyLimit` to prevent concurrent updates)
            log.info('ApplyClientUpdates batch', batch)
            await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceApplyClientUpdates({
              repoId,
              workspaceId,
              xDvClientId: clientId(),
              requestBody: { updates: batch },
            })
            // send progress update
            for (const update of batch) {
              if (update.file_entry.mode === FILE_MODE_FILE) {
                log.info('notifying file upload completed', update.file_entry)
                onFileCompleted()
              }
            }
          })
        }
      })
    })
  )
  infoToast(`${pluralize(files.length, 'File')} uploaded successfully`, true)
}

export const useUploadFiles = (
  repoId: string | undefined,
  workspaceId: string | undefined,
  filesToUpload: File[],
  fileShaWorker: CalculateFileSha,
  setUploading: SetLoading,
  clearDroppedFiles: () => void,
  refreshWorkspaceRevision: (() => void) | undefined,
  onFileProgress: (progressBytes: number) => void,
  onFileCompleted: () => void
) => {
  const postAnalytics = useAnalytics()
  const onApiError = useContext(PublishApiErrorContext)
  return useCallback(
    async (targetDirectoryPath: string) => {
      await callAsync(
        async () => {
          if (filesToUpload.length === 0) {
            return
          }
          await uploadFilesAsync(
            repoId!,
            workspaceId!,
            targetDirectoryPath,
            filesToUpload,
            fileShaWorker,
            onFileProgress,
            onFileCompleted
          )
          refreshWorkspaceRevision?.()
          postAnalytics('UploadFilesUploaded', {
            repo_id: repoId, workspace_id: workspaceId,
            files_count: filesToUpload.length.toString(),
            path: targetDirectoryPath,
          })
        },
        setUploading,
        (error) => {
          onApiError(error)
        },
        () => {
          clearDroppedFiles()
        }
      )
    },
    [
      setUploading,
      filesToUpload,
      repoId,
      workspaceId,
      fileShaWorker,
      onFileProgress,
      onFileCompleted,
      refreshWorkspaceRevision,
      postAnalytics,
      onApiError,
      clearDroppedFiles,
    ]
  )
}
