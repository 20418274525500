import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/FiberManualRecord'
import ClearIcon from '@mui/icons-material/Clear'
import MovedIcon from '@mui/icons-material/DoubleArrow'
import { ReactNode } from 'react'
import { ChangeType } from '../../models/ChangeType'
import styled from '@emotion/styled'

const StyledEditIcon = styled(EditIcon)`
  circle {
    r: 6;
  }
`

const ICONS: Record<ChangeType, ReactNode | undefined> = {
  Intact: undefined,
  Moved: <MovedIcon />,
  Added: <AddIcon />,
  Modified: <StyledEditIcon />,
  Deleted: <ClearIcon />,
}

export const getIcon = (changeType: ChangeType): ReactNode | undefined => ICONS[changeType]
