/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConflictIndex } from './ConflictIndex';
export type Conflict = {
    conflict_id: string;
    is_resolved: boolean;
    resolved_side?: Conflict.resolved_side;
    base: ConflictIndex;
    other: ConflictIndex;
    result?: ConflictIndex;
};
export namespace Conflict {
    export enum resolved_side {
        RESULT = 'RESULT',
        BASE = 'BASE',
        OTHER = 'OTHER',
    }
}

