import { getIcon } from 'material-file-icons'
import { isText } from 'istextorbinary'
import { Buffer } from 'buffer'
import { is3dModelByPath, isImageByPath } from '../../utils/extensionUtils'
import { log } from '../../utils/log'
import { BlobState, FileState } from '../../models/fileState'

export const buildFileStateAsync = async (filePath: string, blobState: BlobState): Promise<FileState> => {
  let isTextFile = false
  let content: string | undefined = undefined

  const { id, blob } = blobState
  let { downloadUrl } = blobState

  if (blob && !blobState.isDirectory) {
    downloadUrl = URL.createObjectURL(blob)
    const arrayBuffer = await blob.arrayBuffer()
    isTextFile = Boolean(isText(filePath, Buffer.from(arrayBuffer)))
    if (isTextFile) {
      content = await blob.text()
    }
  }

  if (!downloadUrl && !blobState.isDirectory) {
    log.error('Could not get a download url for file', { id, filePath })
  }

  const isImage = isImageByPath(filePath)
  const is3dModel = is3dModelByPath(filePath)
  let fileState = {
    ...blobState,
    filePath,
    isTextFile,
    isBinary: !isTextFile,
    isImage,
    is3dModel,
    isDiffable: isTextFile || isImage || is3dModel,
    fileIcon: getIcon(filePath),
    content,
    downloadUrl,
  }
  log.info('FileState', { ...fileState })
  return fileState
}
