import isNil from 'lodash/isNil'
import identity from 'lodash/identity'

export const groupBy = <TFrom, TTo>(
  items: TFrom[] | undefined,
  by: (item: TFrom) => string,
  mapValue: (item: TFrom, existingItem: TTo | undefined) => TTo = identity
) =>
  items?.reduce(
    (acc, value) => {
      const key = by(value)
      acc[key] = mapValue(value, acc[key])
      return acc
    },
    {} as { [key: string]: TTo }
  ) || {}

export const notNil = (obj: any): boolean => !isNil(obj)

export const toggleFromArray = <T>(items: T[], item: T) =>
  items.includes(item) ? items.filter((i) => i !== item) : [...items, item]

export const tryGet = <T>(record: Record<string, T>, key?: string): T | undefined => (key ? record[key] : undefined)

export const singleOrUndefined = <T>(items: T[]) => (items.length === 1 ? items[0] : undefined)

export const filterBy = <T>(items: T[], itemFilterableProperties: (item: T) => string[], query: string) => {
  query = query.toLowerCase().trim()
  return items.filter((item) => itemFilterableProperties(item).some((value) => value.toLowerCase().includes(query)))
}
