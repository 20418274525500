import * as React from 'react'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos'
import { useNavigate } from 'react-router'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { BannerPadding } from '../base/PaddingStyle'
import { Tree, TreeNode } from '../../models/Tree'
import { notNil, toggleFromArray } from '../../utils/objectUtil'
import { TextRegular, TextSmall } from '../base/TextStyle'
import { AntTreeNodeProps } from 'antd/lib/tree'
import { AntTree } from './AntTree'
import { HIDE_INTACT_CLASSNAME } from './useHideIntact'
import { useDebounceCallback, useResizeObserver, useSessionStorage } from 'usehooks-ts'
import { SearchBox } from '../selector/SearchBox'
import { getAncestorPaths } from '../../utils/pathUtils'
import uniq from 'lodash/uniq'
import { pluralize } from '../../utils/textUtils'
import { SEARCH_LIMIT } from '../../hooks/api/useTreeSearch'
import { remToPx, Styleable, TREE_LEFT_MARGIN_REM, TREE_LEFT_MARGIN_REM_PADDED } from '../../theme'
import { FileViewerPathContext } from '../file/useFileAndBaseFile'
import { NodeTitle, RootNode } from './Node'
import { FlexRow } from '../base/Flex'
import { RefFileStatus } from '../../api/coreapi'

const NoContentMessage = styled.div`
  ${BannerPadding};
  color: ${({ theme }) => theme.colors.black.secondary};
  text-align: center;
`

const ArrowDownIcon = styled(ArrowRightIcon)`
  transform: rotate(90deg);
`

const ArrowUpIcon = styled(ArrowRightIcon)`
  transform: rotate(-90deg);
`

const StyledAntTree = styled(AntTree)`
  ${TextRegular};
  background-color: transparent;
  margin-left: ${TREE_LEFT_MARGIN_REM}rem;

  .ant-tree-switcher {
    background-color: transparent;
  }

  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.blue.primary};
    border-color: ${({ theme }) => theme.colors.blue.primary};
  }

  .ant-tree-checkbox {
    margin-right: 0.2rem;
  }

  .ant-tree-checkbox + span {
    padding: 0;
  }

  .ant-tree-node-selected {
    background-color: unset !important;
  }

  .ant-tree-node-content-wrapper:hover {
    background-color: unset !important;
  }

  .ant-tree-switcher-icon {
    color: ${({ theme }) => theme.colors.black.secondary};
  }

  .ant-tree-switcher-loading-icon {
    color: ${({ theme }) => theme.colors.blue.primary};
  }

  .${HIDE_INTACT_CLASSNAME} {
    display: none;
  }
`

const TreeWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding-bottom: 1rem;
  min-height: 0;
`

const StatusRow = styled(FlexRow)`
  margin-left: ${TREE_LEFT_MARGIN_REM_PADDED}rem;
  gap: 1rem;
  color: ${({ theme }) => theme.colors.black.secondary};
  ${TextSmall};
  align-items: center;
  height: 1.5rem;
`

const CollapseButton = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black.secondary};
  height: 16px;
  width: 16px;
  cursor: pointer;
  opacity: 50%;

  svg {
    color: ${({ theme }) => theme.colors.white.primary};
    height: 10px;
    width: 10px;
    margin-left: 3px;
    margin-bottom: 4px;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.blue.primary};
    opacity: 1;
  }
`

const StyledSearchBox = styled(SearchBox)`
  margin: ${({ theme }) => theme.padding.s}rem ${({ theme }) => theme.padding.s} 0 ${TREE_LEFT_MARGIN_REM_PADDED}rem;
`

const TreeSection = styled.div`
  height: 100%;
  overflow: auto;
`

const searchResultsLabel = (count: number) => {
  if (count === 0) {
    return 'No search results'
  }
  return `Showing ${count >= SEARCH_LIMIT ? ' first' : ''} ${pluralize(count, 'search result')}`
}

export type LoadKeyResult = {
  nodes: TreeNode[]
  loadedKeys: string[]
}

type Props = Styleable & {
  treeId: string
  treeData: Tree
  onExpandNodeAsync: (expandedKey: string) => Promise<LoadKeyResult>
  redirectRouteOnClick: (nodeKey: string) => string
  checkedKeys?: string[]
  onChecked?: (checkedKeys: string[]) => void
  setCheckedPathsCount?: (checkedFilesCount: number) => void
  onSelected?: (key: string) => void
  noContentLabel?: string
  selectedNodeKey?: string
  changedOnly: boolean
  searchLoading: boolean
  onSearch: (query: string) => void
  searchResultKeys?: string[]
  addRootNode?: boolean
  otherStatusesByPath?: Record<string, RefFileStatus[]>
  expandHints?: string[]
  enableWorkspaceActions: boolean
  hideSearch?: boolean
  autoFocusOnSearch?: boolean
  loadOnSelectOrExpand?: (node: TreeNode, loadedKeys: string[]) => boolean
  isNewTree: boolean
  minHeight?: number
}

export const ReloadIfHasUnloadedChild = (node: TreeNode, loadedKeys: string[]) =>
  node.children?.some((n) => !loadedKeys.includes(n.key)) ?? false
export function DirStructureTreeView({
  treeId,
  className,
  treeData,
  onExpandNodeAsync,
  redirectRouteOnClick,
  checkedKeys,
  onChecked,
  setCheckedPathsCount,
  onSelected,
  noContentLabel,
  selectedNodeKey,
  changedOnly,
  searchLoading,
  onSearch,
  searchResultKeys,
  addRootNode,
  otherStatusesByPath,
  expandHints,
  enableWorkspaceActions,
  loadOnSelectOrExpand,
  isNewTree,
  hideSearch = false,
  autoFocusOnSearch = true,
  minHeight = 200,
}: Props) {
  const navigate = useNavigate()
  const { setBasePath } = useContext(FileViewerPathContext)
  const checkable = notNil(onChecked)
  const [expandedKeys, setExpandedKeys] = useSessionStorage<string[]>(
    `tree.${treeId}.expanded`,
    selectedNodeKey ? [selectedNodeKey] : []
  )
  const [loadedKeys, setLoadedKeys] = useState<string[]>([])
  const onCheckedCallback = useCallback(
    (checkedKeys: string[], checkedNodes: TreeNode[]) => {
      onChecked && onChecked(checkedKeys)
      setCheckedPathsCount && setCheckedPathsCount(checkedNodes.length)
    },
    [onChecked, setCheckedPathsCount]
  )
  const adjustedTreeData = useMemo(
    () => (changedOnly ? filterOnlyChanges(treeData) : treeData),
    [treeData, changedOnly]
  )
  const firstLevelNodes: TreeNode[] = adjustedTreeData.root.children!
  const treeIsEmpty = useMemo(() => isEmpty(firstLevelNodes), [firstLevelNodes])
  const noneLoaded = useMemo(() => firstLevelNodes.every((item) => isEmpty(item.children)), [firstLevelNodes])
  useEffect(() => {
    if (noneLoaded || isNewTree) {
      setLoadedKeys([])
    }
  }, [noneLoaded, isNewTree])
  useEffect(() => {
    if (!isEmpty(searchResultKeys)) {
      const searchResultsParents = searchResultKeys!
        .map((path) => getAncestorPaths(path))
        .flatMap((paths) => paths)
        .filter((path) => path)
      setExpandedKeys((keys) => uniq([...keys, ...searchResultsParents!]))
    }
  }, [searchResultKeys, setExpandedKeys])

  const loadDataCallback = useCallback(
    async (key: string) => {
      const res = await onExpandNodeAsync(key)
      setLoadedKeys((keys) => uniq([...keys, ...res.loadedKeys]))
      return res.nodes
    },
    [onExpandNodeAsync, setLoadedKeys]
  )

  const onExpand = useCallback(
    (children: TreeNode[]) => {
      if (!changedOnly) {
        return
      }
      const relevantChildren = children.filter((node) => node.changeType !== 'Intact')
      if (relevantChildren.length === 1 && relevantChildren[0]!.isDirectory) {
        setExpandedKeys((keys) => uniq([...keys, relevantChildren[0]!.key]))
      }
    },
    [changedOnly, setExpandedKeys]
  )
  useEffect(() => {
    if (!changedOnly) {
      return
    }
    onExpand(adjustedTreeData.root.children || [])
  }, [changedOnly, onExpand, adjustedTreeData.root.children])
  useEffect(() => {
    setExpandedKeys((keys) => {
      const missingExpandedKeys = keys.filter((key) => !adjustedTreeData.nodeByKey[key])
      const keysToAdd = Object.values(adjustedTreeData.nodeByKey)
        .filter((node) => node.originalKey && missingExpandedKeys.includes(node.originalKey))
        .map((node) => node.key)
      return [...keys, ...keysToAdd]
    })
  }, [setExpandedKeys, adjustedTreeData.nodeByKey])
  const onFileDrillDown = useCallback(
    (node: TreeNode) => {
      const route = redirectRouteOnClick(node.key)
      if (route === '') {
        return
      }
      setBasePath(node.prevPath)
      navigate(route)
    },
    [navigate, redirectRouteOnClick, setBasePath]
  )
  useEffect(() => {
    if (isEmpty(expandHints)) {
      return
    }
    setExpandedKeys((keys) => uniq([...keys, ...expandHints!]))
  }, [expandHints, loadedKeys, onExpandNodeAsync, setExpandedKeys])
  const showSearch = !hideSearch

  const treePanelRef = useRef<HTMLDivElement>(null)
  const [treePanelHeight, setTreePanelHeight] = useState<number>(minHeight)
  const onTreePanelResize = useDebounceCallback(({ height, width }: { height?: number; width?: number }) => {
    const treeHeight = Math.max(Math.floor((height || 0) - remToPx(4)), minHeight)
    if (treeHeight > 0) {
      setTreePanelHeight(treeHeight)
    }
  }, 100)
  useResizeObserver({
    ref: treePanelRef,
    onResize: onTreePanelResize,
  })

  return (
    <TreeWrapper className={className}>
      {treeIsEmpty ? (
        <NoContentMessage>{noContentLabel || (changedOnly ? 'No changes' : 'Tree is empty')}</NoContentMessage>
      ) : (
        <>
          {showSearch && (
            <StyledSearchBox
              noPersistSearch
              hint={'Search items by name'}
              onChange={onSearch}
              isLoading={searchLoading}
              autoFocus={autoFocusOnSearch}
            />
          )}
          <TreeSection ref={treePanelRef}>
            <StatusRow>
              <CollapseButton title="Collapse all" onClick={() => setExpandedKeys([])}>
                <ArrowUpIcon />
              </CollapseButton>
              {notNil(searchResultKeys) && <div>{searchResultsLabel(searchResultKeys!.length)}</div>}
            </StatusRow>
            {addRootNode && <RootNode isSelected={selectedNodeKey === ''} onSelected={() => onSelected?.('')} />}
            <StyledAntTree
              height={treePanelHeight}
              checkable={checkable}
              expandedKeys={expandedKeys}
              onExpand={(expandedKeys, { node, expanded }) => {
                setExpandedKeys(expandedKeys as string[])
                if (expanded) {
                  onExpand(node.children || [])
                  if (loadOnSelectOrExpand && loadOnSelectOrExpand(node, loadedKeys)) {
                    loadDataCallback(node.key)
                  }
                }
              }}
              checkedKeys={checkedKeys}
              onSelect={(selectedKeys, { node }) => {
                if (onSelected) {
                  onSelected(node.key)
                }
                if (node.isLeaf) {
                  onFileDrillDown(node)
                } else {
                  setExpandedKeys((current) => toggleFromArray(current, node.key))
                  if (node.selected) {
                    onExpand(node.children || [])
                  } else {
                    if (loadOnSelectOrExpand && loadOnSelectOrExpand(node, loadedKeys)) {
                      loadDataCallback(node.key)
                    }
                  }
                }
              }}
              onCheck={(checkedKeys, { checkedNodes }) => onCheckedCallback(checkedKeys as string[], checkedNodes)}
              loadData={async ({ key }) => {
                const children = await loadDataCallback(key)
                onExpand(children)
              }}
              loadedKeys={loadedKeys}
              treeData={firstLevelNodes}
              showLine
              blockNode
              switcherIcon={({ expanded }: AntTreeNodeProps) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon />)}
              titleRender={(node: TreeNode) => {
                const isSelected = node.key === selectedNodeKey
                if (isSelected && node.isLeaf) {
                  setTimeout(() => setBasePath(node.prevPath))
                }
                return (
                  <NodeTitle
                    {...node}
                    isSelected={isSelected}
                    treeCheckable={checkable}
                    changedOnly={changedOnly}
                    isSearchResult={(showSearch && searchResultKeys?.includes(node.key)) || false}
                    otherStatuses={otherStatusesByPath?.[node.key]}
                    enableWorkspaceActions={enableWorkspaceActions}
                  />
                )
              }}
            />
          </TreeSection>
        </>
      )}
    </TreeWrapper>
  )
}

const filterOnlyChanges = (tree: Tree): Tree => {
  // Stack for DFS traversal (pairs of [original node, copied node])
  const stack: Array<[TreeNode, TreeNode]> = []
  const root = tree.root
  // Create a deep copy of the root node (without modifying the original)
  const newRoot: TreeNode = { ...root, children: [] }

  stack.push([root, newRoot])

  // Traverse the original tree
  while (stack.length > 0) {
    const [originalNode, copiedNode] = stack.pop()!

    originalNode.children
      ?.filter((child) => child.changeType !== 'Intact')
      .forEach((child) => {
        // Create a copy of the child
        const copiedChild: TreeNode = { ...child, children: [] }

        // Add the copied child to the current copied node
        copiedNode.children!.push(copiedChild)

        // Continue traversing the child nodes
        stack.push([child, copiedChild])
      })
  }

  return { ...tree, root: newRoot }
}
