import { SidePanelTabs } from './tabs/Tabs'
import { SidePanel } from './SidePanel'
import { useWorkspace } from '../../hooks/api/useWorkspace'
import { CommitHistory } from './CommitHistory'
import { useCommit } from '../../hooks/api/useCommit'
import { notNil } from '../../utils/objectUtil'

type Props = {
  repoId: string
  branchId?: string
  commitId?: string
  workspaceId?: string
  hasOutletContent: boolean
}

export const CommitsHistorySidePanel = ({ repoId, branchId, commitId, workspaceId, hasOutletContent }: Props) => {
  const { workspace, workspaceLoading } = useWorkspace(repoId, workspaceId)
  const { commit: workspaceCheckedOutCommit } = useCommit(repoId, workspace?.source_commit)
  const inWorkspaceContext = notNil(workspaceId)
  const targetBranchId = inWorkspaceContext ? workspace?.branch_id || workspaceCheckedOutCommit?.branch_id : branchId
  return (
    <SidePanel
      contextName={'workspacePanel'}
      loading={inWorkspaceContext && workspaceLoading}
      hasOutletContent={hasOutletContent}
    >
      {workspace && (
        <SidePanelTabs
          selectedMode={inWorkspaceContext ? 'view' : undefined}
          repoId={repoId}
          workspaceId={workspace.workspace_id}
        />
      )}
      <CommitHistory
        repoId={repoId}
        branchId={targetBranchId}
        workspaceId={workspace?.workspace_id}
        selectedCommitId={commitId}
        checkedOutCommitId={workspace?.source_commit}
      />
    </SidePanel>
  )
}
