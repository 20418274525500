import { routeToDesktopLogin } from '../../Routes'
import config from '../../env/config'
import { useSearchParams } from 'react-router-dom'
import { sanitizeUrl } from '../../utils/url'
import { DesktopRedirectComponent } from './DesktopRedirectComponent'

const urlToDesktopApp = (code: string, state: string) =>
  `${sanitizeUrl(config.DESKTOP_DEEP_LINK_DOMAIN!)}${routeToDesktopLogin()}?code=${code}&state=${state}`

export const DesktopLoginPage = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const state = searchParams.get('state')

  return <DesktopRedirectComponent webAppRedirect={urlToDesktopApp(code!, state!)} desktopAppRedirect={'/'} />
}
