import { Conflict } from '../api/coreapi'
import { fromFileEntryStatus } from '../models/ChangeType'

export const BASE_SIDE = 'current'
export const OTHER_SIDE = 'incoming'

export const getConflictTypes = (conflict: Conflict): string[] => {
  const baseChangeType = fromFileEntryStatus(conflict.base.type)
  const otherChangeType = fromFileEntryStatus(conflict.other.type)
  const changeText =
    baseChangeType === otherChangeType
      ? `both ${baseChangeType}${baseChangeType === 'Added' ? ' (with different contents)' : ''}`
      : `${baseChangeType} by ${BASE_SIDE}, ${otherChangeType} by ${OTHER_SIDE}`
  const pathText = conflict.base.path !== conflict.other.path ? 'path conflict' : undefined
  const modeText = conflict.base.file_mode !== conflict.other.file_mode ? 'file mode conflict' : undefined
  return [changeText, pathText, modeText].filter((text) => text) as string[]
}
