import { IsDesktopApp } from '../components/utils/DesktopAppApi'
import { useCallback } from 'react'
import { log } from '../../utils/log'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { ApiError, DefaultService } from '../../api/agentapi'
import { StatusCodes } from 'http-status-codes'
import { errorToast } from '../../utils/toast'
import { isNetworkError } from '../../utils/errorClassify'

export const useOpenLocalItemWithDefaultApp = (onNetworkError?: () => void) => {
  const postAnalytics = useAnalytics()
  const openLocalItemWithDefaultApp = useCallback(
    async (repoId: string, workspaceId: string, filePath: string) => {
      if (!IsDesktopApp()) {
        log.error('Open local item is not supported in web app', { workspaceId, filePath })
        return
      }
      postAnalytics('OpenLocalItemWithDefaultApp', { repo_id: repoId, workspace_id: workspaceId, path: filePath })
      try {
        return DefaultService.openLocalWorkspaceFile({ requestBody: { workspaceId, filePath } })
      } catch (e) {
        if (isNetworkError(e) && onNetworkError !== undefined) {
          onNetworkError()
          return
        }
        if (e instanceof ApiError && e.status === StatusCodes.NOT_FOUND) {
          errorToast('Path not found, make sure the workspace is cloned locally')
        } else {
          errorToast(`Error occurred while opening ${filePath}`)
          log.error('Error occurred while opening file', { error: e, workspaceId, filePath })
        }
      }
    },
    [onNetworkError, postAnalytics]
  )

  return { openLocalItemWithDefaultApp }
}
