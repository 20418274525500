import { ConflictIndex, FileEntry, RepositoryManipulationService } from '../../api/coreapi'
import { useApi } from '../useApi'
import { buildFileStateAsync } from './buildFileStateAsync'
import { getBlobStateAsync } from './getBlobStateAsync'
import { FileState } from '../../models/fileState'
import { PLACEHOLDER_FOR_NO_PARENT } from '../../utils/commitParent'

export const useBlobByPath = (
  repoId: string,
  refId: string | undefined,
  filePath: string | undefined,
  currentRevision: number | undefined = undefined
) =>
  useApi<FileState>(
    ['repos', repoId, 'blobs', refId, filePath, String(currentRevision)],
    refId && filePath
      ? async () =>
          await buildFileStateAsync(
            filePath,
            await getBlobStateAsync(
              `${repoId}/${refId}/${filePath}`,
              refId === PLACEHOLDER_FOR_NO_PARENT,
              () =>
                RepositoryManipulationService.srcHandlersv2FilesGetFileEntry({
                  repoId,
                  refId,
                  path: filePath,
                }),
              () =>
                RepositoryManipulationService.srcHandlersv2FilesGetBlob({
                  repoId,
                  refId,
                  path: filePath,
                })
            )
          )
      : null,
    true
  )

export const useConflictBlob = (repoId: string, conflictIndex?: ConflictIndex) =>
  useApi<FileState>(
    [
      'repos',
      repoId,
      'blobs',
      `download?storage_backend=${conflictIndex?.storage_backend}&storage_uri=${conflictIndex?.storage_uri}`,
    ],
    conflictIndex
      ? async () =>
          await buildFileStateAsync(
            conflictIndex.path,
            await getBlobStateAsync(
              `${repoId}/${conflictIndex.storage_backend}/${conflictIndex.storage_uri}`,
              !conflictIndex.storage_uri,
              () => toFileEntry(conflictIndex),
              () =>
                RepositoryManipulationService.srcHandlersv2FilesDownloadBlob({
                  repoId,
                  storageBackend: conflictIndex.storage_backend ?? 2,
                  storageUri: conflictIndex.storage_uri ?? '',
                })
            )
          )
      : null,
    true
  )

const toFileEntry = (conflictIndex: ConflictIndex): FileEntry => {
  const res: FileEntry = {
    status: 0, // unused
    path: conflictIndex.path,
    prev_path: conflictIndex.prev_path,
    mode: conflictIndex.file_mode,
  }
  if (conflictIndex.storage_uri) {
    res.blob = {
      storage_uri: conflictIndex.storage_uri,
      storage_backend: conflictIndex.storage_backend ?? 2,
      size: conflictIndex.size ?? 0,
      sha: '', // unused
    }
  }
  return res
}
