import { useMergeTitle } from '../../hooks/api/useMergeTitle'
import { useMemo } from 'react'
import { BannerPadding } from '../base/PaddingStyle'
import styled from '@emotion/styled'
import { TextBig, TextSmall } from '../base/TextStyle'
import { Loader } from '../base/Loader'
import { CopyToClipboardAction, useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { CopyIcon } from '../base/CopyIcon'
import { useMerge } from '../../hooks/api/useMerge'
import { FlexFiller, FlexRow } from '../base/Flex'
import { UserCard } from '../base/UserCard'

type Props = {
  repoId: string
  mergeId: string
}

const Header = styled(FlexRow)`
  ${BannerPadding};
  ${TextBig};
  gap: 4rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.primary};
  background-color: ${({ theme }) => theme.colors.blue.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
`

const HeaderSection = styled(FlexRow)`
  gap: 1rem;
  align-items: center;
`

const MergeTitle = styled.span`
  color: ${({ theme }) => theme.colors.blue.primary};
  font-weight: 600;
`

const CommitId = styled.span`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.black.secondary};
`

const CommitDescriptor = ({
  commitId,
  copyToClipboard,
}: {
  commitId: string
  copyToClipboard: CopyToClipboardAction
}) => (
  <CommitId>
    {commitId} <CopyIcon copyToClipboard={copyToClipboard} text={commitId || ''} title="Copy commit id" />
  </CommitId>
)

const StyledUserCard = styled(UserCard)`
  padding: 0;
`

const NoWrapText = styled.div`
  white-space: nowrap;
`

export const MergeHeader = ({ repoId, mergeId }: Props) => {
  const copyToClipboard = useCopyToClipboardAction()
  const { merge, loading: mergeLoading } = useMerge(repoId, mergeId)
  const { getTitle, loading: getTitleLoading } = useMergeTitle(repoId, merge ? [merge] : [])
  const title = useMemo(() => (merge && getTitle ? getTitle(merge) : undefined), [getTitle, merge])

  return (
    <>
      {mergeLoading || getTitleLoading ? (
        <Loader addPadding />
      ) : (
        <Header>
          <FlexFiller />
          <HeaderSection>
            Merge <MergeTitle>{title?.other || merge?.other_ref}</MergeTitle>
            <CommitDescriptor commitId={merge?.other_ref || ''} copyToClipboard={copyToClipboard} />
          </HeaderSection>
          <HeaderSection>
            Into <MergeTitle>{title?.base || merge?.base_ref}</MergeTitle>
            <CommitDescriptor commitId={merge?.base_ref || ''} copyToClipboard={copyToClipboard} />
          </HeaderSection>
          <HeaderSection>
            <NoWrapText>Opened by </NoWrapText>
            <StyledUserCard
              name={merge?.user.full_name || merge?.user.name || merge?.user.id}
              email={merge?.user.email}
              picture={merge?.user.image}
            />
          </HeaderSection>
          <FlexFiller />
        </Header>
      )}
    </>
  )
}
