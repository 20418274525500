import { Outlet } from 'react-router'
import { CompareSidePanel } from '../sidepanel/CompareSidePanel'
import { useUrlState } from '../../hooks/useUrlState'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { useEffect } from 'react'

export const CommitCompareSidePanelRoute = () => {
  const { repoId, commitId, compareCommitId } = useUrlState()
  const postAnalytics = useAnalytics()
  useEffect(() => {
    postAnalytics('CommitCompareOpened', { commit_id: commitId, compare_commit_id: compareCommitId })
  }, [commitId, compareCommitId, postAnalytics])
  return (
    <>
      <CompareSidePanel repoId={repoId!} baseCommitId={commitId!} compareCommitId={compareCommitId} />
      <Outlet />
    </>
  )
}
