import { useCallback, useState } from 'react'
import { PrimaryButton } from '../base/PrimaryButton'
import { infoToast } from '../../utils/toast'
import { ImportRepoDialog } from './ImportRepoDialog'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { LocationContext } from '../../models/Analytics'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import { FlexRow } from '../base/Flex'

type Props = {
  locationContext: LocationContext
  onCreated?: (repoId: string, repoName: string) => void
  onOpenChanged?: (open: boolean) => void
}

export const useImportRepoDialog = ({ onOpenChanged, onCreated, locationContext }: Props) => {
  const [importRepoDialogOpen, setImportRepoDialogOpen] = useState(false)
  const postAnalytics = useAnalytics()

  const setOpen = useCallback(
    (open: boolean) => {
      if (open) {
        postAnalytics('ImportRepoDialogOpened', {}, locationContext)
      }
      setImportRepoDialogOpen(open)
      onOpenChanged?.(open)
    },
    [locationContext, onOpenChanged, postAnalytics]
  )

  return {
    Dialog: () => (
      <ImportRepoDialog
        isOpen={importRepoDialogOpen}
        setOpen={setOpen}
        onCreated={(repoId, repoName) => {
          onCreated?.(repoId, repoName)
          infoToast('Repository being imported in the background', true)
        }}
      />
    ),
    setOpen: setImportRepoDialogOpen,
    Button: () => (
      <PrimaryButton
        disabled={false}
        title="Import Git Repository"
        onClick={() => {
          setOpen(true)
        }}
      >
        <FlexRow gap={0.5} centered={true} centerContent={true}>
          <ExitToAppOutlinedIcon />
          Import Git Repository
        </FlexRow>
      </PrimaryButton>
    ),
  }
}
