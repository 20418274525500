/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileMode } from './FileMode';
import type { ObjectStatus } from './ObjectStatus';
import type { StorageBackend } from './StorageBackend';
import type { StorageUri } from './StorageUri';
/**
 * A index in a conflict, could be representing either "base", "other" or "result". If the index does not represent "result", then all properties besides "prev_path" can be considered 'required'.
 *
 */
export type ConflictIndex = {
    conflict_index_id: ConflictIndex.conflict_index_id;
    file_mode: FileMode;
    path: string;
    prev_path?: string;
    type: ObjectStatus;
    storage_backend?: StorageBackend;
    storage_uri?: StorageUri;
    /**
     * Size in bytes
     */
    size?: number;
};
export namespace ConflictIndex {
    export enum conflict_index_id {
        RESULT = 'RESULT',
        BASE = 'BASE',
        OTHER = 'OTHER',
    }
}

