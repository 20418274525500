import { useEffect, useRef } from 'react'
import { useKeyPress } from '../../hooks/useKeyPress'
import { isFocused } from '../../utils/focus'
import * as KeyCode from 'keycode-js'

export const useFocusedButtons = (enabled: boolean, autoFocusOnPrimary: boolean = true) => {
  const primaryButtonRef = useRef<HTMLDivElement>(null)
  const secondaryButtonRef = useRef<HTMLDivElement>(null)

  useKeyPress('Escape', () => secondaryButtonRef.current?.click(), enabled)
  useKeyPress(
    (event) =>
      event.key === KeyCode.CODE_ENTER &&
      (isFocused(secondaryButtonRef.current) || isFocused(primaryButtonRef.current)),
    () => {
      if (isFocused(secondaryButtonRef.current)) {
        secondaryButtonRef.current?.click()
      } else if (isFocused(primaryButtonRef.current)) {
        primaryButtonRef.current?.click()
      }
    },
    enabled
  )

  useEffect(() => {
    if (enabled && autoFocusOnPrimary) {
      primaryButtonRef.current?.focus()
    }
  }, [enabled, autoFocusOnPrimary])

  return [primaryButtonRef, secondaryButtonRef]
}
