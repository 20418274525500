import Editor, { DiffEditor } from '@monaco-editor/react'
import { Styleable } from '../../theme'
import { useIsDarkTheme } from '../../hooks/useIsDarkTheme'
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { log } from '../../utils/log'

type Props = Styleable & {
  text: string
  baseText?: string
  isDiffView: boolean
  filePath: string // for language detection
  readOnly: boolean
  onChange?: (text: string) => void
  sectionMarginBottomRem?: number
}

const Filler = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.padding.m}rem;
`

const LEFT_MARKER = 'L'
const RIGHT_MARKER = 'R'

type SelectedLine = {
  number: number
  isLeft: boolean
}
const defaultSelectedLine: SelectedLine = { number: 0, isLeft: true }

const getInitialLineNumber = (): SelectedLine => {
  const hash = window.location.hash.replace('#', '')
  const isLeft = hash.startsWith(LEFT_MARKER)
  if (!isLeft && !hash.startsWith(RIGHT_MARKER)) {
    return defaultSelectedLine
  }
  const number = parseInt(hash.substring(1))
  if (isNaN(number)) {
    return defaultSelectedLine
  }
  return { number, isLeft }
}

const setLineNumber = (position: SelectedLine) => {
  window.location.hash = `${position.isLeft ? LEFT_MARKER : RIGHT_MARKER}${position.number}`
}

export const TextFileEditor = ({
  className,
  text,
  baseText,
  isDiffView,
  filePath,
  readOnly,
  onChange,
  sectionMarginBottomRem,
}: Props) => {
  const [monaco, setMonaco] = useState<any>()

  const editorOptions = {
    readOnly,
    contextmenu: false,
    scrollBeyondLastLine: false,
    selectOnLineNumbers: true,
    splitViewDefaultRatio: 0.8,
  }
  const [isDarkTheme] = useIsDarkTheme()
  const editorTheme = isDarkTheme ? 'vs-dark' : 'light'
  const { number: selectedLineNumber, isLeft: selectedLineIsLeft } = getInitialLineNumber()
  const [language, setLanguage] = useState<string>()

  const OverrideStyle = css`
    :is(.monaco-editor) {
      width: 100% !important;
    }

    .line-numbers {
      cursor: pointer !important;
    }

    section {
      padding-bottom: 1rem;
      flex-grow: 1;
    }
  `

  const registerEditorLineNumberWatch = (editor: any, isLeft: boolean) => {
    editor?.onMouseDown(({ target }: any) => {
      const element: HTMLDivElement = target?.element
      if (element?.className === 'line-numbers') {
        const targetLine = parseInt(element.innerText)
        if (isNaN(targetLine)) {
          return
        }
        editor.setPosition({ lineNumber: targetLine, column: 0 })
        setLineNumber({ number: targetLine, isLeft })
      }
    })
  }

  const setEditorLineNumber = (editor: any, lineNumber: number) => {
    if (!editor) {
      return
    }
    const position = { lineNumber: lineNumber, column: 0 }
    editor.setPosition(position)
    editor.revealPosition(position)
  }

  const injectElementStyle = (element: any) => {
    const sectionElement = element.closest('section')
    if (sectionMarginBottomRem && sectionElement) {
      sectionElement.style = sectionElement.style + `; margin-bottom: ${sectionMarginBottomRem}rem;`
    }
  }

  const onEditorMount = (editor: any, monaco: any, isDiffEditor: boolean) => {
    // see https://microsoft.github.io/monaco-editor/typedoc/interfaces/editor.IEditor.html
    // as these types can't be found via ts models for some reason
    if (monaco) {
      setMonaco(monaco)
    }
    const element = isDiffEditor ? editor.B : editor.db
    element && injectElementStyle(element)
    if (isDiffEditor) {
      const { _originalEditor, _modifiedEditor } = editor
      registerEditorLineNumberWatch(_originalEditor, true)
      registerEditorLineNumberWatch(_modifiedEditor, false)
      if (selectedLineNumber !== 0) {
        if (selectedLineIsLeft) {
          setEditorLineNumber(_originalEditor, selectedLineNumber)
        } else {
          setEditorLineNumber(_modifiedEditor, selectedLineNumber)
        }
      }
    } else {
      registerEditorLineNumberWatch(editor, true)
      if (selectedLineNumber !== 0) {
        setEditorLineNumber(editor, selectedLineNumber)
      }
    }
  }

  useEffect(() => {
    if (!monaco) {
      return
    }
    let models: any[] = []
    try {
      models = monaco.editor.getModels() || []
    } catch (e) {}
    try {
      if (models.length === 0) {
        const model = monaco.editor.createModel(
          text || baseText,
          undefined, // language
          monaco.Uri.file(filePath)
        )
        models.push(model)
      }
      const languages = models.map((m) => m.getLanguageId()).filter((l) => l !== 'plaintext')
      if (languages.length > 0) {
        log.info('Detected languages', { languages })
        setLanguage(languages[0])
      }
    } catch (e) {}
    // eslint-disable-next-line -- for some reason it want to exclude monacoRef from deps
  }, [baseText, filePath, text, isEmpty(monaco)])

  return (
    <>
      <Global styles={OverrideStyle} />
      <Filler />
      {isDiffView ? (
        <DiffEditor
          className={className}
          originalModelPath={`orig/${filePath}`}
          modifiedModelPath={`mod/${filePath}`}
          modified={text}
          original={baseText}
          options={editorOptions}
          theme={editorTheme}
          onMount={(editor, monaco) => onEditorMount(editor, monaco, true)}
          originalLanguage={language}
          modifiedLanguage={language}
        />
      ) : (
        <Editor
          className={className}
          value={text}
          path={filePath}
          options={editorOptions}
          onChange={onChange ? (value) => onChange(value || '') : undefined}
          theme={editorTheme}
          onMount={(editor, monaco) => onEditorMount(editor, monaco, false)}
        />
      )}
      <Filler />
    </>
  )
}
