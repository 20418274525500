import { useCopyToClipboard } from 'usehooks-ts'
import { useCallback } from 'react'
import { infoToast } from '../utils/toast'

export type CopyToClipboardAction = (value: string, message: string) => void

export const useCopyToClipboardAction = (): CopyToClipboardAction => {
  const [, copy] = useCopyToClipboard()
  return useCallback<CopyToClipboardAction>(
    async (value, message) => {
      await copy(value)
      infoToast(message, true)
    },
    [copy]
  )
}
