import { Loader } from '../base/Loader'
import { OverlayFiller } from '../base/OverlayFiller'
import { useResizeObserver, useSessionStorage } from 'usehooks-ts'
import styled from '@emotion/styled'
import { ReactNode, useRef } from 'react'
import {
  remToPx,
  SIDE_PANEL_MAX_WIDTH_REM,
  SIDE_PANEL_MIN_WIDTH_REM,
  SIDE_PANEL_WIDTH_REM,
  Styleable,
} from '../../theme'
import { FlexColumn } from '../base/Flex'
import { Resizable } from 're-resizable'

type SidePanelProps = Styleable & {
  loading?: boolean
  hasOutletContent: boolean
  children: ReactNode
  contextName: string
  minWidth?: number
  maxWidth?: number
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.stroke};
`

const Panel = styled(FlexColumn)`
  width: 100%;
  height: 100%;
`

export const SidePanel = ({
  className,
  loading,
  hasOutletContent,
  children,
  contextName,
  minWidth = SIDE_PANEL_MIN_WIDTH_REM,
  maxWidth = SIDE_PANEL_MAX_WIDTH_REM,
}: SidePanelProps) => {
  const panelRef = useRef<HTMLDivElement>(null)
  const { width: panelWidth = 0 } = useResizeObserver({ ref: panelRef, box: 'border-box' })
  const [sidePanelWidth, setSidePanelWidth] = useSessionStorage(
    `${contextName}_sidePanelWidth`,
    remToPx(SIDE_PANEL_WIDTH_REM)
  )
  return loading ? (
    <Loader />
  ) : (
    <Resizable
      minWidth={remToPx(minWidth)}
      maxWidth={remToPx(maxWidth)}
      defaultSize={{ width: remToPx(SIDE_PANEL_WIDTH_REM) }}
      size={{ width: sidePanelWidth }}
      onResizeStop={(_, __, ___, d) => setSidePanelWidth(sidePanelWidth + d.width)}
      enable={{ right: true }}
    >
      <Container className={className}>
        <Panel ref={panelRef}>{children}</Panel>
        {!hasOutletContent && <OverlayFiller left={panelWidth} />}
      </Container>
    </Resizable>
  )
}
