import { Tab, TabProps } from './Tab'
import { routeToWorkspaceEdit, routeToWorkspaceView } from '../../../Routes'
import isNil from 'lodash/isNil'
import { FlexRow } from '../../base/Flex'

type RawTabProps = Omit<TabProps, 'position'>
type TabsProps = {
  tabs: RawTabProps[]
}

export const Tabs = ({ tabs }: TabsProps) => (
  <FlexRow>
    {tabs.map((tab, i) => (
      <Tab key={tab.title} position={i} {...tab} />
    ))}
  </FlexRow>
)

type SidePanelMode = 'view' | 'edit'

type SidePanelTabsProps = {
  repoId: string
  workspaceId: string
  selectedMode?: SidePanelMode
}

export const SidePanelTabs = ({ repoId, workspaceId, selectedMode }: SidePanelTabsProps) => {
  const workspaceTab: RawTabProps = {
    selected: selectedMode === 'edit',
    title: 'Workspace',
    redirectTo: routeToWorkspaceEdit(repoId, workspaceId),
  }
  const historyTab: RawTabProps = {
    selected: isNil(selectedMode) || selectedMode === 'view',
    title: 'History',
    redirectTo: routeToWorkspaceView(repoId, workspaceId),
  }
  return <Tabs tabs={isNil(selectedMode) ? [historyTab] : [workspaceTab, historyTab]} />
}
