import styled from '@emotion/styled'
import SettingsIcon from '@mui/icons-material/Settings'
import { routeToWorkspaceSettings } from '../../Routes'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router'

type Props = {
  repoId: string
  workspaceId: string
}

const Container = styled.div`
  align-self: center;
`

const WorkspaceConfigurationButton = styled(SettingsIcon)`
  margin: 0 ${({ theme }) => theme.padding.l}rem;
  cursor: pointer;
`

export const ToWorkspaceSettings = ({ repoId, workspaceId }: Props) => {
  const navigate = useNavigate()
  return (
    <Tooltip title={'Workspace Settings'} arrow>
      <Container onClick={() => navigate(routeToWorkspaceSettings(repoId, workspaceId))}>
        <WorkspaceConfigurationButton />
      </Container>
    </Tooltip>
  )
}
