import { ActionDialog } from './ActionDialog'
import { Progress } from 'antd'
import styled from '@emotion/styled'

type ProgressPair = {
  completed: number
  total: number
}

type Props = {
  title: string
  isOpen: boolean
  setOpen: (open: boolean) => void
  discreteProgress?: ProgressPair
  continuousProgress: ProgressPair
}

const StyledProgress = styled(Progress)``

export const ProgressDialog = ({ title, isOpen, setOpen, discreteProgress, continuousProgress }: Props) => {
  return (
    <ActionDialog
      title={title}
      message={
        <>
          {discreteProgress && (
            <div>
              Completed {discreteProgress.completed} of {discreteProgress.total}
            </div>
          )}
          <StyledProgress
            percent={Math.round((100 * continuousProgress.completed) / continuousProgress.total)}
            status="active"
          />
        </>
      }
      isOpen={isOpen}
      setOpen={setOpen}
      onConfirmAsync={async () => {}}
      confirmButtonLabel=""
      cancelButtonLabel="Hide"
      loadingMessage=""
      noPrimaryAction
    />
  )
}
