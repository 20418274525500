import { useCallback, useState } from 'react'
import { ViewChangesSwitch } from '../base/ViewChangesSwitch'
import styled from '@emotion/styled'
import { useChangedInRef, useTreeData } from '../../hooks/api/useTreeData'
import { Loader } from '../base/Loader'
import { DirStructureTreeView, ReloadIfHasUnloadedChild } from '../tree/DirStructureTreeView'
import { appendFilePath } from '../../Routes'
import { useSessionStorage } from 'usehooks-ts'
import { useTreeSearch } from '../../hooks/api/useTreeSearch'
import debounce from 'lodash/debounce'
import { TreeViewOptions } from '../tree/TreeViewOptions'
import { FlatDirsView } from '../tree/FlatDirsView'
import { useCommit } from '../../hooks/api/useCommit'
import { FlexColumn } from '../base/Flex'

type Props = {
  repoId: string
  commitId: string
  compareCommitId?: string
  allowViewChangesSwitch: boolean
  fileUrlPrefix: string
  selectedFilePath?: string
}

const Container = styled(FlexColumn)`
  height: 100%;
  padding-bottom: 8.5rem;
  background-color: ${({ theme }) => theme.colors.background};
`

const maxDepthToFetchInTree = 2
export const CommitTree = ({
  repoId,
  commitId,
  compareCommitId,
  allowViewChangesSwitch,
  fileUrlPrefix,
  selectedFilePath,
}: Props) => {
  const { parentCommitId } = useCommit(repoId, commitId)
  const { treeData, loading, onExpandNodeAsync, isNewTree } = useTreeData(
    repoId,
    commitId,
    compareCommitId || parentCommitId,
    {},
    maxDepthToFetchInTree,
    undefined,
    true
  )
  const [treeViewOption, setTreeViewOption] = useSessionStorage<TreeViewOptions>(
    `commit.treeView.${allowViewChangesSwitch}`,
    allowViewChangesSwitch ? 'all' : 'list_changes'
  )
  const [query, setQuery] = useState<string>()
  const { data: searchResultKeys, loading: searchLoading } = useTreeSearch(repoId, commitId, query)
  const { data: changesData, loading: changesLoading } = useChangedInRef(
    'TreeData',
    repoId,
    commitId,
    compareCommitId || parentCommitId
  )
  const reloadIfSelected = useCallback(ReloadIfHasUnloadedChild, [])

  return (
    <Container>
      {loading ? (
        <Loader addPadding />
      ) : (
        <>
          {allowViewChangesSwitch && (
            <>
              <ViewChangesSwitch selected={treeViewOption} setSelected={setTreeViewOption} />
            </>
          )}
          {treeViewOption === 'list_changes' ? (
            <FlatDirsView
              changedItems={changesData?.items}
              changesLoading={changesLoading}
              selectedNodeKey={selectedFilePath}
              redirectRouteOnClick={(path) => appendFilePath(fileUrlPrefix, path)}
              enableWorkspaceActions={false}
            />
          ) : (
            <DirStructureTreeView
              treeId={`${commitId}_${compareCommitId || '-'}`}
              treeData={treeData!}
              redirectRouteOnClick={(path) => appendFilePath(fileUrlPrefix, path)}
              selectedNodeKey={selectedFilePath}
              changedOnly={treeViewOption === 'tree_changes'}
              onExpandNodeAsync={onExpandNodeAsync}
              onSearch={debounce(setQuery)}
              searchLoading={searchLoading}
              searchResultKeys={searchResultKeys}
              enableWorkspaceActions={false}
              loadOnSelectOrExpand={reloadIfSelected}
              isNewTree={isNewTree}
            />
          )}
        </>
      )}
    </Container>
  )
}
