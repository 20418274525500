import { ExpandableProps, RefDropdown } from './RefDropdown'
import AddToQueueOutlinedIcon from '@mui/icons-material/AddToQueueOutlined'
import CallSplitIcon from '@mui/icons-material/CallSplit'
import { BranchSelector } from '../selector/BranchSelector'
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { log } from '../../utils/log'
import { isUnderFileHistory, routeToBranchView, routeToWorkspaceEdit } from '../../Routes'
import { useNavigate } from 'react-router-dom'
import { notNil, tryGet } from '../../utils/objectUtil'
import { InDetachedCommitContext } from '../commit/DetachedCommitRoute'
import { stripPrefixOfCommitId } from '../../utils/textUtils'
import { useBranchesById, useRefreshBranches } from '../../hooks/api/useBranches'
import { useWorkspaces } from '../../hooks/api/useWorkspaces'
import { WorkspaceName } from '../base/WorkspaceName'
import { PrimaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { NewWorkspaceDialog } from '../dialogs/NewWorkspaceDialog'
import { InMergesContext } from '../merge/MergesRoute'
import { useTimeout } from 'usehooks-ts'
import { useLocation } from 'react-router'
import { FlexRow, FlexRowStyle } from '../base/Flex'
import { NewBranchDialog } from '../dialogs/NewBranchDialog'
import { ShelfChangesDialog } from '../branch/ShelfChangesDialog'
import { ApplyShelfDialog } from '../branch/ApplyShelfDialog'
import { Shelf } from '../../api/coreapi'
import { useBranch } from '../../hooks/api/useBranch'

const ButtonsRow = styled.div`
  ${FlexRowStyle};
  gap: 1rem;
  margin: ${({ theme }) => theme.padding.m}rem ${({ theme }) => theme.padding.l}rem;
`

const CreateNewButton = styled(PrimaryButton)`
  width: 100%;
`

type Props = ExpandableProps & {
  repoId: string
  branchId?: string
  commitId?: string
  workspaceId?: string
  offsetLeftPx: number
  setActionDialogOpen: Dispatch<SetStateAction<boolean>>
}

export const BranchDropdown = ({
  repoId,
  branchId,
  commitId,
  workspaceId,
  expanded,
  setExpanded,
  offsetLeftPx,
  setActionDialogOpen,
}: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { workspacesById, workspacesLoading, refresh: refreshWorkspaces } = useWorkspaces(repoId)
  const refreshBranches = useRefreshBranches(repoId)
  const { inMergesContext } = useContext(InMergesContext)
  const { inDetachedCommitContext } = useContext(InDetachedCommitContext)
  const [newWorkspaceDialogOpen, setNewWorkspaceDialogOpen] = useState(false)
  const [newBranchDialogOpen, setNewBranchDialogOpen] = useState(false)
  const inWorkspaceContext = notNil(workspaceId)
  const { branchesById, loading: branchesLoading } = useBranchesById(repoId)
  const selectedBranch = tryGet(branchesById, branchId)
  const selectedWorkspace = tryGet(workspacesById, workspaceId)
  const [, setRedirectTo] = useState<string>()
  const [shelfChangesDialogIsOpen, setShelfChangesDialogIsOpen] = useState(false)
  const [applyShelfDialogIsOpen, setApplyShelfDialogIsOpen] = useState(false)
  const [shelves, setShelves] = useState<Shelf[]>([])
  const [newlyCreatedBranchId, setNewlyCreatedBranchId] = useState<string>()
  const { branch: newlyCreatedBranch } = useBranch(repoId, newlyCreatedBranchId)
  const [workspaceIdForRedirection, setWorkspaceIdForRedirection] = useState<string>()
  const mergedSetDialogOpen = useCallback(
    (open: boolean, setter: Dispatch<SetStateAction<boolean>>) => {
      if (open) {
        setExpanded(false)
      }
      setter(open)
      setActionDialogOpen(open)
    },
    [setActionDialogOpen, setExpanded]
  )

  useEffect(() => {
    if (
      branchId ||
      commitId ||
      inWorkspaceContext ||
      inDetachedCommitContext ||
      inMergesContext ||
      workspacesLoading ||
      branchesLoading ||
      isUnderFileHistory(pathname)
    ) {
      setRedirectTo(undefined)
      return
    }
    if (Object.keys(workspacesById).length === 1) {
      const singleWorkspaceId = Object.keys(workspacesById)[0]!
      log.info('Found only a single workspace - selecting it', { repoId, workspaceId: singleWorkspaceId })
      setRedirectTo(routeToWorkspaceEdit(repoId, singleWorkspaceId))
    } else if (Object.keys(workspacesById).length === 0 && branchesById && Object.keys(branchesById).length === 1) {
      const singleBranchId = Object.keys(branchesById)[0]!
      log.info('Found only a single branch - selecting it', { repoId, branchId: singleBranchId })
      setRedirectTo(routeToBranchView(repoId, singleBranchId, branchesById[singleBranchId]!.commit_id))
    }
  }, [
    branchId,
    branchesById,
    branchesLoading,
    commitId,
    inDetachedCommitContext,
    inMergesContext,
    inWorkspaceContext,
    navigate,
    pathname,
    repoId,
    workspacesById,
    workspacesLoading,
  ])

  const redirect = useCallback(() => {
    setRedirectTo((to) => {
      if (to) {
        setTimeout(() => navigate(to, { replace: true }))
      }
      return undefined
    })
  }, [navigate])
  useTimeout(() => redirect(), 1000)

  const title =
    workspaceId || commitId || branchId
      ? inWorkspaceContext
        ? 'Workspace'
        : `Viewing ${inDetachedCommitContext ? 'Commit' : 'Branch'}`
      : 'Select a branch or workspace'

  const sourceBranch = inDetachedCommitContext
    ? undefined
    : selectedWorkspace
      ? tryGet(branchesById, selectedWorkspace.branch_id)
      : selectedBranch

  return (
    <>
      <NewWorkspaceDialog
        repoId={repoId}
        isOpen={newWorkspaceDialogOpen}
        setOpen={(open) => mergedSetDialogOpen(open, setNewWorkspaceDialogOpen)}
        onCreated={(workspaceId) => {
          refreshWorkspaces()
          setExpanded(false)
          navigate(routeToWorkspaceEdit(repoId, workspaceId))
        }}
      />
      <NewBranchDialog
        repoId={repoId}
        sourceBranchOrCommit={sourceBranch}
        isOpen={newBranchDialogOpen}
        setOpen={(open) => mergedSetDialogOpen(open, setNewBranchDialogOpen)}
        onCreated={(branchId, commitId, workspaceId) => {
          refreshBranches()
          setExpanded(false)
          if (workspaceId) {
            navigate(routeToWorkspaceEdit(repoId, workspaceId))
          } else {
            navigate(routeToBranchView(repoId, branchId, commitId))
          }
        }}
        onHasPendingChanges={(branchId, workspaceId) => {
          setNewlyCreatedBranchId(branchId)
          setWorkspaceIdForRedirection(workspaceId)
          setShelfChangesDialogIsOpen(true)
        }}
        onHasShelvedChanges={(shelves, branchId, workspaceId) => {
          setShelves(shelves)
          setNewlyCreatedBranchId(branchId)
          setWorkspaceIdForRedirection(workspaceId)
          setApplyShelfDialogIsOpen(true)
        }}
      />
      <ShelfChangesDialog
        isOpen={shelfChangesDialogIsOpen}
        setOpen={setShelfChangesDialogIsOpen}
        branch={newlyCreatedBranch}
        onSuccess={() => {
          if (workspaceIdForRedirection) {
            navigate(routeToWorkspaceEdit(repoId, workspaceIdForRedirection))
            setWorkspaceIdForRedirection(undefined)
          }
        }}
      />
      <ApplyShelfDialog
        isOpen={applyShelfDialogIsOpen}
        setOpen={setApplyShelfDialogIsOpen}
        branch={newlyCreatedBranch}
        shelves={shelves}
        onSuccess={() => {
          if (workspaceIdForRedirection) {
            navigate(routeToWorkspaceEdit(repoId, workspaceIdForRedirection))
            setWorkspaceIdForRedirection(undefined)
          }
        }}
      />
      <RefDropdown
        loading={branchesLoading || workspacesLoading}
        title={title}
        selectedTitle={
          inDetachedCommitContext ? (
            stripPrefixOfCommitId(commitId)
          ) : selectedWorkspace ? (
            <WorkspaceName workspace={selectedWorkspace} branch={tryGet(branchesById, selectedWorkspace.branch_id)} />
          ) : (
            selectedBranch?.branch_name
          )
        }
        expanded={expanded}
        setExpanded={setExpanded}
        offsetLeftPx={offsetLeftPx}
      >
        <ButtonsRow>
          <CreateNewButton
            disabled={false}
            title="Create New Workspace"
            onClick={() => mergedSetDialogOpen(true, setNewWorkspaceDialogOpen)}
          >
            <FlexRow gap={0.5} centered={true}>
              <AddToQueueOutlinedIcon />
              New Workspace
            </FlexRow>
          </CreateNewButton>
          <CreateNewButton
            disabled={false}
            title="Create New Branch"
            onClick={() => mergedSetDialogOpen(true, setNewBranchDialogOpen)}
          >
            <FlexRow gap={0.5} centered={true}>
              <CallSplitIcon />
              New Branch
            </FlexRow>
          </CreateNewButton>
        </ButtonsRow>
        {!branchesLoading && !workspacesLoading && (
          <BranchSelector
            repoId={repoId}
            selectedBranchId={branchId}
            selectedWorkspaceId={workspaceId}
            branchesById={branchesById}
            workspacesById={workspacesById}
            branchLink={(branch) => routeToBranchView(repoId, branch.branch_id, branch.commit_id)}
          />
        )}
      </RefDropdown>
    </>
  )
}
