import styled from '@emotion/styled'
import { TextBig, TextBold } from '../base/TextStyle'
import { BannerPadding, PaddingExtra } from '../base/PaddingStyle'
import { PrimaryButton } from '../base/PrimaryButton'
import { FlexColumn, FlexFiller, FlexRow } from '../base/Flex'
import debounce from 'lodash/debounce'
import { DirStructureTreeView, ReloadIfHasUnloadedChild } from '../tree/DirStructureTreeView'
import { useUrlState } from '../../hooks/useUrlState'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTreeData } from '../../hooks/api/useTreeData'
import { useTreeSearch } from '../../hooks/api/useTreeSearch'
import { Loader } from '../base/Loader'
import isEmpty from 'lodash/isEmpty'
import { useApi } from '../../hooks/useApi'
import { RepositoryWorkspaceManipulationService, WorkspacePreferences } from '../../api/coreapi'
import { Checkbox } from '../base/Checkbox'
import { log } from '../../utils/log'
import { errorToast, infoToast } from '../../utils/toast'
import isNil from 'lodash/isNil'
import { ApiError } from '../../api/coreapi'
import { useAnalytics } from '../../hooks/api/useAnalytics'

const Container = styled(FlexColumn)`
  height: fit-content;
  width: fit-content;
  max-height: calc(100% - 8rem);
  max-width: calc(100% - 4rem);
  color: ${({ theme }) => theme.colors.black.primary};
  background-color: ${({ theme }) => theme.colors.blue.hover};
  ${PaddingExtra};
  margin: 2rem;
  border-radius: 1rem;
  gap: 1.5rem;
`

const Title = styled.div`
  ${TextBig};
  color: ${({ theme }) => theme.colors.blue.primary};
`

const SectionTitle = styled.div`
  ${TextBold};
`

const TreeWrapper = styled.div`
  width: 600px;
  padding: 2rem;
  margin: 1rem;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 1rem;
  overflow-y: auto;
  overflow-x: inherit;
`

const SelectAllRow = styled(FlexRow)`
  gap: 1.5rem;
  color: ${({ theme }) => theme.colors.black.secondary};
  ${BannerPadding};
`

const usePostWorkspacePreferencesAsync = (
  repoId: string,
  workspaceId: string,
  setSaving: (saving: boolean) => void
) => {
  const postAnalytics = useAnalytics()

  return useCallback(
    async (preferences: WorkspacePreferences) => {
      log.info('saving ws preferences', { repoId, workspaceId, preferences })
      setSaving(true)
      try {
        await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceSetPreferences({
          repoId,
          workspaceId,
          requestBody: preferences,
        })
        postAnalytics('WorkspacePreferencesUpdated', {
          repo_id: repoId,
          workspace_id: workspaceId,
        })
        infoToast('Workspace settings saved', true)
      } catch (e) {
        if (e instanceof ApiError && e.status === 409) {
          const msg = e.body?.detail || ''
          errorToast(msg)
        } else {
          errorToast()
          log.error('Unexpected error on set preferences', e)
        }
      } finally {
        setSaving(false)
      }
    },
    [postAnalytics, repoId, setSaving, workspaceId]
  )
}

const maxDepthToFetchInTree = 2
export const WorkspaceSettingsRoute = () => {
  const { repoId, workspaceId } = useUrlState()
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const { data: wsPreferences, loading: preferencesLoading } = useApi<WorkspacePreferences>(
    ['repos', repoId, 'workspaces', workspaceId, 'preferences'],
    () =>
      RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceGetPreferences({
        repoId: repoId!,
        workspaceId: workspaceId!,
      })
  )
  const {
    treeData,
    loading: treeLoading,
    onExpandNodeAsync,
    isNewTree,
  } = useTreeData(
    repoId!,
    workspaceId!,
    undefined,
    {
      staticView: true,
      dirsOnly: true,
      useSelectiveSync: false,
      skipMergeSingleChildren: true,
    },
    maxDepthToFetchInTree
  )
  const [query, setQuery] = useState<string>()
  const { data: searchResultKeys, loading: searchLoading } = useTreeSearch(repoId!, workspaceId!, query, true)
  const topLevelKeys = useMemo(() => treeData?.root.children?.map((node) => node.key), [treeData?.root.children])
  const [allChecked, setAllChecked] = useState<boolean | null>(false)
  const [saving, setSaving] = useState(false)
  const postWorkspacePreferencesAsync = usePostWorkspacePreferencesAsync(repoId!, workspaceId!, setSaving)
  const loading = preferencesLoading || treeLoading
  const saveDisabled = loading || saving || checkedKeys.length === 0

  useEffect(() => {
    if (isNil(wsPreferences)) {
      return
    }
    if (isEmpty(wsPreferences?.sync_paths_rules)) {
      setAllChecked(true)
      return
    }
    setCheckedKeys(wsPreferences!.sync_paths_rules)
  }, [topLevelKeys, wsPreferences])

  useEffect(() => {
    if (loading) {
      return
    }
    setAllChecked(isEmpty(checkedKeys) ? false : topLevelKeys?.every((key) => checkedKeys.includes(key)) ? true : null)
  }, [checkedKeys, loading, topLevelKeys])

  useEffect(() => {
    if (allChecked) {
      setCheckedKeys(topLevelKeys || [])
    }
  }, [allChecked, topLevelKeys])

  const reloadIfSelected = useCallback(ReloadIfHasUnloadedChild, [])
  return (
    <Container>
      <Title>Workspace Settings</Title>
      <SectionTitle>Selective Sync</SectionTitle>
        Select which folders in the repo will be synced locally. This only affects the current workspace.
        <br/>* If a folder is checked, its contents will be synced to the local workspace directory, including all subfolders.
        <br/>* If a folder is unchecked, it will not be synced locally.
        <br/>* If a folder is partially checked, its subfolders will be synced only if they are checked.
        <br/>* If a folder was previously synced and is then unchecked, it will be deleted.
        <br/>Selective sync settings cannot be changed while there are pending changes in the workspace.
      {loading ? (
        <Loader addPadding />
      ) : (
        <TreeWrapper>
          <SelectAllRow>
            <Checkbox
              title="Select all"
              checked={allChecked}
              setChecked={(checked) => setCheckedKeys(checked ? topLevelKeys || [] : [])}
            />
            <div>Select all</div>
          </SelectAllRow>
          <DirStructureTreeView
            treeId={workspaceId!}
            treeData={treeData!}
            checkedKeys={checkedKeys}
            onChecked={(keys: string[]) => setCheckedKeys(keys)}
            setCheckedPathsCount={() => {}}
            redirectRouteOnClick={(path) => ''}
            changedOnly={false}
            onExpandNodeAsync={onExpandNodeAsync}
            onSearch={debounce(setQuery)}
            searchLoading={searchLoading}
            searchResultKeys={searchResultKeys}
            enableWorkspaceActions={false}
            loadOnSelectOrExpand={reloadIfSelected}
            isNewTree={isNewTree}
          />
        </TreeWrapper>
      )}
      <FlexFiller />
      <PrimaryButton
        disabled={saveDisabled}
        onClick={() =>
          !saveDisabled && postWorkspacePreferencesAsync({ sync_paths_rules: allChecked ? [] : checkedKeys })
        }
      >
        {loading ? <Loader size={30} /> : 'Save'}
      </PrimaryButton>
    </Container>
  )
}
