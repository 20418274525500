import { osName, OsTypes } from 'react-device-detect'
import { FlexRow } from '../base/Flex'
import { PrimaryButton } from '../base/PrimaryButton'
import { openInExternalBrowser } from '../../desktop/components/utils/openInExternalBrowser'
import { DesktopAppVersion } from '../../desktop/components/utils/DesktopAppApi'
import config from '../../env/config'
import { AgentStartMinVersionSupport } from '../../desktop/components/utils/startAgent'

export const MacDesktopUpdateNotice = () => {
  const onMac = osName === OsTypes.MAC_OS
  const desktopAppVersion = DesktopAppVersion()
  const onOldVersion = !!desktopAppVersion && desktopAppVersion < AgentStartMinVersionSupport
  const downloadUrl = config.IS_DEV_ENV
    ? 'https://dl.todesktop.com/240527plqkkuhxe/mac/installer/universal'
    : 'https://dl.diversion.dev/mac'
  return (
    onMac &&
    onOldVersion && (
      <FlexRow gap={1} centered={true}>
        A new version of Diversion Desktop App available, please{' '}
        <PrimaryButton disabled={false} onClick={() => openInExternalBrowser(downloadUrl)}>
          click here
        </PrimaryButton>{' '}
        to download it.
      </FlexRow>
    )
  )
}
