import { ApiError, Error as CoreApiError, FileEntry } from '../../api/coreapi'
import config from '../../env/config'
import { BareFetcher } from 'swr'
import { isBlobRedirectError, isFileNotFoundError } from '../../utils/errorClassify'
import { BlobState } from '../../models/fileState'
import { BlobResponse } from '../../models/BlobResponse'
import { fetchRawAsync } from '../../api/fetcher'
import { isDirectory } from '../../models/fileMode'

const isFileTooLargeToPreview = (fileSizeBytes: number) => fileSizeBytes > config.MAXIMUM_DISPLAYED_FILE_SIZE_BYTES

const emptyBlob = (): Blob => new Blob([''])

const emptyBlobState = (id: string): BlobState => ({
  id,
  blob: emptyBlob(),
  sizeBytes: 0,
  isFileTooLarge: false,
  existsOnServer: false,
  isDirectory: false,
})

const getBlobResponseAsync = async (fetcher: BareFetcher<Blob>): Promise<BlobResponse> => {
  try {
    const response = await fetcher()
    return { blob: response, downloadUrl: undefined }
  } catch (e) {
    if (isBlobRedirectError(e)) {
      return { blob: undefined, downloadUrl: (e as ApiError).headers['location'] }
    }
    throw e
  }
}

const getBlobAsync = async (isFileTooLarge: boolean, blobResponse: BlobResponse): Promise<Blob | undefined> => {
  const { blob, downloadUrl } = blobResponse
  if (blob) {
    return blob
  }
  if (isFileTooLarge || !downloadUrl) {
    return undefined
  }
  return await (await fetchRawAsync(downloadUrl)).blob()
}

export const getBlobStateAsync = async (
  id: string,
  knownToBeMissing: boolean,
  getFileMetadataAsync: BareFetcher<FileEntry | CoreApiError>,
  getBlobOrRedirectAsync: BareFetcher<Blob>
): Promise<BlobState> => {
  try {
    if (knownToBeMissing) {
      return emptyBlobState(id)
    }
    const metadata = (await getFileMetadataAsync()) as FileEntry
    if (isDirectory(metadata.mode)) {
      return { ...emptyBlobState(id), isDirectory: true, existsOnServer: true }
    }
    const isFileTooLarge = isFileTooLargeToPreview(metadata.blob?.size || 0)
    const blobState: BlobState = {
      id,
      sizeBytes: metadata.blob?.size || 0,
      existsOnServer: true,
      isFileTooLarge,
      isDirectory: false,
    }
    if (blobState.sizeBytes === 0) {
      return { ...blobState, blob: new Blob(), downloadUrl: undefined }
    }
    const blobResponse = await getBlobResponseAsync(getBlobOrRedirectAsync)
    const blob = await getBlobAsync(isFileTooLarge, blobResponse)
    return { ...blobState, blob, downloadUrl: blobResponse.downloadUrl }
  } catch (e) {
    if (isFileNotFoundError(e)) {
      return emptyBlobState(id)
    }
    throw e
  }
}
