/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollaborationInvite } from '../models/CollaborationInvite';
import type { CollaborationInviteRequest } from '../models/CollaborationInviteRequest';
import type { CollaborationRequest } from '../models/CollaborationRequest';
import type { Collaborator } from '../models/Collaborator';
import type { Error } from '../models/Error';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CollaboratorsService {
    /**
     * Get all collaborators and pending invites for repo
     * @returns any A list of collaborators and pending invites
     * @throws ApiError
     */
    public static srcHandlersCollaborationListCollaborators({
        repoId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
    }): CancelablePromise<{
        collaborators?: Array<Collaborator>;
        invites?: Array<CollaborationInvite>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/collaborators',
            path: {
                'repo_id': repoId,
            },
        });
    }
    /**
     * Change a user's access level to the repo
     * @returns any Request completed successfully
     * @throws ApiError
     */
    public static srcHandlersCollaborationUpdateMode({
        repoId,
        userId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A user's ID
         */
        userId: string,
        requestBody?: CollaborationRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/repos/{repo_id}/collaborators/{user_id}',
            path: {
                'repo_id': repoId,
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Revoke a user's access level to the repo
     * @returns any Request completed successfully
     * @throws ApiError
     */
    public static srcHandlersCollaborationRevokeAccess({
        repoId,
        userId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A user's ID
         */
        userId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/repos/{repo_id}/collaborators/{user_id}',
            path: {
                'repo_id': repoId,
                'user_id': userId,
            },
        });
    }
    /**
     * Invite a collaborator to a repository
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2InvitesCreateNew({
        repoId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        requestBody: CollaborationInviteRequest,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/repos/{repo_id}/invites',
            path: {
                'repo_id': repoId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Resend existing invite
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2InvitesResend({
        repoId,
        inviteId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Invite ID, as returned by a previous call
         *
         */
        inviteId: number,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/invites/{invite_id}',
            path: {
                'repo_id': repoId,
                'invite_id': inviteId,
            },
        });
    }
    /**
     * Revoke existing invite
     * @returns any Request completed successfully
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2InvitesRevoke({
        repoId,
        inviteId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Invite ID, as returned by a previous call
         *
         */
        inviteId: number,
    }): CancelablePromise<any | Error> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/repos/{repo_id}/invites/{invite_id}',
            path: {
                'repo_id': repoId,
                'invite_id': inviteId,
            },
        });
    }
}
