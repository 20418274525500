import { useItemListApi } from '../useApi'
import { Repo, RepositoryManagementService } from '../../api/coreapi'
import { useMemo } from 'react'
import { sortBy } from 'lodash'

export const useRepos = (ownedOnly: boolean = false) => {
  const {
    data: repos,
    loading,
    refresh,
  } = useItemListApi<Repo>(['repos', ownedOnly.toString()], () =>
    RepositoryManagementService.srcHandlersv2RepoListAll({ owned: ownedOnly ? true : undefined })
  )
  const sortedRepos = useMemo(() => (repos ? sortBy(repos, (repo) => repo.repo_name.toLowerCase()) : repos), [repos])
  return { repos: sortedRepos, loading, refresh }
}
