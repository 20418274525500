import { useItemListApi } from '../useApi'
import { Merge, RepositoryMergeManipulationService } from '../../api/coreapi'

export const useMerges = (repoId?: string) => {
  const { data: merges, loading } = useItemListApi<Merge>(
    ['repos', repoId, 'merges', 'notResolved', 'open'],
    repoId
      ? () =>
          RepositoryMergeManipulationService.srcHandlersv2MergeListOpenMerges({
            repoId,
          })
      : null
  )
  return { merges, loading }
}
