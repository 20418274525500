import { useCallback, useContext } from 'react'
import { FileMutationService } from '../../../../api/coreapi'
import { clientId } from '../../../../api/configure'
import { infoToast } from '../../../../utils/toast'
import { useAnalytics } from '../../../../hooks/api/useAnalytics'
import { useNavigate } from 'react-router'
import { routeToWorkspaceEdit } from '../../../../Routes'
import { ActionValidationContext } from '../actionValidationContext'

export const useDeleteAsync = (repoId: string, workspaceId: string) => {
  const navigate = useNavigate()
  const { revalidateState } = useContext(ActionValidationContext)
  const postAnalytics = useAnalytics()
  return useCallback(
    async (filePath: string) => {
      await FileMutationService.srcHandlersv2FilesDeleteFile({
        repoId,
        refId: workspaceId,
        xDvClientId: clientId(),
        path: filePath,
      })
      infoToast('Item deleted successfully', true)
      revalidateState()
      postAnalytics('DeleteItems', {
        files_count: '1',
        path: filePath,
      })
      navigate(routeToWorkspaceEdit(repoId, workspaceId))
    },
    [navigate, postAnalytics, revalidateState, repoId, workspaceId]
  )
}
