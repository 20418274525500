import React, { useCallback, useContext } from 'react'
import { StyledDialogActions, StyledDialogContent } from '../dialogs/DialogStyle'
import { Loader } from '../base/Loader'
import { DangerButton, PrimaryButton, SecondaryButton } from '../base/PrimaryButton'
import Dialog from '@mui/material/Dialog'
import { useMountedState } from '../../hooks/useMountedState'
import styled from '@emotion/styled'
import { TextTitle } from '../base/TextStyle'
import { useCheckoutAsync } from './useCheckout'
import { useUrlState } from '../../hooks/useUrlState'
import { callAsync } from '../../utils/callAsync'
import { PublishApiErrorContext } from '../../App'
import { useApplyShelfAsync, useShelfChangesAsync } from './useShelf'
import { usePostResetAsync } from '../sidepanel/workspace/reset/usePostResetAsync'
import { formatDate } from '../../utils/dateUtils'
import { Branch } from '../../api/coreapi'
import { useCachedWorkspace } from '../../hooks/useCachedWorkspace'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  branch: Branch | undefined
  onSuccess: () => void
}

const Title = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
`

const CancelButton = styled(SecondaryButton)`
  margin: 1rem 0;
`

export const ShelfChangesDialog = ({ isOpen, setOpen, branch, onSuccess }: Props) => {
  const [loading, setLoading] = useMountedState(false)
  const onApiError = useContext(PublishApiErrorContext)
  const { repoId, workspaceId: workspaceIdFromParam } = useUrlState()
  const { workspace } = useCachedWorkspace(repoId, workspaceIdFromParam)
  const workspaceId = workspace?.workspace_id
  const checkoutAsync = useCheckoutAsync(repoId, workspaceId, () => {
    throw new Error('Unexpected flow')
  })
  const shelfChangesAsync = useShelfChangesAsync(repoId, workspaceId)
  const shelfApplyAsync = useApplyShelfAsync(repoId, workspaceId)
  const resetAsync = usePostResetAsync(repoId, workspaceId, true, undefined)
  const doActionAsync = useCallback(
    async (asyncAction: () => Promise<void>) => {
      await callAsync(asyncAction, setLoading, onApiError, () => setOpen(false))
    },
    [onApiError, setLoading, setOpen]
  )

  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={isOpen} disableEscapeKeyDown>
      <StyledDialogContent centerText={loading}>
        <Title>Your workspace has pending changes</Title>
        {loading && <Loader />}
      </StyledDialogContent>
      {!loading && (
        <StyledDialogActions column height={18}>
          <PrimaryButton
            disabled={false}
            onClick={() =>
              doActionAsync(async () => {
                const shelf = await shelfChangesAsync(`Temporary shelf (${formatDate(new Date(), false, false)})`, true)
                await checkoutAsync(branch!.branch_id)
                await shelfApplyAsync(shelf.id, true)
                onSuccess()
              })
            }
          >
            Take changes
          </PrimaryButton>
          <SecondaryButton
            onClick={() =>
              doActionAsync(async () => {
                await shelfChangesAsync(`Automatic shelf (${formatDate(new Date(), false, false)})`, true)
                await checkoutAsync(branch!.branch_id)
                onSuccess()
              })
            }
          >
            Save changes for later use
          </SecondaryButton>
          <DangerButton
            onClick={() =>
              doActionAsync(async () => {
                await resetAsync(true)
                await checkoutAsync(branch!.branch_id)
                onSuccess()
              })
            }
          >
            Discard changes
          </DangerButton>
          <CancelButton onClick={() => setOpen(false)}>Cancel checkout</CancelButton>
        </StyledDialogActions>
      )}
    </Dialog>
  )
}
