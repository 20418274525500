import { useApi } from '../useApi'
import { RefFileStatus, RefsFilesStatus, RepositoryWorkspaceManipulationService } from '../../api/coreapi'
import { milliseconds } from 'date-fns'
import { useMemo, useState } from 'react'
import { groupBy } from '../../utils/objectUtil'
import { sanitizePath } from '../../utils/pathUtils'
import sortedUniq from 'lodash/sortedUniq'

const REFRESH_INTERVAL: Duration = { minutes: 1 }

export const useOtherRefsStatus = (repoId: string, workspaceId: string) => {
  const [loadedPaths, setLoadedPaths] = useState<string[]>([''])
  const { data } = useApi<RefsFilesStatus[]>(
    ['repos', repoId, 'workspaces', workspaceId, 'other_statuses', ...loadedPaths],
    async () =>
      await Promise.all(
        loadedPaths.map(
          async (path) =>
            (await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceGetOtherStatuses({
              repoId,
              workspaceId,
              pathPrefix: path,
            })) as RefsFilesStatus
        )
      ),
    false,
    { refreshInterval: milliseconds(REFRESH_INTERVAL) }
  )

  const otherStatusesByPath: Record<string, RefFileStatus[]> = useMemo(() => {
    if (!data) {
      return {}
    }
    return groupBy(
      data.flatMap((status) => status.statuses),
      (workspacesFileStatus) => sanitizePath(workspacesFileStatus.path),
      (item) => item.file_statuses
    )
  }, [data])

  return { otherStatusesByPath, loadPath: (path: string) => setLoadedPaths((p) => sortedUniq([...p, path].sort())) }
}
