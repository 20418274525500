/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * The tier of the user
 */
export enum Tier {
    EDUCATION = 'EDUCATION',
    INDIE = 'INDIE',
    PROFESSIONAL = 'PROFESSIONAL',
    ENTERPRISE = 'ENTERPRISE',
    UNKNOWN = 'UNKNOWN',
}
