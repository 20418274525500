/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Reference = {
    Id: string;
    Type: Reference.Type;
};
export namespace Reference {
    export enum Type {
        BRANCH = 'branch',
        COMMIT = 'commit',
    }
}

