const IMAGE_EXTENSIONS_TO_MIME_TYPE: Record<string, string> = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  bmp: 'image/bmp',
  ico: 'image/vnd.microsoft.icon',
  svg: 'image/svg+xml',
}

const IMAGE_EXTENSIONS = Object.keys(IMAGE_EXTENSIONS_TO_MIME_TYPE).map((ext) => `.${ext}`)

export const isImageByPath = (filePath: string): boolean =>
  Boolean(IMAGE_EXTENSIONS.find((ext) => filePath.endsWith(ext)))

const MODELS_EXTENSIONS: string[] = ['stl']

export const is3dModelByPath = (filePath: string): boolean =>
  Boolean(MODELS_EXTENSIONS.find((ext) => filePath.endsWith(ext)))
