import isEmpty from 'lodash/isEmpty'
import { errorToast } from '../utils/toast'
import { Workspace } from '../api/coreapi'

export const validateWorkSpace = (
  isOpen: boolean,
  workspaceId: string | undefined,
  setOpen: (open: boolean) => void,
  workspace: Workspace | undefined
) => {
  return () => {
    if (!isOpen) {
      return
    }
    if (isEmpty(workspaceId)) {
      errorToast('No workspace selected')
      setOpen(false)
      return
    }
    if (!isEmpty(workspace) && isEmpty(workspace?.branch_id)) {
      errorToast('No branch is checked out')
      setOpen(false)
      return
    }
  }
}
