import { css, Theme } from '@emotion/react'

export const BoxPadding = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.padding.s}rem ${theme.padding.m}rem;
`

export const BannerPadding = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.padding.m}rem ${theme.padding.l}rem;
`

export const PaddingExtra = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.padding.l}rem ${theme.padding.xl}rem;
`
