import React, { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router'
import { useAgentKeepAlive } from '../desktop/hooks/useAgentKeepAlive'
import { useLocalStorage } from 'usehooks-ts'
import { useUserInfo } from '../hooks/api/useUserInfo'
import { DefaultService, Tier } from '../api/coreapi'
import { milliseconds } from 'date-fns'
import { SetUserTierDialog } from './dialogs/SetUserTierDialog'
import { log } from '../utils/log'
import { errorToast } from '../utils/toast'

const A_DAY_IN_MILLIS = milliseconds({ days: 1 })

export const AuthenticatedRootElement = () => {
  const [isUserTierDialogOpen, setIsUserTierDialogOpen] = useState<boolean>(false)
  const [lastTierPopup, setLastTierPopup] = useLocalStorage<number>('last_tier_popup', Date.now())
  const { data: userInfo } = useUserInfo({ refreshInterval: A_DAY_IN_MILLIS })

  useEffect(() => {
    if (userInfo && userInfo.tier === Tier.UNKNOWN) {
      const now = Date.now()
      if (now - lastTierPopup > A_DAY_IN_MILLIS) {
        setLastTierPopup(now)
        setIsUserTierDialogOpen(true)
      }
    }
  }, [lastTierPopup, setLastTierPopup, userInfo])

  const onTierSelect = useCallback(async (tier: Tier) => {
    try {
      await DefaultService.srcHandlersv2AccountUpdateTier({
        requestBody: { tier: tier },
      })
    } catch (e) {
      log.error('Failed to update user tier:', e)
      errorToast('Failed to update user tier. Please contact support.')
      return
    }
  }, [])

  //Initiating the agent keep alive hook here to apply to all pages
  useAgentKeepAlive()

  return (
    <>
      <SetUserTierDialog isOpen={isUserTierDialogOpen} setOpen={setIsUserTierDialogOpen} onConfirm={onTierSelect} />
      <Outlet />
    </>
  )
}
