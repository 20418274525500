import { Branch, Workspace } from '../../api/coreapi'
import styled from '@emotion/styled'
import { TextBold, TextItalic } from './TextStyle'
import { stripPrefixOfCommitId } from '../../utils/textUtils'
import { Ellipsis } from './Ellipsis'

type Props = {
  workspace: Workspace
  branch: Branch | undefined
  workspaceWordPrefix?: boolean
}

const Container = styled.div`
  ${Ellipsis};
`

const Bold = styled.span`
  ${TextBold};
`
const Italic = styled.span`
  ${TextItalic};
`

export const WorkspaceName = ({ workspace, branch, workspaceWordPrefix }: Props) => (
  <Container title={workspace.name}>
    {workspaceWordPrefix && <span>Workspace </span>}
    {branch ? (
      <>
        <Bold>{branch.branch_name}</Bold> (on <Italic>{workspace.name}</Italic>)
      </>
    ) : (
      <>
        <Bold>{workspace.name}</Bold>&nbsp;&nbsp;&nbsp;(on <Italic>commit</Italic>{' '}
        <Bold>{stripPrefixOfCommitId(workspace.source_commit)}</Bold>)
      </>
    )}
  </Container>
)

export const workspaceName = (workspace: Workspace, branch?: Branch) =>
  `${workspace.name} (${workspaceOn(workspace, branch)})`

export const workspaceOn = (workspace: Workspace, branch?: Branch) =>
  `on ${branch ? 'branch' : 'commit'} ${branch ? branch.branch_name : stripPrefixOfCommitId(workspace.source_commit)}`
