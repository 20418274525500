import { CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import { FullPage } from './FullPage'
import { Styleable } from '../../theme'
import { css } from '@emotion/react'

const Container = styled.div<{ addPadding?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ addPadding }) => (addPadding ? '16%' : 0)} 0;
`

const StyledCircularProgress = styled(CircularProgress)<{ size?: number }>`
  color: ${({ theme }) => theme.colors.blue.primary};
  ${({ size }) =>
    size &&
    css`
      height: ${size}px;
      width: ${size}px;
    `}
`

export const Loader = ({ addPadding, className, size }: Styleable & { addPadding?: boolean; size?: number }) => (
  <Container className={className} addPadding={addPadding}>
    <StyledCircularProgress size={size} />
  </Container>
)

const Text = styled.span`
  margin-top: ${({ theme }) => theme.padding.xl}rem;
  color: ${({ theme }) => theme.colors.blue.primary};
`

export const FullPageLoader = () => (
  <FullPage>
    <Loader />
    <Text>Loading...</Text>
  </FullPage>
)
