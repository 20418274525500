import { milliseconds } from 'date-fns'
import { useAgentApi } from '../../hooks/useAgentApi'
import { isAgentStartSupported, startAgentIfSupported } from '../components/utils/startAgent'
import { DefaultService } from '../../api/agentapi'
import { log } from '../../utils/log'
import { sleep } from '../../utils/sleep'
import { initializeAuthSessionAsync } from '../../api/auth'
import { useState } from 'react'

const AGENT_KEEP_ALIVE_INTERVAL = milliseconds({ seconds: 6 })

const WAIT_TIME_FOR_AGENT_START = milliseconds({ seconds: 3 })

export const useAgentKeepAlive = () => {
  const [errorCount, setErrorCount] = useState<number>(0)
  useAgentApi(
    'agent_alive_with_auto_start',
    isAgentStartSupported()
      ? async () => {
          try {
            await DefaultService.isAlive({})
            setErrorCount(0)
            return
          } catch (e) {
            log.info('Try to start the agent', e)
            const isAgentStarted = await startAgentIfSupported()
            if (!isAgentStarted) {
              return
            }
          }

          try {
            //Wait for the agent to fully start before checking if it is alive
            await sleep(WAIT_TIME_FOR_AGENT_START)
            await DefaultService.isAlive({})
            setErrorCount(0)
          } catch (e) {
            setErrorCount((prev) => prev + 1)
            if (errorCount >= 3) {
              log.error('Agent automatic start has failed', e)
            }
            return
          }
          try {
            await initializeAuthSessionAsync()
          } catch (e) {
            log.error('Error in initializing auth session after agent started', e)
          }
        }
      : null,
    false,
    { refreshInterval: AGENT_KEEP_ALIVE_INTERVAL }
  )
}
