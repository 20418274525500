import React, { useCallback, useContext, useState } from 'react'
import { callAsync } from '../../utils/callAsync'
import { PublishApiErrorContext } from '../../App'
import { useMountedState } from '../../hooks/useMountedState'
import isEmpty from 'lodash/isEmpty'
import { Repo, RepositoryManagementService } from '../../api/coreapi'
import { InputActionDialog } from './InputActionDialog'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  onCreated: (repoId: string, repoName: string) => void
}

export const NewRepoDialog = ({ isOpen, setOpen, onCreated }: Props) => {
  const [saveLoading, setLoading] = useMountedState(false)
  const [repoName, setRepoName] = useState<string>('')
  const onApiError = useContext(PublishApiErrorContext)
  const handleClose = useCallback(() => setOpen(false), [setOpen])
  const handleConfirm = useCallback(async () => {
    await callAsync(
      async () => {
        const repo = (await RepositoryManagementService.srcHandlersv2RepoPost({
          requestBody: {
            repo_name: repoName,
          },
        })) as Repo
        onCreated(repo.repo_id, repoName)
      },
      setLoading,
      onApiError,
      handleClose
    )
  }, [setLoading, onApiError, handleClose, repoName, onCreated])
  return (
    <InputActionDialog
      title="Create New Repository"
      onClose={handleClose}
      isOpen={isOpen}
      inputPlaceholder="Repository name"
      input={repoName}
      setInput={setRepoName}
      saveLoading={saveLoading}
      saveLoadingMessage="Creating repository..."
      createActionName="Create"
      createDisabled={isEmpty(repoName)}
      handleCreate={handleConfirm}
    />
  )
}
