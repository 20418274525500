import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useIsMounted } from 'usehooks-ts'

export const useMountedState = <T>(initialState: T): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(initialState)
  const isMounted = useIsMounted()
  const safeSetState = useCallback(
    (setAction: SetStateAction<T>) => {
      if (isMounted()) {
        setState(setAction)
      }
    },
    [isMounted]
  )
  return [state, safeSetState]
}
