import { FullPage } from './FullPage'
import styled from '@emotion/styled'
import { exposeDebugInfo } from '../../env/config'

const Text = styled.span`
  color: ${({ theme }) => theme.colors.red.primary};
  white-space: break-spaces;
  text-align: start;

  :not(:first-child) {
    padding-top: ${({ theme }) => theme.padding.l};
  }
`

type Props = {
  error: Error
}

export const FullErrorPage = ({ error }: Props) => (
  <FullPage>
    <Text>Something went wrong...</Text>
    {exposeDebugInfo() && (
      <>
        <Text>{error.stack}</Text>
      </>
    )}
  </FullPage>
)
