import { Commit, Tag } from '../../api/coreapi'
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined'
import PlaylistRemoveOutlinedIcon from '@mui/icons-material/PlaylistRemoveOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import IdCopyIcon from '@mui/icons-material/Tag'
import EditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import CherryPickOutlinedIcon from '@mui/icons-material/MenuOpenOutlined'
import { SelectItem } from './SelectItem'
import { SearchBox } from './SearchBox'
import isEmpty from 'lodash/isEmpty'
import { NoContentMessage } from './NoContentMessage'
import { Separator } from '../base/Separator'
import { formatTimestamp } from '../../utils/dateUtils'
import { EMPTY_COMMIT_MESSAGE_PLACEHOLDER } from '../../utils/textUtils'
import { Loader } from '../base/Loader'
import config from '../../env/config'
import { FlexColumnStyle } from '../base/Flex'
import { fetchCommitsPageAsync, useCommits } from '../../hooks/api/useCommits'
import { useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import useIsInViewport from 'use-is-in-viewport'
import { callAsync } from '../../utils/callAsync'
import { PublishApiErrorContext } from '../../App'
import { CommitActionContext } from '../commit/CommitActions'
import { useUrlState } from '../../hooks/useUrlState'
import { useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { useCurrentUser } from '../../hooks/api/useCurrentUser'
import RevertToCommitOutlinedIcon from '@mui/icons-material/LowPriorityOutlined';

type Props = {
  repoId: string
  refId: string | undefined
  selectedCommitId?: string
  commitLink: (commit: Commit) => string
  noContentMessage?: string
  getTags?: (commitId: string) => Tag[] | undefined
  query: string | undefined
  setQuery: (query: string | undefined) => void
}

const Container = styled.div`
  ${FlexColumnStyle};
  padding-bottom: 1rem;
`

const BottomOfList = styled.div``

export const CommitSelector = ({
  repoId,
  refId,
  selectedCommitId,
  commitLink,
  noContentMessage,
  getTags,
  query,
  setQuery,
}: Props) => {
  const onApiError = useContext(PublishApiErrorContext)
  const { doAction: doCommitAction } = useContext(CommitActionContext)
  const [isBottomInViewport, bottomRef] = useIsInViewport()
  const { data: firstPage, loading: firstPageLoading } = useCommits(repoId, refId, config.PAGE_SIZE, 0, query)
  const { userData: currentUser } = useCurrentUser()
  const [nextPageLoading, setNextPageLoading] = useState(false)
  const [pages, setPages] = useState<Commit[][]>([])
  const allCommits = (firstPage || []).concat(...pages)
  const allCommitsCount = allCommits.length
  const { workspaceId } = useUrlState()

  const hasCommits = !isEmpty(firstPage)
  useEffect(() => {
    const hasNextPage = allCommitsCount % config.PAGE_SIZE === 0
    if (refId && isBottomInViewport && !nextPageLoading && hasCommits && hasNextPage) {
      callAsync(
        async () => (await fetchCommitsPageAsync(repoId, refId, config.PAGE_SIZE, allCommitsCount, query)).items,
        setNextPageLoading,
        onApiError,
        () => {}
      ).then((commits) => commits && setPages((pages) => [...pages, commits]))
    }
  }, [allCommitsCount, hasCommits, isBottomInViewport, nextPageLoading, onApiError, query, refId, repoId])

  useEffect(() => {
    setPages([])
  }, [query, refId, repoId])

  const copyToClipboard = useCopyToClipboardAction()
  const initialCommitId = 'dv.commit.1'

  return (
    <Container>
      <SearchBox
        hint={'Search for a commit'}
        onChange={(query) => setQuery(query.length >= config.QUERY_MIN_LENGTH ? query : undefined)}
        maxTextLength={config.QUERY_MAX_LENGTH}
      />
      <Separator />
      {firstPageLoading ? (
        <Loader addPadding />
      ) : (
        <>
          {isEmpty(firstPage) ? (
            <NoContentMessage>
              {query ? 'No commits found' : noContentMessage || 'You have no commits yet'}
            </NoContentMessage>
          ) : (
            <>
              {allCommits.map((commit) => (
                <SelectItem
                  key={commit.commit_id}
                  description={`Commit ${commit.commit_id}`}
                  redirectToOnClick={commitLink(commit)}
                  title={commit.commit_message || EMPTY_COMMIT_MESSAGE_PLACEHOLDER}
                  subTitles={[
                    formatTimestamp(commit.created_ts),
                    commit.commit_id,
                    commit.author.full_name || commit.author.id,
                  ]}
                  isSelected={selectedCommitId === commit.commit_id}
                  clickable={true}
                  tags={getTags && getTags(commit.commit_id)?.map((tag) => tag.name)}
                  menuActions={
                    workspaceId || refId
                      ? {
                          'Create branch from here': {
                            icon: <AltRouteOutlinedIcon />,
                            action: () => doCommitAction('create-branch-from-commit', commit),
                          },
                          'Copy commit message': {
                            icon: <ContentCopyIcon />,
                            action: () => copyToClipboard(commit.commit_message || '', 'Copied commit message'),
                          },
                          ...(commit.author.id === currentUser?.username && {
                            'Edit commit message': {
                              icon: <EditOutlineIcon />,
                              action: () => doCommitAction('edit-commit-message', commit),
                            },
                          }),
                          'Copy commit ID': {
                            icon: <IdCopyIcon />,
                            action: () => copyToClipboard(commit.commit_id || '', 'Copied commit ID'),
                          },
                          ...(commit.commit_id !== initialCommitId && {
                            'Cherry-pick into workspace': {
                              icon: <CherryPickOutlinedIcon/>,
                              action: () => doCommitAction('cherry-pick', commit),
                            },
                          }),
                          ...(commit.parents.length > 0 &&
                            workspaceId && {
                              'Undo this commit': {
                                icon: <PlaylistRemoveOutlinedIcon />,
                                action: () => doCommitAction('revert', commit),
                              },
                            }),
                          ...(workspaceId && {
                                'Revert to this commit': {
                                  icon: <RevertToCommitOutlinedIcon />,
                                  action: () => doCommitAction('revert-to-commit', commit),
                                },
                              }),
                        }
                      : undefined
                  }
                />
              ))}
              <BottomOfList ref={bottomRef} />
              {nextPageLoading && <Loader addPadding />}
            </>
          )}
        </>
      )}
    </Container>
  )
}
