import { JournalOrdinalId } from '../../models/Tree'
import { useApi } from '../useApi'
import { getTreePage } from './getTreePage'

export const useTreePages = (
  repoId: string,
  refId: string,
  includeDeleted: boolean,
  pathPrefix: string,
  workspaceJournalOrdinalId: JournalOrdinalId,
  dirsOnly: boolean | undefined,
  useSelectiveSync: boolean | undefined,
  onApiError: (error: any, key: string) => void,
  maxDepth?: number,
  batchSize?: number
) => {
  return useApi(
    [
      'treePages',
      repoId,
      refId,
      includeDeleted,
      pathPrefix,
      workspaceJournalOrdinalId,
      dirsOnly,
      useSelectiveSync,
      maxDepth,
    ].map((v) => v?.toString()),
    async () =>
      await getTreePage(
        repoId,
        refId,
        includeDeleted,
        pathPrefix,
        workspaceJournalOrdinalId,
        dirsOnly,
        useSelectiveSync,
        onApiError,
        maxDepth,
        batchSize
      ),
    true
  )
}
