import { Styleable } from '../../theme'
import config from '../../env/config'

export const HomeImage = ({ className }: Styleable) => {
  let imagePath
  if (config.ENVIRONMENT === 'localhost') {
    imagePath = require('../../assets/images/home-local.png')
  } else if (config.IS_DEV_ENV) {
    imagePath = require('../../assets/images/home-test.png')
  } else {
    imagePath = require('../../assets/images/home.png')
  }
  return <img src={imagePath} alt="Home" className={className} height={34} width={29} />
}
