/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Branch } from '../models/Branch';
import type { Error } from '../models/Error';
import type { NewBranch } from '../models/NewBranch';
import type { NewResourceId } from '../models/NewResourceId';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RepositoryBranchManipulationService {
    /**
     * Create a branch
     * @returns Error An error occurred
     * @returns NewResourceId Resource created
     * @throws ApiError
     */
    public static srcHandlersv2BranchPost({
        repoId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        requestBody: NewBranch,
    }): CancelablePromise<Error | NewResourceId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/branches',
            path: {
                'repo_id': repoId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Branch with the same name already exists`,
                503: `Service unavailable`,
            },
        });
    }
    /**
     * Get a list of all branches in the repo.
     * @returns any Success
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2BranchListAll({
        repoId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
    }): CancelablePromise<{
        object: 'Branch';
        items: Array<Branch>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/branches',
            path: {
                'repo_id': repoId,
            },
        });
    }
    /**
     * Update a branch
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2BranchPatch({
        repoId,
        branchId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Branch ID. Branch _name_ can be used instead of the ID, but storing and using branch ID in API requests is preferred.
         */
        branchId: string,
        requestBody: {
            commit_id?: string;
            branch_name?: string;
            branch_description?: string;
        },
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/repos/{repo_id}/branches/{branch_id}',
            path: {
                'repo_id': repoId,
                'branch_id': branchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Branch with the same name already exists`,
                501: `Renaming branches is not supported for repositories synced with git`,
            },
        });
    }
    /**
     * Get the current branch details
     * @returns Branch Branch details returned
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2BranchGet({
        repoId,
        branchId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Branch ID. Branch _name_ can be used instead of the ID, but storing and using branch ID in API requests is preferred.
         */
        branchId: string,
    }): CancelablePromise<Branch | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/branches/{branch_id}',
            path: {
                'repo_id': repoId,
                'branch_id': branchId,
            },
        });
    }
    /**
     * Delete a branch
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2BranchDelete({
        repoId,
        branchId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Branch ID. Branch _name_ can be used instead of the ID, but storing and using branch ID in API requests is preferred.
         */
        branchId: string,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/repos/{repo_id}/branches/{branch_id}',
            path: {
                'repo_id': repoId,
                'branch_id': branchId,
            },
            errors: {
                400: `The request does not meet the required conditions`,
                503: `Service unavailable`,
            },
        });
    }
}
