import Cache from 'lru-ttl-cache'
import config from '../env/config'

const cache = new Cache<string, boolean>({ max: 1024, ttl: config.TOAST_DEDUP_TIMEOUT })

export const dedup = (key: string, action: () => void, skipDedup: boolean = false) => {
  if (!cache.has(key) || skipDedup) {
    cache.set(key, true)
    action()
  }
}
