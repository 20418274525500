import { useContext } from 'react'
import { SyncStatusContext, SyncStatusType } from './SyncStatusContext'
import { AgentDownStatus, ErrorSyncStatus, SyncingSyncStatus, UpToDateSyncStatus } from './SyncStatus'
import { UpdateWorkspaceSyncStatus } from './UpdateWorkspaceSyncStatus'
import styled from '@emotion/styled'
import { PausedSyncStatus } from './PausedSyncStatus'

const StatusBarContainer = styled.div`
  display: flex;
  margin: 0 ${({ theme }) => theme.padding.l}rem;
  max-width: 16rem;
`

export const SyncStatusBar = () => {
  const { syncStatus, statusMsg, numOfFiles } = useContext(SyncStatusContext)

  return (
    <StatusBarContainer>
      {syncStatus === SyncStatusType.None && null}
      {syncStatus === SyncStatusType.Synced && <UpToDateSyncStatus />}
      {syncStatus === SyncStatusType.Syncing && <SyncingSyncStatus numOfWaitingFiles={numOfFiles} />}
      {syncStatus === SyncStatusType.Error && <ErrorSyncStatus error={statusMsg} />}
      {syncStatus === SyncStatusType.AgentDownError && <AgentDownStatus />}
      {syncStatus === SyncStatusType.NeedsUpdate && <UpdateWorkspaceSyncStatus />}
      {syncStatus === SyncStatusType.Paused && <PausedSyncStatus />}
    </StatusBarContainer>
  )
}
