import { DesktopAppVersion } from './DesktopAppApi'
import { execute } from '@todesktop/client-exec'
import { log } from '../../../utils/log'

export const AgentStartMinVersionSupport = '0.4.1'
export const isAgentStartSupported = () => {
  const desktopAppVersion = DesktopAppVersion()
  return desktopAppVersion && desktopAppVersion >= AgentStartMinVersionSupport && execute !== undefined
}
export const startAgentIfSupported = async () => {
  if (!isAgentStartSupported()) {
    return false
  }
  try {
    await execute([])
    return true
  } catch (e) {
    log.error('Error starting agent using execute', e)
    return false
  }
}
