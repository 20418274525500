import { Branch, Commit, FileHistoryEntry, Tag } from '../../api/coreapi'
import { CopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import styled from '@emotion/styled'
import { FlexColumnStyle, FlexRowStyle } from '../base/Flex'
import isEmpty from 'lodash/isEmpty'
import { changeToActionDescription, fromFileEntryStatus } from '../../models/ChangeType'
import { getIcon } from '../tree/ChangeTypeIcons'
import { StatusIcon, StatusText } from '../base/StatusIcon'
import { capitalize, RIGHT_ARROW } from '../../utils/textUtils'
import { getMoveLabel } from '../../utils/pathUtils'
import React from 'react'
import { CommitCard } from '../commitTree/CommitCard'
import { css } from '@emotion/react'

type HistoryEntryProps = {
  repoId: string
  filePath: string
  historyEntry: FileHistoryEntry
  copyToClipboard: CopyToClipboardAction
  branch?: Branch
  tags?: Tag[]
  selected: boolean
  onCommitSelected: (commitId: Commit) => void
}

const HistoryEntryCard = styled.div<{ selected: boolean }>`
  min-width: 28rem;
  background-color: ${({ theme }) => theme.colors.white.primary};
  border-radius: 0.5rem;
  ${FlexColumnStyle};
  gap: 0.5rem;
  cursor: pointer;
  padding: ${({ theme }) => theme.padding.l}rem;
  ${({ theme, selected }) =>
    selected &&
    css`
      outline: 2px solid ${theme.colors.blue.primary};
    `}
`

const StyledCommitCard = styled(CommitCard)``

const StatusRow = styled.div`
  ${FlexRowStyle};
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.blue.primary};
`

export const FileHistoryEntryCard = ({
  repoId,
  filePath,
  historyEntry,
  branch,
  tags,
  copyToClipboard,
  selected,
  onCommitSelected,
}: HistoryEntryProps) => {
  const { entry, commit } = historyEntry
  const fileMoved = !isEmpty(entry.prev_path) && entry.prev_path !== entry.path
  const changeType = fileMoved ? 'Moved' : fromFileEntryStatus(entry.status) || 'Intact'
  const icon = getIcon(changeType)
  return (
    <HistoryEntryCard selected={selected} onClick={() => onCommitSelected(commit)}>
      <StatusRow>
        <StatusIcon changeType={changeType}>{icon}</StatusIcon>{' '}
        <StatusText changeType={changeType}>{capitalize(changeToActionDescription(changeType))}</StatusText>
        {fileMoved && (
          <>
            <div />
            <div>{entry.prev_path}</div>
            <div title={getMoveLabel(entry.prev_path!, entry.path)}>{RIGHT_ARROW}</div>
            <div>{entry.path}</div>
          </>
        )}
        {!fileMoved && filePath !== entry.path && (
          <>
            <div />
            <div>{entry.path}</div>
          </>
        )}
      </StatusRow>
      <StyledCommitCard
        repoId={repoId!}
        commit={commit}
        tags={tags}
        branch={branch}
        copyToClipboard={copyToClipboard}
        linkeable
        highlight={false}
        showUserImage
        showUserName={false}
      />
    </HistoryEntryCard>
  )
}
