import { useUrlState } from '../../../hooks/useUrlState'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import { callAsync } from '../../../utils/callAsync'
import { MergeId, RepositoryWorkspaceManipulationService } from '../../../api/coreapi'
import isEmpty from 'lodash/isEmpty'
import { errorToast, infoToast } from '../../../utils/toast'
import { routeToMerge } from '../../../Routes'
import { WarningSyncStatus } from './SyncStatus'
import { useAnalytics } from '../../../hooks/api/useAnalytics'

export const UpdateWorkspaceSyncStatus = () => {
  const { repoId, workspaceId } = useUrlState()
  const { loading, forwardWorkspace } = useForwardWorkspace(repoId!, workspaceId!)
  return (
    <WarningSyncStatus
      icon={<CloudDownloadIcon />}
      primaryText={'Pending workspace updates'}
      secondaryText={loading ? 'Updating workspace...' : 'Click to update'}
      onClick={loading ? undefined : forwardWorkspace}
    />
  )
}

const useForwardWorkspace = (repoId: string, workspaceId: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const postAnalytics = useAnalytics()
  const forwardWorkspace = useCallback(() => {
    return callAsync(
      async () => {
        postAnalytics('CallForwardWorkspace', {})
        const result = await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceForwardWorkspace({
          repoId,
          workspaceId,
        })
        const mergeId = (result as MergeId)?.merge_id
        const hasConflicts = !isEmpty(mergeId)
        if (hasConflicts) {
          infoToast('Conflicts discovered in workspace update', true)
          navigate(routeToMerge(repoId, mergeId))
        } else {
          infoToast('Workspace updated successfully', true)
        }
      },
      setLoading,
      () => errorToast('Error updating workspace. Please try again.')
    )
  }, [repoId, workspaceId, navigate, postAnalytics])

  return { loading, forwardWorkspace }
}
