import { useCallback } from 'react'
import { callAsync, OnError, SetLoading } from '../utils/callAsync'
import { AccessMode, CollaboratorsService } from '../api/coreapi'
import { isInviteAlreadySentError } from '../utils/errorClassify'
import { errorToast } from '../utils/toast'

export const useCollaboratorsApis = (
  repoId: string,
  setLoading: SetLoading,
  onApiError: OnError,
  onFinally: () => void
) => ({
  revokeInvite: useCallback(
    async (inviteId: number) => {
      await callAsync(
        () =>
          CollaboratorsService.srcHandlersv2InvitesRevoke({
            repoId,
            inviteId,
          }),
        setLoading,
        onApiError,
        onFinally
      )
    },
    [onApiError, onFinally, repoId, setLoading]
  ),
  resendInvite: useCallback(
    async (inviteId: number) => {
      await callAsync(
        () =>
          CollaboratorsService.srcHandlersv2InvitesResend({
            repoId,
            inviteId,
          }),
        setLoading,
        onApiError,
        onFinally
      )
    },
    [onApiError, onFinally, repoId, setLoading]
  ),
  sendInvite: useCallback(
    async (email: string, mode: AccessMode) => {
      await callAsync(
        async () => {
          try {
            await CollaboratorsService.srcHandlersv2InvitesCreateNew({
              repoId,
              requestBody: { mode, collaborator_handle: email },
            })
          } catch (e) {
            if (isInviteAlreadySentError(e)) {
              errorToast('An invitation for this repository has already been extended to the email address provided')
            } else {
              throw e
            }
          }
        },
        setLoading,
        onApiError,
        onFinally
      )
    },
    [onApiError, onFinally, repoId, setLoading]
  ),
  revokeAccess: useCallback(
    async (userId: string) => {
      await callAsync(
        () => CollaboratorsService.srcHandlersCollaborationRevokeAccess({ repoId, userId }),
        setLoading,
        onApiError,
        onFinally
      )
    },
    [onApiError, onFinally, repoId, setLoading]
  ),
  updateMode: useCallback(
    async (userId: string, mode: AccessMode) => {
      await callAsync(
        () =>
          CollaboratorsService.srcHandlersCollaborationUpdateMode({
            repoId,
            userId,
            requestBody: { mode },
          }),
        setLoading,
        onApiError,
        onFinally
      )
    },
    [onApiError, onFinally, repoId, setLoading]
  ),
})
