import { ReactElement } from 'react'
import styled from '@emotion/styled'
import { Styleable } from '../../theme'
import { Tooltip } from '@mui/material'
import { css } from '@emotion/react'
import { changeColorOpacity } from '../../utils/colorUtils'
import { FlexRowStyle } from './Flex'

type SwitchButtonProps<T> = {
  icon: ReactElement
  key: T
  title: string
}

type SwitchButtonsProps<T> = Styleable & {
  buttons: SwitchButtonProps<T>[]
  selected: T
  setSelected: (selected: T) => void
}

const Container = styled.div`
  ${FlexRowStyle};
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
`

const BORDER_RADIUS = '20%'

const Option = styled.div<{ isFirst: boolean; isLast: boolean; selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.blue.primary : changeColorOpacity(theme.colors.blue.primary, 0.4)};
  color: ${({ theme }) => theme.colors.blue.contrastText};
  padding: ${({ theme }) => theme.padding.s}rem ${({ theme }) => theme.padding.m}rem;
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top-left-radius: ${BORDER_RADIUS};
      border-bottom-left-radius: ${BORDER_RADIUS};
    `}
  ${({ isLast }) =>
    isLast &&
    css`
      border-top-right-radius: ${BORDER_RADIUS};
      border-bottom-right-radius: ${BORDER_RADIUS};
    `}
  ${({ isFirst, theme }) =>
    !isFirst &&
    css`
      border-left: 1px solid ${theme.colors.white.primary};
    `}
`

export const SwitchButtons = <T extends string>({
  className,
  buttons,
  selected,
  setSelected,
}: SwitchButtonsProps<T>) => (
  <Container className={className}>
    {buttons.map((button, index) => (
      <Tooltip key={button.key} title={button.title} arrow>
        <Option
          selected={selected === button.key}
          onClick={() => setSelected(button.key)}
          isFirst={index === 0}
          isLast={index === buttons.length - 1}
        >
          {button.icon}
        </Option>
      </Tooltip>
    ))}
  </Container>
)
