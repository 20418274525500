/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AccessMode {
    READ = 'READ',
    WRITE = 'WRITE',
    ADMIN = 'ADMIN',
    OWNER = 'OWNER',
}
