export type SetLoading = (loading: boolean) => void
export type OnError = (error: Error) => void
type OnFinally = () => void
export const callAsync = async <T>(
  asyncAction: () => Promise<T>,
  setLoading: SetLoading,
  onError: OnError,
  onFinally?: OnFinally
): Promise<T | undefined> => {
  try {
    setLoading(true)
    return await asyncAction()
  } catch (error) {
    onError(error as Error)
  } finally {
    onFinally && onFinally()
    setLoading(false)
  }
}
