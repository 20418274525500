import { useCallback } from 'react'
import { RepositoryWorkspaceManipulationService } from '../../../../api/coreapi'
import { Refresh } from '../../../../hooks/useApi'
import { TriggerRefreshAgent } from '../../../../desktop/components/utils/refreshAgent'

export const usePostResetAsync = (
  repoId: string | undefined,
  workspaceId: string | undefined,
  all: boolean,
  filePaths: string[] | undefined,
  refresh?: Refresh
) => {
  return useCallback(
    async (deleteAdded: boolean) => {
      await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceReset({
        repoId: repoId!,
        workspaceId: workspaceId!,
        requestBody: {
          all: all ? true : undefined,
          paths: all ? undefined : filePaths,
          delete_added: deleteAdded,
        },
      })
      TriggerRefreshAgent(workspaceId, repoId)
      refresh?.()
    },
    [all, filePaths, refresh, repoId, workspaceId]
  )
}
