/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalyticsEvents } from '../models/AnalyticsEvents';
import type { AnalyticsIdentify } from '../models/AnalyticsIdentify';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AnalyticsService {
    /**
     * Ingest analytics events
     * @returns void
     * @throws ApiError
     */
    public static srcHandlersAnalyticsIngest({
        requestBody,
    }: {
        requestBody: AnalyticsEvents,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/analytics/ingest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update user traits
     * @returns void
     * @throws ApiError
     */
    public static srcHandlersAnalyticsIdentify({
        requestBody,
    }: {
        requestBody: AnalyticsIdentify,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/analytics/identify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ingest analytics events without user context
     * @returns void
     * @throws ApiError
     */
    public static srcHandlersAnalyticsIngestAnon({
        requestBody,
        anonymousId = '',
    }: {
        requestBody: AnalyticsEvents,
        /**
         * An anonymous user id
         */
        anonymousId?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/analytics/anonymous/ingest',
            query: {
                'anonymous_id': anonymousId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
