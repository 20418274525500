import { CommitFileViewer } from '../file/CommitFileViewer'
import { useUrlState } from '../../hooks/useUrlState'
import { useSearchParamsValues } from '../../hooks/useSearchParamsValues'
import { useContext } from 'react'
import { FileViewerPathContext } from '../file/useFileAndBaseFile'

type Props = {
  useFilePathFromContext?: boolean
}

export const CommitFileViewerRoute = ({ useFilePathFromContext }: Props) => {
  const { repoId, commitId, compareCommitId, '*': filePath } = useUrlState()
  const { commitId: searchCommitId } = useSearchParamsValues(['commitId'])
  const { path } = useContext(FileViewerPathContext)
  if (useFilePathFromContext && !path) {
    return null
  }
  return (
    <CommitFileViewer
      repoId={repoId!}
      commitId={(commitId || searchCommitId)!}
      compareCommitId={compareCommitId}
      filePath={(useFilePathFromContext ? path : filePath)!}
      showFileHistory={Boolean(commitId)}
    />
  )
}
