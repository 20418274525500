import { NewRepoDialog } from './NewRepoDialog'
import { useCallback, useState } from 'react'
import { PrimaryButton } from '../base/PrimaryButton'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { LocationContext } from '../../models/Analytics'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import { FlexRow } from '../base/Flex'

type Props = {
  locationContext: LocationContext
  onCreated: (repoId: string, repoName: string) => void
  onOpenChanged?: (open: boolean) => void
}

export const useNewRepoDialog = ({ onCreated, onOpenChanged, locationContext }: Props) => {
  const [newRepoDialogOpen, setNewRepoDialogOpen] = useState(false)
  const postAnalytics = useAnalytics()

  const setOpen = useCallback(
    (open: boolean) => {
      if (open) {
        postAnalytics('NewRepoDialogOpened', {}, locationContext)
      }
      setNewRepoDialogOpen(open)
      onOpenChanged?.(open)
    },
    [locationContext, onOpenChanged, postAnalytics]
  )

  return {
    Dialog: () => <NewRepoDialog isOpen={newRepoDialogOpen} setOpen={setOpen} onCreated={onCreated} />,
    setOpen: setNewRepoDialogOpen,
    Button: () => (
      <PrimaryButton
        disabled={false}
        title="Create New Repository"
        onClick={() => {
          setOpen(true)
        }}
      >
        <FlexRow gap={0.5} centered={true} centerContent={true}>
          <AddBoxOutlinedIcon />
          Create New Repository
        </FlexRow>
      </PrimaryButton>
    ),
  }
}
