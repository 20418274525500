import { useMemo } from 'react'
import { useLocalWorkspaces } from './useLocalWorkspaces'

export const useIsLocallyCloned = (workspaceId?: string) => {
  const { data: localWorkspacesRes } = useLocalWorkspaces()

  return useMemo(() => {
    return { isLocallyCloned: localWorkspacesRes?.data?.some((w) => w.WorkspaceID === workspaceId) ?? false }
  }, [workspaceId, localWorkspacesRes])
}
