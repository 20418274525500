import { ActionDialog } from '../../../dialogs/ActionDialog'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  postDeleteAsync: () => Promise<void>
  filePath: string
}

export const DeleteDialog = ({ isOpen, setOpen, filePath, postDeleteAsync }: Props) => {
  return (
    <ActionDialog
      title="Delete"
      isOpen={isOpen}
      onConfirmAsync={postDeleteAsync}
      message={`Are you sure you want to delete the file '${filePath}'?`}
      setOpen={setOpen}
      confirmButtonLabel="Delete"
      loadingMessage="Deleting file..."
      isDanger
    />
  )
}
