import { Conflict, Merge } from '../../api/coreapi'
import { useBlobByPath, useConflictBlob } from '../../hooks/blob/useBlob'
import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

export const useMergeFiles = (repoId: string, merge: Merge | undefined, conflict: Conflict | undefined) => {
  const [canMerge, setCanMerge] = useState(false)
  const ancestorFilePath = conflict?.base.prev_path || conflict?.other.prev_path
  const { data: ancestorFile, loading: ancestorLoading } = useBlobByPath(
    repoId,
    canMerge ? merge?.ancestor_commit : undefined,
    canMerge ? ancestorFilePath : undefined
  )
  const { data: baseFile, loading: baseLoading } = useConflictBlob(repoId, conflict?.base)
  const { data: otherFile, loading: otherLoading } = useConflictBlob(repoId, conflict?.other)
  const { data: resultFile, loading: resultLoading } = useConflictBlob(repoId, conflict?.result)
  useEffect(() => {
    if (baseFile && otherFile) {
      setCanMerge(
        (baseFile.isTextFile || !baseFile.existsOnServer) &&
          (otherFile.isTextFile || !otherFile.existsOnServer) &&
          (resultFile?.isTextFile || !resultFile?.existsOnServer)
      )
    }
  }, [baseFile, otherFile, resultFile])

  const nullableAncestorFile = conflict && isEmpty(ancestorFilePath) ? null : ancestorFile

  return {
    ancestorFile: nullableAncestorFile,
    ancestorFilePath,
    baseFile,
    otherFile,
    resultFile,
    canMerge,
    loading: ancestorLoading || baseLoading || otherLoading || resultLoading,
  }
}
