export const changeColorOpacity = (color: string, opacity: number) => {
  if (color.length > 7) {
    color = color.substring(0, color.length - 2)
  }
  const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255)
  let opacityHex = _opacity.toString(16).toUpperCase()
  if (opacityHex.length === 1) {
    opacityHex = '0' + opacityHex
  }
  return color + opacityHex
}
