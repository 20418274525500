import styled from '@emotion/styled'

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: ${({ theme }) => theme.colors.stroke};
  z-index: 1;
`

export const VerticalSeparator = styled.div`
  width: 1px;
  min-width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.stroke};
`
