import Avatar from '@mui/material/Avatar'
import { toInitials } from '../../utils/textUtils'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { Tooltip } from '@mui/material'
import { useMemo } from 'react'

type Props = {
  picture?: string
  name?: string
  email: string
  hideTooltip?: boolean
}

const InitialsAvatar = styled(Avatar)<{ color?: string }>`
  color: ${({ theme }) => theme.colors.blue.contrastText};
  background-color: ${({ color, theme }) => color || theme.colors.blue.primary};
`

const simpleHash = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  hash = Math.abs(hash)
  return hash
}

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min)
}

type HSLRange = { min: number; max: number }
const hRange: HSLRange = { min: 0, max: 360 }
const sRange: HSLRange = { min: 50, max: 75 }
const lRange: HSLRange = { min: 25, max: 60 }

const generateHSL = (name: string): string => {
  const hash = simpleHash(name)
  const h = normalizeHash(hash, hRange.min, hRange.max)
  const s = normalizeHash(hash, sRange.min, sRange.max)
  const l = normalizeHash(hash, lRange.min, lRange.max)
  return `hsl(${h}, ${s}%, ${l}%)`
}

const UserAvatarInternal = ({ picture, displayName }: { picture: string | undefined; displayName: string }) => {
  const avatarColor = useMemo(() => generateHSL(displayName), [displayName])
  return (
    <>
      {isEmpty(picture) && !isEmpty(displayName) ? (
        <InitialsAvatar color={avatarColor}>{toInitials(displayName)}</InitialsAvatar>
      ) : (
        <Avatar src={picture} alt={displayName} />
      )}
    </>
  )
}

export const UserAvatar = ({ picture, name, email, hideTooltip }: Props) => {
  const displayName = name || email
  return hideTooltip ? (
    <UserAvatarInternal picture={picture} displayName={displayName} />
  ) : (
    <Tooltip title={displayName || 'N/A'} arrow>
      <div>
        <UserAvatarInternal picture={picture} displayName={displayName} />
      </div>
    </Tooltip>
  )
}
