import { ActionDialog } from '../dialogs/ActionDialog'
import { BranchEx } from '../../models/BranchEx'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import React, { useCallback, useState } from 'react'
import { RepositoryBranchManipulationService } from '../../api/coreapi'
import { errorToast, infoToast } from '../../utils/toast'
import { FilePath } from '../base/TextStyle'
import { DialogInput } from '../dialogs/DialogStyle'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { isNotSupportedWithGitSyncError } from '../../utils/errorClassify'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  branch: BranchEx | undefined
}

const RenameBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const useRenameAsync = () => {
  const postAnalytics = useAnalytics()
  return useCallback(
    async (repoId: string, branchId: string, newName: string) => {
      try {
        await RepositoryBranchManipulationService.srcHandlersv2BranchPatch({
          repoId,
          branchId,
          requestBody: { branch_name: newName },
        })
      } catch (e) {
        if (isNotSupportedWithGitSyncError(e)) {
          errorToast('Renaming branches is not supported for repositories synced with git')
          return
        }
        throw e
      }
      infoToast('Branch renamed successfully', true)
      postAnalytics('BranchRenamed', {
        repo_id: repoId,
        branch_id: branchId,
      })
    },
    [postAnalytics]
  )
}

export const RenameDialog = ({ isOpen, setOpen, branch }: Props) => {
  const renameAsync = useRenameAsync()
  const [newName, setNewName] = useState<string>()

  return (
    <ActionDialog
      title="Rename"
      isOpen={isOpen}
      onConfirmAsync={async () => {
        if (isEmpty(branch) || isEmpty(newName)) {
          return
        }
        await renameAsync(branch.repoId, branch.branch_id, newName!)
      }}
      message={
        <RenameBody>
          <div>
            Enter new name for <FilePath>{branch?.branch_name}</FilePath>
          </div>
          <DialogInput
            autoFocus
            type="text"
            placeholder="Name"
            onChange={(event) => setNewName(event.target.value || '')}
          />
        </RenameBody>
      }
      setOpen={setOpen}
      confirmButtonLabel="Rename"
      loadingMessage="Renaming branch..."
      disabled={isEmpty(newName)}
    />
  )
}
