import React, { useCallback, useContext, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import { PrimaryButton, SecondaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { Loader } from '../base/Loader'
import { callAsync } from '../../utils/callAsync'
import { TextBold, TextTitle } from '../base/TextStyle'
import { PublishApiErrorContext } from '../../App'
import { useMountedState } from '../../hooks/useMountedState'
import isEmpty from 'lodash/isEmpty'
import { NewResourceId, RepositoryWorkspaceManipulationService } from '../../api/coreapi'
import { useRepo } from '../../hooks/api/useRepo'
import { StyledDialogActions, StyledDialogContent } from './DialogStyle'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { clientId } from '../../api/configure'
import { useKeyPress } from '../../hooks/useKeyPress'
import * as KeyCode from 'keycode-js'

const Message = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
`

const Title = styled(Message)`
  ${TextBold};
`

const StyledInput = styled.input`
  background-color: inherit;
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.m}rem;
  outline: 0 none;
  width: 100%;
  border-radius: 1rem;
`

type Props = {
  repoId: string
  isOpen: boolean
  setOpen: (open: boolean) => void
  onCreated: (workspaceId: string) => void
}

export const NewWorkspaceDialog = ({ repoId, isOpen, setOpen, onCreated }: Props) => {
  const [saveLoading, setLoading] = useMountedState(false)
  const onApiError = useContext(PublishApiErrorContext)
  const [workspaceName, setWorkspaceName] = useState<string>('')
  const handleClose = useCallback(() => {
    setLoading(false)
    setWorkspaceName('')
    setOpen(false)
  }, [setOpen, setLoading, setWorkspaceName])
  const { repo, repoLoading } = useRepo(repoId)
  const postAnalytics = useAnalytics()
  const handleConfirm = useCallback(async () => {
    await callAsync(
      async () => {
        const workspaceId = (await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceCreateWorkspace({
          repoId,
          xDvClientId: clientId(),
          requestBody: {
            name: workspaceName,
            branch_id: repo?.default_branch_id,
          },
        })) as NewResourceId
        onCreated(workspaceId.id)
      },
      setLoading,
      onApiError,
      handleClose
    )
  }, [setLoading, onApiError, handleClose, repoId, workspaceName, repo?.default_branch_id, onCreated])
  const loading = saveLoading || repoLoading

  useEffect(() => {
    if (isOpen) {
      postAnalytics('NewWorkspaceDialogOpened', { repo_id: repoId })
    }
  }, [isOpen, postAnalytics, repoId])

  useEffect(() => {
    if (repo && isEmpty(repo.default_branch_id)) {
      setOpen(false)
    }
  }, [repo, setOpen])

  const isPrimaryActionDisabled =
    repoLoading || isEmpty(workspaceName) || isEmpty(repo?.default_branch_id) || saveLoading
  useKeyPress(KeyCode.CODE_ENTER, handleConfirm, isOpen && !isPrimaryActionDisabled)
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <StyledDialogContent centerText={loading}>
        <Title>Create New Workspace</Title>
        {loading ? (
          <>
            <Loader />
            <Message>Creating workspace...</Message>
          </>
        ) : (
          <StyledInput
            autoFocus
            type="text"
            placeholder="Workspace name"
            onChange={(event) => setWorkspaceName(event.target.value || '')}
          />
        )}
      </StyledDialogContent>
      {!saveLoading && (
        <StyledDialogActions>
          <PrimaryButton
            disabled={repoLoading || isEmpty(workspaceName) || isEmpty(repo?.default_branch_id)}
            onClick={handleConfirm}
          >
            Create
          </PrimaryButton>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        </StyledDialogActions>
      )}
    </Dialog>
  )
}
