import { useEffect, useRef } from 'react'
import { ChangeType } from '../../models/ChangeType'
import { log } from '../../utils/log'

export const HIDE_INTACT_CLASSNAME = 'hide-intact'

const setClassList = (treeNodeParent: HTMLDivElement, hide: boolean) => {
  if (hide) {
    if (treeNodeParent.classList.contains(HIDE_INTACT_CLASSNAME)) {
      return
    }
    treeNodeParent.classList.add(HIDE_INTACT_CLASSNAME)
  } else {
    if (!treeNodeParent.classList.contains(HIDE_INTACT_CLASSNAME)) {
      return
    }
    treeNodeParent.classList.remove(HIDE_INTACT_CLASSNAME)
  }
}

const observerClassChanges = (element: HTMLDivElement, callback: (el: HTMLDivElement) => void) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        callback(mutation.target as HTMLDivElement)
      }
    })
  })
  observer.observe(element, { attributes: true })
  return () => observer.disconnect()
}

export const useHideIntact = (changeType: ChangeType, changedOnly: boolean, path: string) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current && changeType === 'Intact') {
      const parent = ref.current?.closest('.ant-tree-treenode') as HTMLDivElement | null
      if (!parent) {
        if (path !== '') {
          log.warn('No parent treenode class found for element', ref)
        }
        return
      }
      if (parent) {
        setClassList(parent, changedOnly)
        return observerClassChanges(parent, (element) => setClassList(element, changedOnly))
      }
    }
  }, [changeType, changedOnly, path])
  return ref
}
