import styled from '@emotion/styled'
import { ToastContainer } from 'material-react-toastify'
import { HEADER_HEIGHT_REM } from '../theme'

export default styled(ToastContainer)`
  margin-top: ${HEADER_HEIGHT_REM}rem;

  .Toastify__toast {
    color: ${({ theme }) => theme.colors.blue.contrastText};
    font-family: unset;
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.red.primary};
    color: ${({ theme }) => theme.colors.red.contrastText};
  }

  .Toastify__toast--info {
    background: ${({ theme }) => theme.colors.blue.primary};
  }
`
