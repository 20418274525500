import { format as formatTimeAgo } from 'timeago.js'
import { LOCALES } from './locales'

const dayFormatOptions: Intl.DateTimeFormatOptions = {
  localeMatcher: 'best fit',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  ...dayFormatOptions,
  hour: 'numeric',
  minute: 'numeric',
}

const dateExactFormatOptions: Intl.DateTimeFormatOptions = {
  ...dateFormatOptions,
  second: 'numeric',
}

export const formatTimestamp = (epochSeconds: number | undefined, asDay?: boolean) =>
  epochSeconds ? formatDate(new Date(epochSeconds * 1000), asDay) : ''

export const formatDate = (date: Date, asDay?: boolean, includeSeconds?: boolean) =>
  date
    .toLocaleDateString(LOCALES, asDay ? dayFormatOptions : includeSeconds ? dateExactFormatOptions : dateFormatOptions)
    .replace(',', '')

export const timeAgo = (epochSeconds: number) => formatTimeAgo(epochSeconds * 1000)

export const epochSeconds = () => Math.floor(Date.now() / 1000)
