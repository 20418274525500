import { useItemListApi } from '../useApi'
import { RepositoryTagManipulationService, Tag } from '../../api/coreapi'
import { useMemo } from 'react'
import { notNil } from '../../utils/objectUtil'
import groupBy from 'lodash/groupBy'

export const useTagsByCommitId = (repoId: string) => {
  const { data: tags, loading } = useItemListApi<Tag>(['repos', repoId, 'tags'], () =>
    RepositoryTagManipulationService.srcHandlersv2TagListAll({ repoId })
  )
  const tagsByCommitId: Record<string, Tag[]> = useMemo(
    () => (notNil(tags) ? groupBy(tags, (tag) => tag.commit_id) : {}),
    [tags]
  )
  return { tagsByCommitId, loading }
}
