import { useCallback, useState } from 'react'
import { ActionDialog } from '../dialogs/ActionDialog'
import styled from '@emotion/styled'
import { SecondaryButton } from '../base/PrimaryButton'
import { TextSmall } from '../base/TextStyle'
import { RepositoryMergeManipulationService } from '../../api/coreapi'

type Props = {
  repoId: string
  mergeId: string
  onMergeClosed: () => void
}

const StyledButton = styled(SecondaryButton)`
  ${TextSmall};
  margin: 0 1rem 1rem 1rem;
  height: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
`

const Dialog = ({
  isOpen,
  setOpen,
  closeAsync,
}: {
  isOpen: boolean
  setOpen: (open: boolean) => void
  closeAsync: () => Promise<void>
}) => (
  <ActionDialog
    title="Abort Merge"
    isOpen={isOpen}
    onConfirmAsync={closeAsync}
    message="You are about to abort the merge, do you want to proceed?"
    setOpen={setOpen}
    confirmButtonLabel="Abort Merge"
    loadingMessage="Aborting the merge..."
    isDanger
  />
)

export const MergeCloseButton = ({ repoId, mergeId, onMergeClosed }: Props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const closeAsync = useCallback(async () => {
    await RepositoryMergeManipulationService.srcHandlersv2MergeCloseMerge({
      repoId,
      mergeId,
    })
    onMergeClosed()
  }, [mergeId, onMergeClosed, repoId])
  return (
    <>
      <Dialog isOpen={dialogIsOpen} setOpen={setDialogIsOpen} closeAsync={closeAsync} />
      <StyledButton title="Abort Merge" onClick={() => setDialogIsOpen(true)}>
        Abort Merge
      </StyledButton>
    </>
  )
}
