import { useApi } from '../useApi'
import { Branch, RepositoryBranchManipulationService } from '../../api/coreapi'

export const useBranch = (repoId?: string, branchId?: string) => {
  const { data, loading, refresh } = useApi<Branch>(
    ['repos', repoId, 'branches', branchId],
    repoId && branchId ? () => RepositoryBranchManipulationService.srcHandlersv2BranchGet({ repoId, branchId }) : null,
    true
  )
  return { branch: data, branchLoading: loading, refresh }
}
