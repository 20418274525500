import { Error as CoreError, FileEntry, WorkspaceStatus as CoreWorkspaceStatus } from '../../../api/coreapi'
import {
  DefaultService,
  Error as AgentError,
  FileEntry as AgentFileEntry,
  WorkspaceStatus as AgentWorkspaceStatus,
} from '../../../api/agentapi'
import { isNetworkError } from '../../../utils/errorClassify'
import { log } from '../../../utils/log'

function agentFileEntryToCoreFileEntry(item: AgentFileEntry) {
  const t: FileEntry = {
    path: item.path,
    prev_path: item.prev_path,
    hash: item.hash,
    prev_hash: item.prev_hash,
    status: item.status,
    mode: item.mode,
    mtime: item.mtime,
  }
  return t
}
export const isAgentError = (data: any): data is AgentError => (data as AgentError).status !== undefined
export const isAgentWorkspaceStatus = (data: AgentError | AgentWorkspaceStatus): data is AgentWorkspaceStatus =>
  (data as AgentWorkspaceStatus).changed_items_count !== undefined

export const getStatusFromAgent = async (
  repoId: string,
  refId: string,
  offset: number,
  limit: number,
  getStatusFromServer: () => Promise<CoreWorkspaceStatus | CoreError>
) => {
  try {
    const res = await DefaultService.getWorkspaceStatus({ repoId, workspaceId: refId, limit, skip: offset })
    if (isAgentWorkspaceStatus(res)) {
      const workspaceStatus = res
      const newItems = workspaceStatus.items?.new.map(agentFileEntryToCoreFileEntry)
      const modifiedItems = workspaceStatus.items?.modified.map(agentFileEntryToCoreFileEntry)
      const deletedItems = workspaceStatus.items?.deleted.map(agentFileEntryToCoreFileEntry)
      const converted: CoreWorkspaceStatus = {
        changed_items_count: workspaceStatus.changed_items_count,
        changed_files_count: workspaceStatus.changed_files_count,
        conflicts: workspaceStatus.conflicts,
        incomplete_result: workspaceStatus.incomplete_result,
      }
      if (newItems || modifiedItems || deletedItems) {
        converted.items = {
          new: newItems || [],
          modified: modifiedItems || [],
          deleted: deletedItems || [],
        }
      }
      return converted
    }
    // We don't expect to get other value than the agent status or error, therefore we throw the error and fetch the status from the server
    throw res
  } catch (e) {
    if (!isAgentError(e) && !isNetworkError(e)) {
      log.error('Unexpected error return from get status from agent', e)
    }
    return getStatusFromServer()
  }
}
