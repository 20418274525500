import React, { useState } from 'react'
import { useUrlState } from '../../../hooks/useUrlState'
import { ResumeWorkspaceDialog } from '../../../desktop/components/ResumeWorkspaceDialog'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import { WarningSyncStatus } from './SyncStatus'

export const PausedSyncStatus = () => {
  const [resumeDialogOpen, setResumeDialogOpen] = useState(false)
  const { repoId, workspaceId } = useUrlState()
  return (
    <>
      {resumeDialogOpen && (
        <ResumeWorkspaceDialog
          workspaceId={workspaceId!}
          repoId={repoId!}
          isOpen={resumeDialogOpen}
          setIsOpen={setResumeDialogOpen}
        />
      )}
      <WarningSyncStatus
        icon={<PauseCircleIcon />}
        primaryText={'Sync paused'}
        secondaryText={'click here to resume'}
        onClick={() => setResumeDialogOpen(true)}
      />
    </>
  )
}
