import { useLocation } from 'react-router'

export const useSearchParamsValues = (params: string[]): Record<string, string | undefined> => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  return params.reduce(
    (acc, param) => {
      const value = searchParams.get(param)
      if (value) {
        acc[param] = value
      }
      return acc
    },
    {} as Record<string, string | undefined>
  )
}
