import React, { useState, useEffect, useCallback } from 'react'
import ReactFlow, { useNodesState, useEdgesState, Node, Edge, ReactFlowInstance, Controls } from 'reactflow'
import { useUrlState } from '../../hooks/useUrlState'
import { CommitNode } from './CommitNode'
import { Commit, RepositoryCommitManipulationService } from '../../api/coreapi'
import { useAllCommits } from './useAllCommits'
import 'reactflow/dist/style.css'
import { useBranchesById } from '../../hooks/api/useBranches'
import { BranchEx } from '../../models/BranchEx'
import CustomEdge from './CustomEdge'
import CommitDrawer from './CommitDrawer'
import { uniqBy } from 'lodash'
import { CircularProgress } from '@mui/material'
import CenteredBox from './CenteredBox'

const branchYInitialOffset = 30
const branchYIncrementalOffset = 70
const branchXInitialOffset = 30
const branchXIncrementalOffset = 80
const DEFAULT_BRANCH_ID = 'dv.branch.1'

// It's important that the nodeTypes are memoized or defined outside the component for performance reasons.
// useMemo was sometimes still caused re-rendering, moved outside the component.
const nodeTypes = { commitNode: CommitNode }
const edgeTypes = { customEdge: CustomEdge }

const generateFlowNodesAndEdges = (
  commits: Commit[],
  branches: { [p: string]: BranchEx },
  onNodeClick: (commit: Commit) => void,
  previousBranchPositions: Record<string, number> = {}
): { nodes: Node[]; edges: Edge[] } => {
  const nodes: Node[] = []

  const branchPositions: Record<string, number> = { ...previousBranchPositions }
  const branchLatestCommits: Record<string, Commit> = {}

  const sortedCommits = commits.sort((a, b) => {
    const commitIdA = extractCommitNumber(a.commit_id)
    const commitIdB = extractCommitNumber(b.commit_id)
    return commitIdA - commitIdB
  })
  const commitIdList = sortedCommits.map((commit) => commit.commit_id)

  let currentBranchYOffset = branchYInitialOffset

  const assignYAxisOffset = () => {
    const branchNewestCommits: Record<string, string> = {}
    const branchOldestCommits: Record<string, string> = {}
    const usedOffsets: { [offset: number]: { branchId: string; oldestCommit: string; newestCommit: string } } = {}
    const commitToBranchMap: Record<string, string> = {}

    sortedCommits.forEach((commit) => {
      /*
       * The following forEach go through the list of commits and find the oldest and newest commit for each branch.
       * We later do a more strict calculation, where we assign the oldest and newest nodes to be the commit
       * that the branch was created from and the commit it was merged to, respectively, to avoid a situation where we
       * render 2 branches on the same y-axis position when all the commits from one branch were committed before the other
       * but were only merged later, which could look unclear on the same offset.
       */
      if (!branchNewestCommits[commit.branch_id]) {
        branchNewestCommits[commit.branch_id] = commit.commit_id
        branchOldestCommits[commit.branch_id] = commit.commit_id
      } else {
        branchNewestCommits[commit.branch_id] = commit.commit_id
      }

      commitToBranchMap[commit.commit_id] = commit.branch_id
      commit.parents.forEach((parent) => {
        const branchId = commitToBranchMap[parent]
        if (branchId && extractCommitNumber(branchOldestCommits[commit.branch_id]!) > extractCommitNumber(parent)) {
          // If the parent is older than the current oldest commit, update the oldest commit
          // and update the newest commit of the parent's branch to be the current commit,
          // so they won't overlap on rendering
          branchOldestCommits[commit.branch_id] = parent
          branchNewestCommits[branchId] = commit.commit_id
        }
      })
    })

    // We assign the default branch to the first offset and later ensure it will never be reused
    if (branchNewestCommits[DEFAULT_BRANCH_ID]) {
      branchPositions[DEFAULT_BRANCH_ID] = branchYInitialOffset
      usedOffsets[currentBranchYOffset] = {
        branchId: DEFAULT_BRANCH_ID,
        oldestCommit: branchOldestCommits[DEFAULT_BRANCH_ID]!,
        newestCommit: branchNewestCommits[DEFAULT_BRANCH_ID],
      }
    }

    branchPositions[DEFAULT_BRANCH_ID] = branchYInitialOffset
    Object.keys(branchNewestCommits).forEach((branchId) => {
      if (branchId !== DEFAULT_BRANCH_ID && !branchPositions[branchId]) {
        let assignedOffset = null
        for (const [offset, branchInfo] of Object.entries(usedOffsets)) {
          if (branchInfo.branchId === DEFAULT_BRANCH_ID) {
            continue
          }

          const currentBranchNewest = extractCommitNumber(branchNewestCommits[branchId]!)
          const currentBranchOldest = extractCommitNumber(branchOldestCommits[branchId]!)
          const usedBranchNewest = extractCommitNumber(branchInfo.newestCommit)
          const usedBranchOldest = extractCommitNumber(branchInfo.oldestCommit)
          if (currentBranchNewest < usedBranchOldest || currentBranchOldest > usedBranchNewest) {
            assignedOffset = parseInt(offset, 10)
            usedOffsets[assignedOffset] = {
              branchId: branchId,
              oldestCommit: branchOldestCommits[branchId]!,
              newestCommit: branchNewestCommits[branchId]!,
            }
            break
          }
        }

        // If no reusable offset found, assign a new one
        if (assignedOffset === null) {
          currentBranchYOffset += branchYIncrementalOffset
          assignedOffset = currentBranchYOffset
          usedOffsets[assignedOffset] = {
            branchId: branchId,
            oldestCommit: branchOldestCommits[branchId]!,
            newestCommit: branchNewestCommits[branchId]!,
          }
        }

        branchPositions[branchId] = assignedOffset
      }
    })
  }

  const generateNodes = () => {
    sortedCommits.forEach((commit, index) => {
      const yPosition = branchPositions[commit.branch_id]!
      const xPosition = index * branchXIncrementalOffset + branchXInitialOffset

      // Track the latest commit for each branch to add the branch label on top of it
      branchLatestCommits[commit.branch_id] = commit

      nodes.push({
        id: commit.commit_id,
        type: 'commitNode',
        position: { x: xPosition, y: yPosition },
        data: {
          label: commit.commit_message,
          author: commit.author,
          branch_id: commit.branch_id,
          branch_name: branches[commit.branch_id]?.branch_name,
          commit_id: commit.commit_id,
          commit_message: commit.commit_message,
          onNodeClick: () => onNodeClick(commit),
          isLatest: false, // default value, will update later
          isMergeCommit: commit.parents.length > 1,
        },
        dragHandle: 'non-existing', // Hack to disable dragging
      })
    })
  }

  const edges = sortedCommits.flatMap((commit) =>
    commit.parents
      .filter((parent) => commitIdList.includes(parent))
      .map((parent) => ({
        id: `e${parent}-${commit.commit_id}`,
        source: parent,
        target: commit.commit_id,
        animated: false,
        type: 'customEdge',
      }))
  )

  assignYAxisOffset()
  generateNodes()

  // Mark the latest commits and add branch labels
  Object.values(branchLatestCommits).forEach((latestCommit) => {
    const latestNode = nodes.find((node) => node.id === latestCommit.commit_id)
    if (latestNode) {
      latestNode.data.isLatest = true
    }
  })

  return { nodes, edges }
}

const SET_CENTER_DURATION = 1000
export const BranchGraph = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [selectedCommit, setSelectedCommit] = useState<Commit>()
  const { repoId } = useUrlState()
  const { commits, setCommits } = useAllCommits(repoId!)
  const { branchesById } = useBranchesById(repoId!)
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>()
  const [previousBranchPositions, setPreviousBranchPositions] = useState<Record<string, number>>({})
  const [loading, setLoading] = useState(true)

  const handleNodeClick = useCallback((commit: Commit) => {
    setSelectedCommit(commit)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const isParentMissing = selectedCommit?.parents.some((parent) => !nodes.some((node) => node.id === parent))
      if (repoId && isParentMissing) {
        const result = await RepositoryCommitManipulationService.srcHandlersv2CommitListAll({
          repoId,
          refIds: selectedCommit?.parents,
        })
        if ('items' in result) {
          setCommits((prevValue) => uniqBy([...prevValue, ...result.items], 'commit_id'))
        }
      }
    }

    fetchData()
  }, [nodes, repoId, selectedCommit, setCommits])

  useEffect(() => {
    const newNodeCoords = nodes.find((node) => node.id === selectedCommit?.commit_id)
    if (reactFlowInstance && newNodeCoords?.position) {
      reactFlowInstance.setCenter(newNodeCoords.position.x, newNodeCoords.position.y, {
        zoom: reactFlowInstance?.getZoom(),
        duration: SET_CENTER_DURATION,
      })
    }
  }, [nodes, reactFlowInstance, selectedCommit])

  useEffect(() => {
    if (nodes.length > 0) {
      const newBranchPositions: Record<string, number> = {}
      nodes.forEach((node) => {
        newBranchPositions[node.data.branch_id] = node.position.y
      })

      setPreviousBranchPositions((prevPositions) =>
        JSON.stringify(prevPositions) !== JSON.stringify(newBranchPositions) ? newBranchPositions : prevPositions
      )
    }
  }, [nodes])

  const onInit = useCallback(
    (instance: ReactFlowInstance) => {
      setReactFlowInstance(instance)
      commits && setSelectedCommit(commits[commits.length - 1])
    },
    [commits]
  )

  useEffect(() => {
    if (commits.length > 0) {
      const { nodes: newNodes, edges: newEdges } = generateFlowNodesAndEdges(
        commits,
        branchesById,
        handleNodeClick,
        previousBranchPositions
      )
      setNodes(newNodes)
      setEdges(newEdges)
      setLoading(false)
      const nodeToFocus = newNodes[newNodes.length - 1]
      if (nodeToFocus && reactFlowInstance) {
        reactFlowInstance.setCenter(nodeToFocus.position.x, nodeToFocus.position.y, {
          duration: SET_CENTER_DURATION,
        })
      }
    }
  }, [
    commits,
    setNodes,
    setEdges,
    branchesById,
    reactFlowInstance,
    handleNodeClick,
    previousBranchPositions,
    selectedCommit,
    loading,
  ])

  return (
    <>
      {loading ? (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      ) : (
        <>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            draggable={false}
            onInit={onInit}
            panOnScroll={true}
            nodesConnectable={false}
          >
            <Controls position={'top-left'} />
          </ReactFlow>
          <CommitDrawer selectedCommit={selectedCommit} repoId={repoId!} />
        </>
      )}
    </>
  )
}

const extractCommitNumber = (commitId: string): number => parseInt(commitId.split('.').pop()!, 10)

export default BranchGraph
