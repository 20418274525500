import styled from '@emotion/styled'
import { HEADER_HEIGHT_REM } from '../../theme'

export const OverlayFiller = styled.div<{ left?: number; leftIsRem?: boolean }>`
  display: block;
  background: ${({ theme }) => theme.colors.overlay.primary};
  position: absolute;
  top: ${HEADER_HEIGHT_REM}rem;
  bottom: 0;
  left: ${({ left, leftIsRem }) => `${left || 0}${leftIsRem ? 'rem' : 'px'}`};
  right: 0;
`
