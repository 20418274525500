import { useLocalStorage } from 'usehooks-ts'
import { useWorkspaces } from './api/useWorkspaces'
import { useEffect } from 'react'
import isNil from 'lodash/isNil'
import { milliseconds } from 'date-fns'
import { notNil } from '../utils/objectUtil'

export type CacheRecord = {
  workspaceId: string
  updateTimeMs: number
}
const WorkspaceCacheMinimumLifetime = { seconds: 5 }

export const useCachedWorkspace = (repoId: string | undefined, workspaceIdFromParam: string | undefined) => {
  const [repoToLastWorkspaceCache, setWorkspaceToRepoCache] = useLocalStorage<Record<string, CacheRecord | undefined>>(
    'repoToLastWorkspaceCache',
    {}
  )
  const { workspacesById } = useWorkspaces(repoId)
  useEffect(() => {
    // Cache invalidation case
    if (workspaceIdFromParam && repoId && isNil(workspacesById[workspaceIdFromParam])) {
      setWorkspaceToRepoCache((repoToLastWorkspaceCache) => {
        // We use update time and invalidation time to ensure we don't delete the cached value before we refreshed the updated workspace list after a new workspace was created
        const invalidationTime =
          (repoToLastWorkspaceCache[repoId]?.updateTimeMs ?? 0) + milliseconds(WorkspaceCacheMinimumLifetime)
        if (repoToLastWorkspaceCache[repoId] && Date.now() > invalidationTime) {
          return { ...repoToLastWorkspaceCache, [repoId]: undefined }
        } else {
          return repoToLastWorkspaceCache
        }
      })
    } else if (workspaceIdFromParam && repoId && notNil(workspacesById[workspaceIdFromParam])) {
      setWorkspaceToRepoCache((repoToLastWorkspaceCache) => {
        if (repoToLastWorkspaceCache[repoId]?.workspaceId !== workspaceIdFromParam) {
          return {
            ...repoToLastWorkspaceCache,
            [repoId]: { workspaceId: workspaceIdFromParam, updateTimeMs: Date.now() },
          }
        }
        return repoToLastWorkspaceCache
      })
    }
  }, [workspaceIdFromParam, repoId, workspacesById, setWorkspaceToRepoCache])

  const cachedWorkspaceId = repoToLastWorkspaceCache[repoId ?? '']?.workspaceId
  const workspace = workspacesById[workspaceIdFromParam || cachedWorkspaceId || '']
  const isWorkspaceCached = isNil(workspaceIdFromParam) && notNil(cachedWorkspaceId) && notNil(workspace)

  return { workspace, isWorkspaceCached }
}
