import last from 'lodash/last'
import trimEnd from 'lodash/trimEnd'

export const getFileName = (filePath: string) => last(filePath.split(getPathSeparatorFromPath(filePath))) || ''

export const getParentPath = (filePath: string) =>
  filePath.substring(0, filePath.lastIndexOf(getPathSeparatorFromPath(filePath)))

export const getFirstParentPath = (filePath: string) => filePath.split(getPathSeparatorFromPath(filePath))[0]

export const getAncestorPaths = (filePath: string) => {
  const paths = []
  while (filePath) {
    const parentPath = filePath.substring(0, filePath.lastIndexOf(getPathSeparatorFromPath(filePath)))
    paths.push(parentPath)
    filePath = parentPath
  }
  return paths
}

export const sanitizePath = (path: string) => trimEnd(path, getPathSeparatorFromPath(path))

export const getMoveLabel = (filePath: string, prevFilePath: string) =>
  getParentPath(filePath) === getParentPath(prevFilePath) ? 'Renamed' : 'Moved'

export const joinPaths = (path1: string, path2: string) => {
  return path1 === '' ? path2 : `${path1}${getPathSeparatorFromPath(path1)}${path2}`
}

// a hack to determine what is the current path seperator from an input path
// defaults to '/' if no path seperator is found.
export const getPathSeparatorFromPath = (path: string) => {
  return path.indexOf('\\') >= 0 ? '\\' : '/'
}

// returns all unique parent directories of the given files, sorted
export const getUniqueParentDirectories = (files: string[]): string[] => {
  const parentDirs = new Set<string>()

  files.forEach((file) => {
    getAncestorPaths(file).forEach((path) => parentDirs.add(path))
  })

  return Array.from(parentDirs).sort()
}
