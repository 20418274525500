import React from 'react'
import { BaseEdge, EdgeProps } from 'reactflow'

const controlPointOffset = 20 // Controls the curve's roundness

type CoordinatesProps = {
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
}

const HorizontalLine = ({ sourceX, sourceY, targetX, targetY }: CoordinatesProps) => (
  <BaseEdge path={`M${sourceX},${sourceY} H${targetX}`} />
)

const UpToDownLine = ({ sourceX, sourceY, targetX, targetY }: CoordinatesProps) => {
  const rightmostX = Math.max(sourceX, targetX)
  return (
    <BaseEdge
      path={`M${sourceX} ${sourceY}
                    H${targetX - controlPointOffset}
                    C${targetX - controlPointOffset} ${sourceY}
                     ${rightmostX} ${sourceY}
                     ${rightmostX} ${sourceY - controlPointOffset}
                    L${rightmostX} ${targetY}`}
    />
  )
}

const DownToUpLine = ({ sourceX, sourceY, targetX, targetY }: CoordinatesProps) => {
  const lowestY = Math.max(sourceY, targetY)
  return (
    <BaseEdge
      path={`M${sourceX},${sourceY}
                    V${lowestY - controlPointOffset}
                    C${sourceX} ${lowestY - controlPointOffset}
                     ${sourceX} ${lowestY}
                     ${sourceX + controlPointOffset} ${targetY}
                    L${targetX},${lowestY}`}
    />
  )
}

const CustomEdge = ({ sourceX, sourceY, targetX, targetY }: EdgeProps) => {
  const isGoingUp = targetY < sourceY
  const coordinates: CoordinatesProps = { sourceX, sourceY, targetX, targetY }

  return (
    <>
      {sourceY === targetY && <HorizontalLine {...coordinates} />}
      {isGoingUp && <UpToDownLine {...coordinates} />}
      {!isGoingUp && sourceY !== targetY && <DownToUpLine {...coordinates} />}
    </>
  )
}

export default CustomEdge
