import { useAnalytics } from '../../hooks/api/useAnalytics'
import { useCallback } from 'react'
import { MergeId, RepositoryShelvesManipulationService, Shelf } from '../../api/coreapi'
import isEmpty from 'lodash/isEmpty'
import { log } from '../../utils/log'
import { infoToast } from '../../utils/toast'
import { routeToMerge } from '../../Routes'
import { useNavigate } from 'react-router-dom'

export const useShelfChangesAsync = (repoId: string | undefined, workspaceId: string | undefined) => {
  const postAnalytics = useAnalytics()
  return useCallback(
    async (name: string, resetWorkspace: boolean) => {
      postAnalytics('ShelfCreated', { name })
      return (await RepositoryShelvesManipulationService.srcHandlersv2ShelfPost({
        repoId: repoId!,
        requestBody: {
          name: name,
          workspace_id: workspaceId!,
          reset_workspace: resetWorkspace,
        },
      })) as Shelf
    },
    [postAnalytics, repoId, workspaceId]
  )
}

export const useApplyShelfAsync = (repoId: string | undefined, workspaceId: string | undefined) => {
  const navigate = useNavigate()
  const postAnalytics = useAnalytics()
  return useCallback(
    async (shelfId: string, deleteShelf: boolean) => {
      postAnalytics('ShelfApplied', { shelf_id: shelfId })
      const result = await RepositoryShelvesManipulationService.srcHandlersv2ShelfApply({
        shelfId: shelfId,
        repoId: repoId!,
        requestBody: {
          workspace_id: workspaceId!,
          delete_shelf: deleteShelf,
        },
      })
      const mergeId = (result as MergeId)?.merge_id
      const hasConflicts = !isEmpty(mergeId)
      if (hasConflicts) {
        log.info('Shelf apply had conflicts', { repoId, shelfId, mergeId })
        infoToast('Conflicts discovered in shelf apply', true)
        navigate(routeToMerge(repoId!, mergeId))
      }
    },
    [navigate, postAnalytics, repoId, workspaceId]
  )
}
