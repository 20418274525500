import { Outlet } from 'react-router'
import { useUrlState } from '../../hooks/useUrlState'
import styled from '@emotion/styled'
import { MergesSidePanel } from '../sidepanel/MergesSidePanel'
import { FlexRow } from '../base/Flex'

const Container = styled(FlexRow)`
  height: 100%;
  width: 100%;
`

const OverlayFiller = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.overlay.primary};
`

export const MergesSidePanelRoute = () => {
  const { repoId, mergeId } = useUrlState()

  return (
    <Container>
      <MergesSidePanel repoId={repoId!} selectedMergeId={mergeId} />
      <OverlayFiller />
      <Outlet />
    </Container>
  )
}
