import { Refresh, useItemListApi } from '../useApi'
import { RepositoryWorkspaceManipulationService, Workspace } from '../../api/coreapi'
import { useMemo } from 'react'
import { groupBy } from '../../utils/objectUtil'
import { sortBy } from 'lodash'

export function useWorkspaces(repoId?: string): {
  workspacesById: Record<string, Workspace>
  workspacesLoading: boolean
  refresh: Refresh
} {
  const {
    data: workspaces,
    loading: workspacesLoading,
    refresh,
  } = useItemListApi<Workspace>(
    ['repos', repoId, 'workspaces'],
    repoId
      ? () =>
          RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceListAllWorkspaces({
            repoId,
          })
      : null
  )
  const workspacesById = useMemo(
    () =>
      groupBy(
        sortBy(workspaces, (ws) => ws.name?.toLowerCase()),
        (ws) => ws.workspace_id,
        (ws) => ws
      ),
    [workspaces]
  )

  return {
    workspacesById,
    workspacesLoading,
    refresh,
  }
}
