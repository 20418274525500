import { toast, ToastOptions } from 'material-react-toastify'
import { dedup } from './dedup'

const AUTO_CLOSE_SECONDS = 5

const commonOptions: ToastOptions = {
  position: 'top-right',
  autoClose: AUTO_CLOSE_SECONDS * 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
}

export const errorToast = (message?: string, skipDedup: boolean = false) => {
  const validMessage = message || 'Something went wrong'
  dedup(
    validMessage,
    () =>
      toast.error(message || 'Something went wrong', {
        ...commonOptions,
        type: 'error',
      }),
    skipDedup
  )
}

export const infoToast = (message: string, skipDedup: boolean = false) => {
  dedup(
    message,
    () =>
      toast.info(message, {
        ...commonOptions,
        type: 'info',
      }),
    skipDedup
  )
}
