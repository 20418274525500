import { AuthColumn, AuthErrorText, AuthPage, StyledInput } from './Common'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { PrimaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { Loader } from '../base/Loader'
import { callAsync } from '../../utils/callAsync'
import { resetPasswordVerifyAsync } from './actions'
import { usePageTitle } from '../../hooks/usePageTitle'
import { PublishApiErrorContext } from '../../App'
import { useNavigate } from 'react-router'
import { emailQueryParam, routeToSignIn, routeToSignUp, usernameQueryParam, verifyCodeQueryParam } from '../../Routes'
import { useSearchParams } from 'react-router-dom'
import { useKeyPress } from '../../hooks/useKeyPress'

const StyledButton = styled(PrimaryButton)`
  width: 100%;
`

const expectedVerifyErrors = ['UserNotFoundException']

export const PasswordResetVerifyPage = () => {
  const navigate = useNavigate()
  const [newPassword, setNewPassword] = useState<string>()
  const [requestError, setRequestError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const username = searchParams.get(usernameQueryParam)
  const email = searchParams.get(emailQueryParam)
  const verifyCodeFromParams = searchParams.get(verifyCodeQueryParam)
  const [code, setCode] = useState<string>(verifyCodeFromParams || '')
  const onApiError = useContext(PublishApiErrorContext)
  usePageTitle('Reset Password')

  useEffect(() => {
    if (isEmpty(username)) {
      navigate(routeToSignUp())
    }
  }, [navigate, username])

  const verifyCode = useCallback(async () => {
    await callAsync(
      async () => {
        await resetPasswordVerifyAsync(username!, code!, newPassword!)
        navigate(routeToSignIn())
      },
      setLoading,
      (error) => {
        if (expectedVerifyErrors.includes(error.name)) {
          setRequestError(error.message)
        } else {
          onApiError(error)
        }
      }
    )
  }, [username, code, newPassword, navigate, onApiError])

  useKeyPress(
    'Enter',
    (event: KeyboardEvent) => {
      if (!isEmpty(code)) {
        verifyCode()
      }
    },
    true
  )

  return (
    <AuthPage>
      <AuthColumn>
        <div>We have sent a password reset code {isEmpty(email) ? 'by email' : `to ${email}`}</div>
        <div>Enter the code below to reset your password</div>
        <StyledInput
          autoFocus
          type="text"
          value={code}
          placeholder="Verification code"
          onChange={(e) => {
            setRequestError(undefined)
            setCode(e.target.value)
          }}
        />
        <StyledInput
          type="password"
          placeholder="New password"
          autoComplete="new-password"
          onChange={(e) => {
            setRequestError(undefined)
            setNewPassword(e.target.value)
          }}
        />
        {loading ? (
          <Loader />
        ) : (
          <StyledButton disabled={isEmpty(code)} onClick={() => verifyCode()}>
            Change password
          </StyledButton>
        )}
        {requestError && <AuthErrorText>{requestError}</AuthErrorText>}
      </AuthColumn>
    </AuthPage>
  )
}
