import { Outlet } from 'react-router'
import styled from '@emotion/styled'
import { TextRegular, TextTitle } from '../base/TextStyle'
import { HEADER_HEIGHT_REM, Styleable } from '../../theme'
import { ReactNode } from 'react'
import { FlexColumn, FlexRowStyle } from '../base/Flex'

const Container = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.padding.xl}rem 25% ${HEADER_HEIGHT_REM + 4}rem 25%;
  gap: 1rem;
  align-self: start;
  text-align: start;
  overflow: auto;
`

export const IntroTitle = styled.div`
  ${TextTitle};
  ${FlexRowStyle};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.blue.primary};
  gap: 0.5rem;
  padding: 1rem 0;
`

export const IntroBody = styled.div<{ hinted?: boolean }>`
  ${TextRegular};
  color: ${({ theme, hinted }) => (hinted ? theme.colors.black.secondary : theme.colors.black.primary)};
  a {
    color: ${({ theme }) => theme.colors.blue.primary};
  }
  padding: 0.5rem 0;
`

export const IntroPage = ({ className, children }: Styleable & { children: ReactNode }) => {
  return (
    <>
      <Container className={className}>{children}</Container>
      <Outlet />
    </>
  )
}
