import { useNavigate } from 'react-router'
import { useUrlState } from '../../hooks/useUrlState'
import { useApi } from '../../hooks/useApi'
import { FileHistoryEntry, RepositoryManipulationService } from '../../api/coreapi'
import styled from '@emotion/styled'
import { TextBig } from '../base/TextStyle'
import { isFileNotFoundError } from '../../utils/errorClassify'
import { log } from '../../utils/log'
import { infoToast } from '../../utils/toast'
import { routeToRepo } from '../../Routes'
import { useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { useBranchesById } from '../../hooks/api/useBranches'
import { useTagsByCommitId } from '../../hooks/api/useTagsByCommitId'
import React, { useCallback, useEffect } from 'react'
import { CopyIcon } from '../base/CopyIcon'
import { FileHistoryEntryCard } from './FileHistoryEntryCard'
import { SidePanel } from '../sidepanel/SidePanel'
import isNil from 'lodash/isNil'
import { useSearchParamsValues } from '../../hooks/useSearchParamsValues'
import { useSearchParams } from 'react-router-dom'
import { FlexRowStyle } from '../base/Flex'

const DEFAULT_BRANCH_ID = 'dv.branch.1'

type Props = {
  selectedEntry: FileHistoryEntry | undefined
  onEntrySelected: (entry: FileHistoryEntry) => void
}

const Container = styled.div`
  padding-bottom: 6rem;
  color: ${({ theme }) => theme.colors.black.primary};
  height: 100%;
  overflow: auto;
`

const TitleRow = styled.div`
  ${FlexRowStyle};
  padding: ${({ theme }) => theme.padding.m}rem;
  padding-bottom: ${({ theme }) => theme.padding.l}rem;
  align-items: baseline;
  justify-content: center;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.blue.hover};
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
`

const Title = styled.div<{ isFirst?: boolean }>`
  ${TextBig};
  margin-top: ${({ isFirst }) => (isFirst ? 0 : 0.5)}rem;
`

const TitleFilePath = styled.span`
  color: ${({ theme }) => theme.colors.blue.primary};
`

const CommitsList = styled.div`
  padding: ${({ theme }) => theme.padding.xl}rem ${({ theme }) => theme.padding.l}rem;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const FileHistorySidePanel = ({ selectedEntry, onEntrySelected }: Props) => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const { repoId, '*': filePath } = useUrlState()
  const searchParams = useSearchParamsValues(['workspaceId', 'branchId', 'commitId'])
  const refId = searchParams['workspaceId'] || searchParams['branchId'] || DEFAULT_BRANCH_ID
  const copyToClipboard = useCopyToClipboardAction()
  const { branchesById } = useBranchesById(repoId!!)
  const { tagsByCommitId } = useTagsByCommitId(repoId!!)
  const { data: fileHistory, loading } = useApi(['repos', repoId, 'files', 'history', refId, filePath], async () => {
    try {
      return RepositoryManipulationService.srcHandlersv2CommitGetObjectHistory({
        repoId: repoId!,
        refId,
        path: filePath!,
      })
    } catch (e) {
      if (isFileNotFoundError(e)) {
        log.info('Path does not exist or is not a regular file, redirecting', { filePath })
        infoToast('Path does not exist')
        navigate(routeToRepo(repoId!))
      } else {
        throw e
      }
    }
  })
  const onCommitSelected = useCallback(
    (commitId: string, branchId: string) => {
      const entry = fileHistory?.entries.find((entry) => entry.commit.commit_id === commitId)
      entry && onEntrySelected(entry)
      setSearchParams(`?${new URLSearchParams({ ...searchParams, branchId, commitId })}`)
    },
    [fileHistory?.entries, onEntrySelected, searchParams, setSearchParams]
  )
  useEffect(() => {
    if (!fileHistory || fileHistory.entries.length === 0) {
      return
    }
    if (!selectedEntry || !searchParams['commitId']) {
      const defaultCommit = fileHistory.entries[0]!.commit
      onCommitSelected(
        searchParams['commitId'] || defaultCommit.commit_id,
        searchParams['branchId'] || defaultCommit.branch_id
      )
    }
  }, [fileHistory, onCommitSelected, searchParams, selectedEntry])
  return (
    <SidePanel
      contextName={'fileHistory'}
      hasOutletContent={!isNil(searchParams['commitId'])}
      loading={loading}
      minWidth={30}
    >
      <Container>
        <TitleRow>
          <Title isFirst>File History:</Title>
          <Title>
            <TitleFilePath>{filePath}</TitleFilePath>
            <CopyIcon copyToClipboard={copyToClipboard} title="Copy path" text={filePath!} />
          </Title>
        </TitleRow>
        <CommitsList>
          {fileHistory?.entries.map((entry) => (
            <FileHistoryEntryCard
              key={entry.commit.commit_id}
              repoId={repoId!}
              filePath={filePath!}
              historyEntry={entry}
              tags={tagsByCommitId[entry.commit.commit_id]}
              branch={branchesById[entry.commit.branch_id]}
              copyToClipboard={copyToClipboard}
              selected={searchParams['commitId'] === entry.commit.commit_id}
              onCommitSelected={(commit) => onCommitSelected(commit.commit_id, commit.branch_id)}
            />
          ))}
        </CommitsList>
      </Container>
    </SidePanel>
  )
}
