import { IntroBody, IntroPage, IntroTitle } from './IntroPage'
import React from 'react'
import { Anchor } from '../base/Anchor'
import config from '../../env/config'

export const PendingRoute = () => {
  return (
    <IntroPage>
      <IntroTitle>Thank you for signing up to Diversion</IntroTitle>
      <IntroBody>
        We're thrilled to have you on board. Our team is currently reviewing your application and will let you know as
        soon as it's been approved. In the meantime, feel free to browse our{' '}
        <Anchor target={config.MAIN_SITE} title={config.MAIN_SITE}>
          website
        </Anchor>{' '}
        and learn more about what our solution has to offer. We appreciate your patience and can't wait for you to start
        using Diversion soon!
      </IntroBody>
    </IntroPage>
  )
}
