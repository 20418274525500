import React, { createContext, useContext, useEffect } from 'react'
import { Outlet } from 'react-router'

type InDetachedCommit = {
  inDetachedCommitContext: boolean
  setInDetachedCommitContext: (inContext: boolean) => void
}

export const InDetachedCommitContext = createContext<InDetachedCommit>({
  inDetachedCommitContext: false,
  setInDetachedCommitContext: () => {},
})

export const DetachedCommitRoute = () => {
  const { setInDetachedCommitContext } = useContext(InDetachedCommitContext)

  useEffect(() => {
    setInDetachedCommitContext(true)
    return () => setInDetachedCommitContext(false)
  }, [setInDetachedCommitContext])

  return <Outlet />
}
