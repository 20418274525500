/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplyShelf } from '../models/ApplyShelf';
import type { CreateShelf } from '../models/CreateShelf';
import type { Error } from '../models/Error';
import type { MergeId } from '../models/MergeId';
import type { Shelf } from '../models/Shelf';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RepositoryShelvesManipulationService {
    /**
     * Get a list of all shelves in the repo.
     * @returns any A list of shelf objects
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2ShelfListAll({
        repoId,
        branchId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Optional branch id to filter by
         */
        branchId?: string,
    }): CancelablePromise<{
        object: 'Shelf';
        items: Array<Shelf>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/shelves',
            path: {
                'repo_id': repoId,
            },
            query: {
                'branch_id': branchId,
            },
        });
    }
    /**
     * Create a shelf
     * @returns Error An error occurred
     * @returns Shelf Shelf details returned
     * @throws ApiError
     */
    public static srcHandlersv2ShelfPost({
        repoId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        requestBody: CreateShelf,
    }): CancelablePromise<Error | Shelf> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/shelves',
            path: {
                'repo_id': repoId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Shelf with the same name already exists`,
            },
        });
    }
    /**
     * Get shelf details
     * @returns Shelf Shelf details returned
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2ShelfGet({
        repoId,
        shelfId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Shelf identifier
         */
        shelfId: string,
    }): CancelablePromise<Shelf | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/shelves/{shelf_id}',
            path: {
                'repo_id': repoId,
                'shelf_id': shelfId,
            },
        });
    }
    /**
     * Delete a shelf
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2ShelfDelete({
        repoId,
        shelfId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Shelf identifier
         */
        shelfId: string,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/repos/{repo_id}/shelves/{shelf_id}',
            path: {
                'repo_id': repoId,
                'shelf_id': shelfId,
            },
        });
    }
    /**
     * Apply a shelf onto a workspace
     * @returns Error An error occurred
     * @returns MergeId The merge is in progress. It has merge conflicts requiring active input from the user for conflict resolution. The response contains a merge ID, which can be used for querying /repos/{repo_id}/merges/{merge_id}
     * @throws ApiError
     */
    public static srcHandlersv2ShelfApply({
        repoId,
        shelfId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Shelf identifier
         */
        shelfId: string,
        requestBody: ApplyShelf,
    }): CancelablePromise<Error | MergeId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/shelves/{shelf_id}/apply',
            path: {
                'repo_id': repoId,
                'shelf_id': shelfId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
