import { useApi } from '../useApi'
import { Commit, RepositoryCommitManipulationService } from '../../api/coreapi'
import { useMemo } from 'react'
import { getCommitParentCommitId } from '../../utils/commitParent'

export const useCommit = (repoId: string, commitId?: string) => {
  const { data: commit, loading } = useApi<Commit>(
    ['repos', repoId, 'commits', commitId],
    commitId
      ? () =>
          RepositoryCommitManipulationService.srcHandlersv2CommitGet({
            repoId,
            commitId,
          })
      : null,
    true
  )
  const parentCommitId = useMemo(() => getCommitParentCommitId(commit), [commit])
  return { commit, parentCommitId, loading }
}
