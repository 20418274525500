import { DefaultService } from '../../../api/agentapi'
import { IsDesktopApp } from './DesktopAppApi'

const TriggerRefreshAgent = (workspaceId: string | undefined, repoId: string | undefined) => {
  if (IsDesktopApp() && workspaceId && repoId) {
    DefaultService.notifySyncRequired({ repoId, workspaceId })
  }
}

export { TriggerRefreshAgent }
