const DEFAULT_MAX_LOG_LINES = 5000

const logs: string[] = []
const logOptions: Array<keyof Console> = ['log', 'error', 'warn', 'info', 'debug']
let isLogCaptureInitiated = false

export const logCapture = (maxLogLines: number = DEFAULT_MAX_LOG_LINES) => {
  if (isLogCaptureInitiated) {
    return
  }
  const originalConsoleMethods: Record<keyof Console, Function> = logOptions.reduce(
    (acc, type) => {
      acc[type] = console[type]
      return acc
    },
    {} as Record<keyof Console, Function>
  )

  const capture = (type: keyof Console, ...args: any[]) => {
    const timestamp = new Date().toISOString()
    const message = args.join(' ')
    if (logs.length >= maxLogLines) {
      logs.shift()
    }
    logs.push(`[${timestamp}] [${type}] ${message}`)
    const originalConsoleMethod = originalConsoleMethods[type]
    originalConsoleMethod.apply(console, args)
  }

  logOptions.forEach((type) => {
    console[type] = ((...args: any[]) => capture(type, ...args)) as any
  })
  isLogCaptureInitiated = true
}

export const getLatestConsoleOutput = () => {
  return logs.join('\n')
}
