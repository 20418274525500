import { useCallback, useEffect, useState } from 'react'

type ExpandedState = {
  repoExpanded: boolean
  branchExpanded: boolean
}

export const useExpandedState = (
  repoId: string | undefined,
  branchId: string | undefined,
  inBranchlessContext: boolean,
  inRepolessContext: boolean,
  actionDialogOpen: boolean
) => {
  const [{ repoExpanded, branchExpanded }, setExpandedState] = useState<ExpandedState>({
    repoExpanded: false,
    branchExpanded: false,
  })

  const setRepoExpanded = useCallback(
    (expanded: boolean) => {
      if (expanded) {
        setExpandedState({ repoExpanded: true, branchExpanded: false })
      } else {
        setExpandedState({
          repoExpanded: false,
          branchExpanded: !actionDialogOpen && !inRepolessContext && !Boolean(branchId) && !inBranchlessContext,
        })
      }
    },
    [actionDialogOpen, branchId, inBranchlessContext, inRepolessContext]
  )

  const setBranchExpanded = useCallback(
    (expanded: boolean) => {
      if (expanded) {
        setExpandedState({ repoExpanded: false, branchExpanded: true })
      } else {
        setExpandedState({
          repoExpanded: !actionDialogOpen && !Boolean(branchId) && !inBranchlessContext,
          branchExpanded: false,
        })
      }
    },
    [actionDialogOpen, branchId, inBranchlessContext]
  )

  useEffect(() => {
    setExpandedState({
      repoExpanded: !actionDialogOpen && !Boolean(repoId) && !inRepolessContext,
      branchExpanded: !actionDialogOpen && Boolean(repoId) && !Boolean(branchId) && !inBranchlessContext,
    })
  }, [repoId, branchId, inBranchlessContext, inRepolessContext, actionDialogOpen])

  return { repoExpanded, setRepoExpanded, branchExpanded, setBranchExpanded }
}
