import {useEffect, useRef} from 'react'
import {StyledTextarea} from '../base/Input'

type Props = {
  value: string | null
  setCommitMessage: (message: string) => void
  rows?: number
}

export const CommitMessage = ({ setCommitMessage, value, rows }: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    if (ref.current && ref.current.value !== value) {
      ref.current.value = value || ''
    }
  }, [value])
  return (
    <StyledTextarea
      ref={ref}
      autoFocus={Boolean(value)}
      placeholder="Commit message (can be edited later from the History tab)"
      rows={rows || 3}
      defaultValue={value || undefined}
      onChange={(event) => {
        event.preventDefault()
        event.stopPropagation()
        setCommitMessage(event.target.value)
      }}
    />
  )
}
