import { useUrlState } from '../../hooks/useUrlState'
import { useTreeData } from '../../hooks/api/useTreeData'
import React, { ReactNode, useCallback, useState } from 'react'
import { useTreeSearch } from '../../hooks/api/useTreeSearch'
import Dialog from '@mui/material/Dialog'
import { StyledDialogActions, StyledDialogContent } from './DialogStyle'
import { PrimaryButton, SecondaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { FilePath, TextTitle } from '../base/TextStyle'
import { Loader } from '../base/Loader'
import debounce from 'lodash/debounce'
import { DirStructureTreeView, ReloadIfHasUnloadedChild } from '../tree/DirStructureTreeView'
import isNil from 'lodash/isNil'
import { Tooltip } from '@mui/material'
import { remToPx } from '../../theme'

type Props = {
  title: ReactNode
  subTitle?: ReactNode
  treeIdSuffix: string
  buttonLabel: string
  isOpen: boolean
  handleClose: () => void
  onSelected: (path: string) => void
  primaryButtonTooltip?: string
  disabled?: boolean
  loading?: boolean
}

const Title = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
`

const maxDepthToFetchInTree = 2
export const DirectoryPickerDialog = ({
  title,
  subTitle,
  treeIdSuffix,
  buttonLabel,
  isOpen,
  handleClose,
  onSelected,
  primaryButtonTooltip = '',
  disabled,
  loading,
}: Props) => {
  const { repoId, workspaceId } = useUrlState()
  const {
    treeData,
    loading: treeLoading,
    onExpandNodeAsync,
    isNewTree,
  } = useTreeData(
    repoId!,
    workspaceId!,
    undefined,
    {
      staticView: true,
      dirsOnly: true,
      useSelectiveSync: true,
      skipMergeSingleChildren: true,
    },
    maxDepthToFetchInTree
  )
  const [query, setQuery] = useState<string>()
  const { data: searchResultKeys, loading: searchLoading } = useTreeSearch(repoId!, workspaceId!, query, true)
  const [selectedPath, setSelectedPath] = useState<string>('')
  loading = loading || treeLoading
  const reloadIfSelected = useCallback(ReloadIfHasUnloadedChild, [])

  return (
    <Dialog onClose={() => handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <StyledDialogContent centerText={loading} maxHeight="48rem" width="48rem">
        <Title>{title}</Title>
        {subTitle}
        {loading ? (
          <Loader />
        ) : (
          <>
            {!isNil(selectedPath) && (
              <div>
                Selected path:&ensp;<FilePath>{selectedPath || '.'}</FilePath>
              </div>
            )}
            <DirStructureTreeView
              treeId={`${workspaceId!}-${treeIdSuffix}`}
              treeData={treeData!}
              setCheckedPathsCount={() => {}}
              redirectRouteOnClick={() => ''}
              changedOnly={false}
              onExpandNodeAsync={onExpandNodeAsync}
              onSelected={(key) => setSelectedPath(key)}
              selectedNodeKey={selectedPath}
              onSearch={debounce(setQuery)}
              autoFocusOnSearch={false}
              searchLoading={searchLoading}
              searchResultKeys={searchResultKeys}
              addRootNode
              enableWorkspaceActions={false}
              loadOnSelectOrExpand={reloadIfSelected}
              isNewTree={isNewTree}
              minHeight={remToPx(18)}
            />
          </>
        )}
      </StyledDialogContent>
      {!loading && (
        <StyledDialogActions>
          <Tooltip title={primaryButtonTooltip} arrow>
            <PrimaryButton disabled={disabled || isNil(selectedPath)} onClick={() => onSelected(selectedPath!)}>
              {buttonLabel}
            </PrimaryButton>
          </Tooltip>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        </StyledDialogActions>
      )}
    </Dialog>
  )
}
