import { useCallback, useContext } from 'react'
import { callAsync, OnError } from '../../utils/callAsync'
import {
  AnalyticsEvent as ApiAnalyticsEvent,
  type AnalyticsIdentify,
  AnalyticsService,
  OpenAPI as CoreApiConfig,
} from '../../api/coreapi'
import { PublishApiErrorContext } from '../../App'
import { AnalyticsEvent, AnalyticsEventProperty, LocationContext } from '../../models/Analytics'
import { useUrlState } from '../useUrlState'
import { log } from '../../utils/log'
import { DesktopAppVersion } from '../../desktop/components/utils/DesktopAppApi'

export type AnalyticsCallback = (
  event: AnalyticsEvent,
  properties: Partial<Record<AnalyticsEventProperty, string>>,
  locationContext?: LocationContext
) => void

const setIfNotPresent = (
  properties: Partial<Record<AnalyticsEventProperty, string>>,
  key: AnalyticsEventProperty,
  value: string | undefined
) => {
  if (!value || properties[key]) {
    return
  }
  properties[key] = value
}

export const useAnalyticsRoot = (onApiError: OnError): AnalyticsCallback => {
  const { repoId, workspaceId, branchId } = useUrlState()
  return useCallback(
    (
      event: AnalyticsEvent,
      properties: Partial<Record<AnalyticsEventProperty, string>>,
      locationContext?: LocationContext
    ) => {
      properties['url'] = window.location.href
      setIfNotPresent(properties, 'repo_id', repoId)
      setIfNotPresent(properties, 'workspace_id', workspaceId)
      setIfNotPresent(properties, 'branch_id', branchId)
      setIfNotPresent(properties, 'context', locationContext)
      setIfNotPresent(properties, 'desktop_app_version', DesktopAppVersion())
      const apiEvent: ApiAnalyticsEvent = { event, source: 'web', time: new Date().toDateString(), properties }
      const body = {
        requestBody: {
          events: [apiEvent],
        },
      }
      callAsync(
        async () =>
          await (Boolean(CoreApiConfig.TOKEN)
            ? AnalyticsService.srcHandlersAnalyticsIngest(body)
            : AnalyticsService.srcHandlersAnalyticsIngestAnon(body)),
        () => {},
        onApiError
      )
    },
    [branchId, onApiError, repoId, workspaceId]
  )
}

export const identifyUser = () => {
  const identifyEvent: AnalyticsIdentify = { source: 'web', time: new Date().toDateString() }
  const body = {
    requestBody: identifyEvent,
  }
  callAsync(
    async () =>
      await (Boolean(CoreApiConfig.TOKEN)
        ? AnalyticsService.srcHandlersAnalyticsIdentify(body)
        : log.error('cannot identify user that is not signed in')),
    () => {},
    (error) => {
      log.error('failed calling /identify', error)
    }
  )
}

export const useAnalytics = (): AnalyticsCallback => {
  const onApiError = useContext(PublishApiErrorContext)
  return useAnalyticsRoot(onApiError)
}
