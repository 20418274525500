import { ResetButton } from './reset/ResetButton'
import styled from '@emotion/styled'
import { TextSmall } from '../../base/TextStyle'
import { BannerPadding } from '../../base/PaddingStyle'
import { Checkbox } from '../../base/Checkbox'
import { pluralize } from '../../../utils/textUtils'
import { FlexColumn, FlexRow } from '../../base/Flex'
import { Tooltip } from '@mui/material'
import { Loader } from '../../base/Loader'

type Props = {
  uploadingAgents?: string[]
  upperRowLabel: React.JSX.Element
  onResetClick: () => void
  allChecked: boolean | null
  onAllChecked: (checked: boolean) => void
  cascadedChangesCount: number
  refreshingViewAfterAction: boolean
}

const Container = styled(FlexRow)`
  ${BannerPadding};
  text-align: center;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue.light};
`

const StyledText = styled.div`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.black.secondary};
  text-align: start;
`

export const WorkspaceTopBar = ({
  upperRowLabel,
  onResetClick,
  allChecked,
  onAllChecked,
  cascadedChangesCount,
  refreshingViewAfterAction,
}: Props) => (
  <Container>
    <Checkbox title="Select all" checked={allChecked} setChecked={onAllChecked} />
    <FlexColumn centered={true}>
      <StyledText>{upperRowLabel}</StyledText>
      {cascadedChangesCount > 0 && <StyledText>{`${pluralize(cascadedChangesCount, 'path')} modified`}</StyledText>}
    </FlexColumn>
    <FlexRow gap={1} centered={true}>
      {refreshingViewAfterAction && (
        <Tooltip title={'Refreshing view after action'} arrow>
          <span>
            <Loader size={22} />
          </span>
        </Tooltip>
      )}
      <ResetButton enabled={!refreshingViewAfterAction && allChecked !== false} onClick={onResetClick} />
    </FlexRow>
  </Container>
)
