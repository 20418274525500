import { useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from '../base/Flex'
import { DangerButton, PrimaryButton } from '../base/PrimaryButton'
import { Loader } from '../base/Loader'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { Checkbox } from '../base/Checkbox'
import { includes, startCase } from 'lodash'
import config from '../../env/config'

enum ListenableAction {
  'commit' = 'commit',
  'merge' = 'merge',
}

type Webhook = {
  actions: ListenableAction[]
  url: string
}

const getWebhooksAsync = async () => [] as Webhook[]

const saveWebhookAsync = async (webhook: Webhook) => {}

const deleteWebhookAsync = async (webhook: Webhook) => {}

const StyledInput = styled.input`
  padding: ${({ theme }) => theme.padding.m}rem;
  margin: ${({ theme }) => theme.padding.s}rem 0;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: 0.5rem;
  width: 24rem;
`

const WebhookRow = (webhook: Webhook) => {
  const [actions, setActions] = useState(webhook.actions)
  const [url, setUrl] = useState(webhook.url)
  return (
    <FlexRow gap={1}>
      <FlexColumn gap={0.5}>
        <StyledInput
          autoFocus
          type="text"
          placeholder="https://"
          defaultValue={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <FlexRow gap={1}>
          <div>Fire on:</div>
          {Object.keys(ListenableAction)
            .map((action) => ListenableAction[action as ListenableAction])
            .map((action) => (
              <FlexRow key={action} gap={0.5}>
                <Checkbox
                  title={startCase(action)}
                  checked={includes(actions, action)}
                  setChecked={(checked) => {
                    if (checked) {
                      setActions((curr) => [...curr, action])
                    } else {
                      setActions((curr) => curr.filter((a) => a !== action))
                    }
                  }}
                />
                {startCase(action)}
              </FlexRow>
            ))}
        </FlexRow>
      </FlexColumn>
      <PrimaryButton disabled={isEmpty(url) || isEmpty(actions)} onClick={() => saveWebhookAsync({ actions, url })}>
        Save
      </PrimaryButton>
      <DangerButton onClick={() => deleteWebhookAsync(webhook)}>Delete</DangerButton>
    </FlexRow>
  )
}

export const WebhooksTab = () => {
  const [loading, setLoading] = useState(false)
  const [webhooks, setWebhooks] = useState<Webhook[]>([])

  useEffect(() => {
    setLoading(true)
    getWebhooksAsync()
      .then(setWebhooks)
      .finally(() => setLoading(false))
  }, [])

  if (!config.IS_DEV_ENV) {
    return <div>Coming soon!</div>
  }

  return (
    <FlexColumn gap={1}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {webhooks.length === 0 && <div>You had not created any webhooks yet</div>}
          {webhooks.map((webhook, i) => (
            <WebhookRow key={i} {...webhook} />
          ))}
        </>
      )}
      <PrimaryButton disabled={false} onClick={() => setWebhooks([...webhooks, { actions: [], url: '' }])}>
        Add Webhook
      </PrimaryButton>
    </FlexColumn>
  )
}
