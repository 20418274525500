import React from 'react'
import './index.css'
import { App } from './App'
import { reportWebVitals } from './utils/reportWebVitals'
import { log } from './utils/log'
import { createRoot } from 'react-dom/client'
import config from './env/config'
import { sentry } from './env/sentry'
import { Amplify } from 'aws-amplify'
import { cognitoWebAppConfig } from './env/cognito'
import { configureCoreApi, configureAgentApi } from './api/configure'
import { IsDesktopApp } from './desktop/components/utils/DesktopAppApi'
import { routeToDesktopLogin, routeToDesktopLogout } from './Routes'
import TagManager from '@sooro-io/react-gtm-module'
import { logCapture } from './utils/logCapture'

if (IsDesktopApp()) {
  // We currently support it only in the desktop app, once we'll add it to core api we can add it also to the web app
  logCapture()
}

log.info('config', config)

sentry.init()

/**
 * In some cases we don't want to initialize the Amplify library to get a session
 * 1. If we are in auth token mode
 * 2. If we are routed to the desktop login / logout pages from the OAuth2 flow in the web app since we redirect it to the desktop app
 **/
if (
  !config.AUTH_TOKEN_MODE &&
  (![routeToDesktopLogin(), routeToDesktopLogout()].includes(window.location.pathname) || IsDesktopApp())
) {
  Amplify.configure(cognitoWebAppConfig)
}

configureCoreApi()

if (IsDesktopApp()) {
  configureAgentApi()
}

TagManager.initialize({ gtmId: config.GOOGLE_TAG_MANAGER_ID })

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

reportWebVitals((metric) => log.info('web-vitals-metric', metric))
