import { Outlet } from 'react-router'
import { Header } from '../header/Header'
import styled from '@emotion/styled'
import { FlexColumn } from '../base/Flex'
import { BranchActionContext, useBranchActions } from '../branch/useBranchActions'
import { useWorkspaceActions, WorkspaceActionContext } from '../workspace/WorkspaceActions'
import { CommitActionContext, useCommitActions } from '../commit/CommitActions'
import { SyncStatusType, SyncStatusContext, SyncStatusData } from '../header/syncstatus/SyncStatusContext'
import { useEffect, useRef, useState } from 'react'
import { NotificationsBar } from '../dashboard/NotificationsBar'
import { useNotifications } from '../../hooks/api/useNotifications'
import { log } from '../../utils/log'

type Props = {
  repoImporting?: boolean
}

const Container = styled(FlexColumn)<{ notificationBarHeight: number }>`
  height: calc(100vh - ${({ notificationBarHeight }) => notificationBarHeight}px);
  width: 100vw;
  position: fixed;
`

const Content = styled.div<{ notificationBarHeight: number }>`
  display: flex;
  flex: 1 1 0;
  height: calc(100vh - 4rem - ${({ notificationBarHeight }) => notificationBarHeight}px);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`

const ImportingMessage = styled.div`
  text-align: center;
  width: 100%;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.blue.primary};
  margin-top: ${({ theme }) => theme.padding.xl}rem;
`

export const BrowseRoute = ({ repoImporting }: Props) => {
  const { doAction: doBranchAction, dialogs: BranchActionDialogs } = useBranchActions()
  const { doAction: doWorkspaceAction, dialogs: WorkspaceActionDialogs } = useWorkspaceActions()
  const { doAction: doCommitAction, dialogs: CommitActionDialogs } = useCommitActions()
  const { data: notifications, refresh: refreshNotification } = useNotifications()
  const [syncStatusData, setSyncStatusData] = useState<SyncStatusData>({ syncStatus: SyncStatusType.None })
  const [notificationBarHeight, setNotificationBarHeight] = useState(0)
  const notificationsBarRef = useRef<HTMLDivElement>(null)
  if (notifications && notifications.length > 2) {
    log.error('Only showing first 2 notifications in the notifications bar, but received more.')
  }

  useEffect(() => {
    if (notificationsBarRef.current) {
      setNotificationBarHeight(notificationsBarRef.current.offsetHeight)
    }
  }, [notifications])

  return (
    <>
      <BranchActionDialogs />
      <WorkspaceActionDialogs />
      <CommitActionDialogs />
      <BranchActionContext.Provider value={{ doAction: doBranchAction }}>
        <WorkspaceActionContext.Provider value={{ doAction: doWorkspaceAction }}>
          <CommitActionContext.Provider value={{ doAction: doCommitAction }}>
            <SyncStatusContext.Provider value={{ ...syncStatusData, doAction: setSyncStatusData }}>
              {notifications && (
                <div ref={notificationsBarRef}>
                  <NotificationsBar
                    notifications={notifications.slice(0, 2)}
                    refreshNotification={refreshNotification}
                  />
                </div>
              )}
              <Container notificationBarHeight={notificationBarHeight}>
                <Header repoImporting={repoImporting} />
                <Content notificationBarHeight={notificationBarHeight}>
                  {repoImporting ? (
                    <ImportingMessage>
                      Repository is being imported. You will be notified once import is completed.
                    </ImportingMessage>
                  ) : (
                    <Outlet />
                  )}
                </Content>
              </Container>
            </SyncStatusContext.Provider>
          </CommitActionContext.Provider>
        </WorkspaceActionContext.Provider>
      </BranchActionContext.Provider>
    </>
  )
}
