import { useAnalytics } from '../../../hooks/api/useAnalytics'
import { useUrlState } from '../../../hooks/useUrlState'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { PublishApiErrorContext } from '../../../App'
import { DirectoryPickerDialog } from '../../dialogs/DirectoryPickerDialog'
import { Loader } from '../../base/Loader'
import { Tooltip } from '@mui/material'
import { Styleable } from '../../../theme'
import styled from '@emotion/styled'
import CreateDirIcon from '@mui/icons-material/CreateNewFolder'
import { FileMutationService } from '../../../api/coreapi'
import { FILE_MODE_DIRECTORY } from '../../../models/fileMode'
import { infoToast } from '../../../utils/toast'
import { callAsync } from '../../../utils/callAsync'
import isEmpty from 'lodash/isEmpty'
import { epochSeconds } from '../../../utils/dateUtils'
import { WorkspaceRevisionContext } from '../../workspace/useWorkspaceRevisionUpdater'
import { clientId } from '../../../api/configure'
import { DialogInput } from '../../dialogs/DialogStyle'

type Props = Styleable & {
  onCreated: (atPath: string) => void
}

const StyledCreateDirIcon = styled(CreateDirIcon)`
  cursor: pointer;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.blue.contrastText};
  background-color: ${({ theme }) => theme.colors.blue.primary};
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.padding.s}rem;
`
const ValidationError = styled.div`
  color: red;
  font-size: 0.8rem;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const createDirAsync = async (repoId: string, workspaceId: string, targetDirectoryPath: string) => {
  await FileMutationService.srcHandlersv2FilesCreateOrUpdateFile({
    repoId,
    refId: workspaceId,
    path: targetDirectoryPath,
    xDvClientId: clientId(),
    mode: FILE_MODE_DIRECTORY,
    mtime: epochSeconds(),
    requestBody: new Blob(),
  })
  infoToast('Created successfully', true)
}

export const WorkspaceCreateDir = ({ className, onCreated }: Props) => {
  const postAnalytics = useAnalytics()
  const { repoId, workspaceId } = useUrlState()
  const [targetPathDialogOpen, setTargetPathDialogOpen] = useState(false)
  const [name, setName] = useState<string>()
  const [nameErr, setNameErr] = useState<string>()
  const [creating, setCreating] = useState(false)
  const { refresh: refreshWorkspaceRevision } = useContext(WorkspaceRevisionContext)
  const onApiError = useContext(PublishApiErrorContext)

  const createDirCallback = useCallback(
    async (parentDirectoryPath: string, directoryPath: string) => {
      await callAsync(
        async () => {
          await createDirAsync(repoId!, workspaceId!, directoryPath)
          refreshWorkspaceRevision?.()
          onCreated(parentDirectoryPath)
          postAnalytics('CreateDirCreated', { repo_id: repoId, workspace_id: workspaceId, path: directoryPath })
        },
        setCreating,
        (error) => {
          onApiError(error)
        }
      )
    },
    [onApiError, onCreated, postAnalytics, refreshWorkspaceRevision, repoId, workspaceId]
  )

  useEffect(() => {
    const invalidCharsRegex = /[<>:"\\/|?*]/ // Regular expression to match invalid characters
    setNameErr(name && invalidCharsRegex.test(name) ? 'Name cannot contain invalid characters' : '')
  }, [name])

  return (
    <>
      {targetPathDialogOpen && (
        <DirectoryPickerDialog
          title="Create a new directory at path"
          subTitle={
            <InputContainer>
              <DialogInput
                autoFocus
                type="text"
                placeholder="Name"
                onChange={(event) => setName(event.target.value || '')}
              />
              {nameErr && <ValidationError>{nameErr}</ValidationError>}
            </InputContainer>
          }
          treeIdSuffix="create-dir"
          buttonLabel="Create directory"
          isOpen={targetPathDialogOpen}
          handleClose={() => setTargetPathDialogOpen(false)}
          disabled={isEmpty(name) || !isEmpty(nameErr)}
          onSelected={(path) => {
            setTargetPathDialogOpen(false)
            setCreating(true)
            createDirCallback(path, isEmpty(path) ? name! : `${path}/${name}`)
          }}
        />
      )}
      <div className={className}>
        {creating ? (
          <Loader size={18} />
        ) : (
          <Tooltip title="Create a new directory" arrow>
            <StyledCreateDirIcon
              onClick={() => {
                postAnalytics('CreateDirButtonClick', { repo_id: repoId, workspace_id: workspaceId })
                setTargetPathDialogOpen(true)
              }}
            />
          </Tooltip>
        )}
      </div>
    </>
  )
}
