import React, { useState } from 'react'
import { FileHistorySidePanel } from './FileHistorySidePanel'
import { Outlet } from 'react-router'
import { FlexColumnStyle } from '../base/Flex'
import styled from '@emotion/styled'
import { FileViewerPathContext } from '../file/useFileAndBaseFile'
import { FileHistoryEntry } from '../../api/coreapi'

const OutletWrapper = styled.div`
  ${FlexColumnStyle};
  width: 100%;
`

export const FileHistoryRoute = () => {
  const [selectedEntry, setSelectedEntry] = useState<FileHistoryEntry>()
  return (
    <FileViewerPathContext.Provider
      value={{
        basePath: selectedEntry?.entry.prev_path,
        path: selectedEntry?.entry.path,
        setBasePath: () => {},
        setPath: () => {},
      }}
    >
      <FileHistorySidePanel selectedEntry={selectedEntry} onEntrySelected={setSelectedEntry} />
      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
    </FileViewerPathContext.Provider>
  )
}
