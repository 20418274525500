import { ActionDialog } from '../dialogs/ActionDialog'
import React, { useCallback, useState } from 'react'
import { RepositoryWorkspaceManipulationService, Workspace } from '../../api/coreapi'
import { infoToast } from '../../utils/toast'
import { useWorkspaces } from '../../hooks/api/useWorkspaces'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { FilePath } from '../base/TextStyle'
import { DialogInput } from '../dialogs/DialogStyle'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'

const RenameBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

interface RenameWorkspaceDialogProps {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  workspace: Workspace
}

export const RenameWorkspaceDialog = ({ isOpen, setOpen, workspace }: RenameWorkspaceDialogProps) => {
  const { repo_id: repoId, workspace_id: workspaceId, name: workspaceName } = workspace
  const { refresh: refreshWorkspaces } = useWorkspaces(repoId)
  const postAnalytics = useAnalytics()
  const [newName, setNewName] = useState<string>('')

  const workspaceRename = useCallback(async () => {
    if (isEmpty(newName) || newName === workspaceName) {
      return
    }
    await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceRenameWorkspace({
      repoId,
      workspaceId,
      requestBody: {
        name: newName,
      },
    })
    infoToast(`Workspace renamed successfully`, true)
    postAnalytics('WorkspaceRenamed', { repo_id: repoId, workspace_id: workspaceId })
    refreshWorkspaces()
  }, [postAnalytics, refreshWorkspaces, repoId, workspaceName, newName, workspaceId])

  return (
    <ActionDialog
      title="Rename Workspace"
      isOpen={isOpen}
      setOpen={setOpen}
      onConfirmAsync={workspaceRename}
      message={
        <RenameBody>
          <div>
            Enter new name for <FilePath>{workspaceName}</FilePath>
          </div>
          <DialogInput
            autoFocus
            type="text"
            placeholder="Name"
            defaultValue={workspaceName}
            onChange={(event) => setNewName(event.target.value || '')}
          />
        </RenameBody>
      }
      confirmButtonLabel="Rename"
      loadingMessage="Renaming workspace..."
      disabled={isEmpty(newName) || newName === workspaceName}
    />
  )
}
