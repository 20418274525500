import { FlexColumn } from '../base/Flex'
import { CodeRef } from '../base/CodeRef'
import { ActionDialog } from '../dialogs/ActionDialog'
import React, { useCallback } from 'react'
import { RepositoryWorkspaceManipulationService, Workspace } from '../../api/coreapi'
import { infoToast } from '../../utils/toast'
import { useWorkspaces } from '../../hooks/api/useWorkspaces'
import { useAnalytics } from '../../hooks/api/useAnalytics'

interface DeletedWorkspaceDialogProps {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  workspace: Workspace
}

export const DeleteWorkspaceDialog = ({ isOpen, setOpen, workspace }: DeletedWorkspaceDialogProps) => {
  const { repo_id: repoId, workspace_id: workspaceId, name: workspaceName } = workspace
  const { refresh: refreshWorkspaces } = useWorkspaces(repoId)
  const postAnalytics = useAnalytics()
  const workspaceDelete = useCallback(async () => {
    postAnalytics('WorkspaceDeleted', { repo_id: repoId, workspace_id: workspaceId })
    await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceDelete({
      repoId,
      workspaceId,
    })
    infoToast(`Workspace ${workspaceName} deleted`, true)
    refreshWorkspaces()
  }, [postAnalytics, refreshWorkspaces, repoId, workspaceName, workspaceId])

  return (
    <ActionDialog
      title="Delete Workspace"
      isOpen={isOpen}
      setOpen={setOpen}
      onConfirmAsync={workspaceDelete}
      message={
        <FlexColumn gap={1}>
          <div>
            Are you sure you want to delete the workspace <CodeRef>{workspaceName}</CodeRef>
          </div>
          <div>All uncommitted changes will be lost.</div>
        </FlexColumn>
      }
      confirmButtonLabel="Delete"
      loadingMessage="Deleting workspace..."
      isDanger
    />
  )
}
