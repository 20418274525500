/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrganizationSubscription = {
    /**
     * Unique identifier for the organization subscription
     */
    id: string;
    /**
     * ID of the organization
     */
    organization_id: string;
    /**
     * Type of the subscription
     */
    subscription_type: OrganizationSubscription.subscription_type;
    /**
     * Amount of the subscription
     */
    amount: number;
    /**
     * Start of the grace period
     */
    grace_period_start?: string;
    /**
     * End of the grace period
     */
    grace_period_end?: string;
};
export namespace OrganizationSubscription {
    /**
     * Type of the subscription
     */
    export enum subscription_type {
        STORAGE_GB = 'STORAGE_GB',
        USERS = 'USERS',
    }
}

