import config from '../env/config'

export const setFavicon = () => {
  if (!config.IS_DEV_ENV) {
    return
  }
  const link: Element | null = document.querySelector("link[rel~='icon']")
  if (link) {
    ;(link as HTMLLinkElement).href = `${window.location.origin}/favicon.test.ico`
  }
}
