import { useBlobByPath } from '../../hooks/blob/useBlob'
import isNil from 'lodash/isNil'
import { createContext, useContext } from 'react'

type FilePath = {
  path?: string
  setPath: (path?: string) => void
  basePath?: string
  setBasePath: (basePath?: string) => void
}

export const FileViewerPathContext = createContext<FilePath>({
  setBasePath: () => {},
  setPath: () => {},
})

export const useFileAndBaseFile = (
  repoId: string,
  filePath: string,
  refId: string | undefined,
  baseRefId: string | undefined,
  currentRevision: number | undefined = undefined
) => {
  const { path, basePath } = useContext(FileViewerPathContext)
  const {
    data: file,
    loading: fileLoading,
    refresh: refreshFile,
  } = useBlobByPath(repoId, refId, filePath, currentRevision)
  filePath = path || filePath
  const baseFilePath = basePath || filePath
  const { data: baseFile, loading: baseFileLoading } = useBlobByPath(
    repoId,
    isNil(baseFilePath) ? undefined : baseRefId,
    baseFilePath || filePath,
    currentRevision
  )
  return {
    file,
    baseFile,
    loading: fileLoading || baseFileLoading,
    refreshFile,
  }
}
