import { IntroBody, IntroTitle } from '../IntroPage'
import config from '../../../env/config'
import { openInNewTab } from '../../../utils/navigateRaw'
import styled from '@emotion/styled'
import { FlexRowStyle } from '../../base/Flex'
import { Anchor } from '../../base/Anchor'
import { useAnalytics } from '../../../hooks/api/useAnalytics'
import {Table, TableBody, TableCell, TableRow} from "@mui/material";

const PluginBanner = styled(IntroBody)`
  ${FlexRowStyle};
  align-items: center;
  gap: 1rem;
`

const Block = styled.div`
  line-height: 1.8rem;
`

export const FinalPage = () => {
  const postAnalytics = useAnalytics()
  return (
    <>
      <IntroTitle>Great. You can now start using Diversion!</IntroTitle>
      <Block>
        <IntroBody>Some useful resources:</IntroBody>
          <IntroBody>
            Check out the{' '}
            <Anchor target={config.QUICKSTART_LINK} description="Diversion Quick Start">
              quick start guide
            </Anchor>.
          </IntroBody>
        <IntroBody>
          See the docs for an overview of {' '} <Anchor target={"https://docs.diversion.dev/basic/use-existing-repo"}>repo usage</Anchor>.
        </IntroBody>
        <IntroBody>
          Share your repo with collaborators, {' '} <Anchor target={"https://docs.diversion.dev/basic/share-your-repo"}>learn how</Anchor>.
        </IntroBody>
      </Block>

      <IntroTitle>Diversion Plugins</IntroTitle>
    <Table sx={{ maxWidth: 500 }}>
      <TableBody>
        <TableRow>
          <TableCell width={220}>
            Unreal Editor Plugin
          </TableCell>
          <TableCell align="left">
            <PluginBanner onClick={() => openInNewTab(`https://www.unrealengine.com/marketplace/en-US/product/${config.UNREAL_PLUGIN_ID}`, postAnalytics)}>
              <button><img src={"https://dv-test-bin.s3.us-east-2.amazonaws.com/assets/UE-marketplace.png"} alt="Unreal Engine Marketplace" height={"58px"} width={"245px"} /></button>
            </PluginBanner>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={220}>
            Rider / JetBrains Plugin
          </TableCell>
          <TableCell align="left">
            <PluginBanner
              onClick={() => openInNewTab(`https://plugins.jetbrains.com/plugin/${config.JB_PLUGIN_ID}`, postAnalytics)}
            >
              <iframe
                title="Rider / JetBrains Plugin"
                width="245px"
                height="48px"
                src={`https://plugins.jetbrains.com/embeddable/install/${config.JB_PLUGIN_ID}`}
              />
            </PluginBanner>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}
