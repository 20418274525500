import { BrowseRoute } from './BrowseRoute'
import { useEffect, useMemo } from 'react'
import { log } from '../../utils/log'
import { infoToast } from '../../utils/toast'
import { routeToDashboard } from '../../Routes'
import { useNavigate } from 'react-router'
import { useUrlState } from '../../hooks/useUrlState'
import { useRepo } from '../../hooks/api/useRepo'
import isNil from 'lodash/isNil'

export const RepoRoute = () => {
  const navigate = useNavigate()
  const { repoId } = useUrlState()
  const { repo, repoLoading, failed } = useRepo(repoId!!)

  useEffect(() => {
    if (repoId && !repoLoading && !repo && !failed) {
      log.info('Repo not found, redirecting back to home page', repoId)
      infoToast('Repository not found')
      navigate(routeToDashboard(), { replace: true })
    }
  }, [failed, navigate, repo, repoId, repoLoading])

  const isImporting = useMemo(() => {
    return Boolean(repo && repo.sync_git_repo_url && isNil(repo.default_branch_id))
  }, [repo])

  return <BrowseRoute repoImporting={isImporting} />
}
