import { MergeId } from '../api/coreapi'

const SHELF_ID_PREFIX = 'dv.shelf.'
const PATCH_ID_PREFIX = 'dv.patch.'
const WORKSPACE_ID_PREFIX = 'dv.ws.'

export const isShelfId = (id?: string) => id?.startsWith(SHELF_ID_PREFIX) || false
export const isPatchId = (id?: string) => id?.startsWith(PATCH_ID_PREFIX) || false

export const isWorkspaceId = (id?: string) => id?.startsWith(WORKSPACE_ID_PREFIX) || false

export const isMergeIdResponse = (response: any | MergeId): response is MergeId =>
  (response as MergeId)?.merge_id !== undefined
