import * as React from 'react'
import { ReactNode, RefObject, useState } from 'react'
import { useHover } from 'usehooks-ts'
import { OptionsDropdown } from './OptionsDropdown'
import MenuIcon from '@mui/icons-material/Menu'
import styled from '@emotion/styled'
import { FlexRow } from '../base/Flex'

export type menuAction = {
  icon: ReactNode
  action: () => void
}

type DotsDropdownProps = {
  parentRef: RefObject<HTMLDivElement>
  enabled: boolean
  actions: Record<string, menuAction>
}

type MenuProps = {
  setMenuOpen: (open: boolean) => void
  actions: Record<string, menuAction>
}

const StyledMenuIcon = styled(MenuIcon)`
  margin: 0 0.5rem;
`

const ActionsMenu = ({ setMenuOpen, actions }: MenuProps) => (
  <OptionsDropdown
    button={<StyledMenuIcon onClick={(e) => e.preventDefault()} />}
    onOpenChanged={setMenuOpen}
    items={Object.entries(actions).map(([key, entryProps]) => ({
      key,
      title: (
        <FlexRow gap={0.5} centered={true}>
          {entryProps.icon}
          {key}
        </FlexRow>
      ),
      onSelected: () => {
        entryProps.action()
        setMenuOpen(false)
      },
    }))}
  />
)

const ButtonFiller = styled.div`
  height: 21px;
  min-width: 21px;
  margin: 0 0.5rem;
`

export const HoveredDropdown = ({ enabled, parentRef, actions }: DotsDropdownProps) => {
  const hovered = useHover(parentRef)
  const [menuOpen, setMenuOpen] = useState(false)
  if (!enabled || !(hovered || menuOpen)) {
    return <ButtonFiller />
  }
  return <ActionsMenu setMenuOpen={setMenuOpen} actions={actions} />
}
