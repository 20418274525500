/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErrorReport } from '../models/ErrorReport';
import type { PresignedUploadUrl } from '../models/PresignedUploadUrl';
import type { UrgencyLevel } from '../models/UrgencyLevel';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SupportService {
    /**
     * Send an error report
     * @returns void
     * @throws ApiError
     */
    public static srcHandlersSupportErrorReport({
        requestBody,
    }: {
        requestBody: ErrorReport,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/support/error',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get a presigned url that will be used to upload a support bundle
     * @returns PresignedUploadUrl Presigned URL is returned
     * @throws ApiError
     */
    public static srcHandlersSupportGetSupportBundleUploadLink({
        repoId,
        message = '',
        urgency,
    }: {
        /**
         * The repo ID of the repository
         */
        repoId?: string,
        /**
         * A description of the support issue
         */
        message?: string,
        /**
         * The urgency level of the support issue
         */
        urgency?: UrgencyLevel,
    }): CancelablePromise<PresignedUploadUrl> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/support/bundle/upload',
            query: {
                'repo_id': repoId,
                'message': message,
                'urgency': urgency,
            },
        });
    }
}
