import { BlobUploadUrl, FileMutationService, StorageBackend, StorageUri } from './coreapi'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { postWithProgressAsync } from './fetcher'
// @ts-ignore
import { CalculateFileSha } from '../hooks/useFileShaWorker'
import { gzip } from 'pako'

export const uploadFileToRepoAsync = async (
  repoId: string,
  blob: Blob,
  worker: CalculateFileSha,
  onProgress: (progressPercentage: number) => void
): Promise<{ storage_backend: StorageBackend; storage_uri: StorageUri; sha1: string; size: number }> => {
  const sha = await worker(blob)
  return await uploadToRepoAsync(repoId, sha, blob, blob.size, onProgress)
}

async function compressIfNeeded(contentEncoding: string | undefined, contents: Blob) {
  if (contentEncoding === 'gzip') {
    // server requires the file to be compressed with gzip
    const arrayBuffer = await contents.arrayBuffer()
    const compressed = gzip(new Uint8Array(arrayBuffer))
    return new Blob([compressed], { type: contents.type })
  }
  return contents
}

async function uploadToRepoAsync(
  repoId: string,
  sha: string,
  contents: Blob,
  size: number,
  onProgress?: (progressPercentage: number) => void
) {
  const uploadInfo = (await FileMutationService.srcHandlersv2FilesGetBlobUploadLink({
    repoId,
    sha1: sha,
    compressionAccepted: true,
  })) as BlobUploadUrl
  const uploadUrl = uploadInfo.upload_url?.url
  if (!isEmpty(uploadUrl)) {
    const formData = new FormData()
    const fields = uploadInfo.upload_url!.fields
    Object.keys(fields).forEach((field) => {
      formData.append(field, get(fields, field))
    })
    contents = await compressIfNeeded(fields['content-encoding'], contents)
    formData.append('file', contents)
    await postWithProgressAsync(uploadUrl!, {}, formData, onProgress || (() => {}))
  }
  return { ...uploadInfo.location, sha1: sha, size: size }
}
