import { sentry } from '../env/sentry'
import { formatDate } from './dateUtils'
import { ApiError } from '../api/coreapi'

const getPrefix = () => `diversion-app | ${formatDate(new Date(), false, true)} |`

const convertParams = (params: any[]) =>
  params.map((p) => (p?.error instanceof ApiError ? JSON.parse(JSON.stringify(p)) : p))

export const log = {
  info: (message?: any, ...optionalParams: any[]) =>
    console.log(getPrefix(), message, ...convertParams(optionalParams)),
  warn: (message?: any, ...optionalParams: any[]) =>
    console.error(getPrefix(), message, ...convertParams(optionalParams)),
  error: (message?: any, ...optionalParams: any[]) => {
    sentry.captureMessage(message, {}, { data: optionalParams })
    console.error(getPrefix(), message, ...convertParams(optionalParams))
  },
}
