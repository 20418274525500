import { Outlet, useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { useWorkspace } from '../../hooks/api/useWorkspace'
import { useUrlState } from '../../hooks/useUrlState'
import { log } from '../../utils/log'
import { routeToRepo } from '../../Routes'
import { infoToast } from '../../utils/toast'
import { useWorkspaceRevisionUpdater, WorkspaceRevisionContext } from './useWorkspaceRevisionUpdater'

export const WorkspaceRoute = () => {
  const navigate = useNavigate()
  const { repoId, workspaceId } = useUrlState()
  const { workspace, workspaceLoading, failed } = useWorkspace(repoId, workspaceId)
  const [workspaceRevision, setWorkspaceRevision] = useState<number>()

  const { refresh } = useWorkspaceRevisionUpdater(repoId, workspace?.workspace_id, setWorkspaceRevision)

  useEffect(() => {
    if (repoId && workspaceId && !workspaceLoading && !workspace && !failed) {
      log.info('Workspace not found, redirecting back to repo page', workspaceId)
      infoToast('Workspace not found')
      navigate(routeToRepo(repoId), { replace: true })
    }
  }, [navigate, repoId, workspaceId, workspace, workspaceLoading, failed])

  return (
    <WorkspaceRevisionContext.Provider value={{ workspaceRevision, refresh }}>
      <Outlet />
    </WorkspaceRevisionContext.Provider>
  )
}
