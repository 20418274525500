import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { log } from '../../utils/log'
import { infoToast } from '../../utils/toast'
import { FileState } from '../../models/fileState'

export const useVerifyPathOnServer = (file: FileState | undefined, baseFile: FileState | undefined) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    if (file?.existsOnServer === false && baseFile?.existsOnServer === false) {
      const targetRoute = pathname.replace(`/${encodeURIComponent(file.filePath)}`, '').trimEnd()
      log.info('Path does not exist or is not a regular file, redirecting', {
        path: file.filePath,
        targetRoute,
      })
      infoToast('Path does not exist')
      navigate(targetRoute)
    }
  }, [baseFile?.existsOnServer, file?.existsOnServer, file?.filePath, navigate, pathname])
}
