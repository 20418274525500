import Alert from '@mui/material/Alert'
import { DefaultService, Notification, Tier } from '../../api/coreapi'
import styled from '@emotion/styled'
import { useUserInfo } from '../../hooks/api/useUserInfo'
import { useCallback, useState } from 'react'
import { SetUserTierDialog } from '../dialogs/SetUserTierDialog'
import { log } from '../../utils/log'
import { errorToast } from '../../utils/toast'
import { FlexRow } from '../base/Flex'

type NotificationMessageProps = {
  notification: Notification
  freeTierEligibilityOnClick: () => void
}

const StyledAlert = styled(Alert)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
})

const NotificationMessage = ({ notification, freeTierEligibilityOnClick }: NotificationMessageProps) => {
  // TODO(DIV-6149): Change the color of the alert based on the notification type (and handle types outside of severity)
  return (
    <StyledAlert severity="info" key={notification.id}>
      <FlexRow gap={0.5}>
        <FreeTierEligibility notification={notification} onClick={freeTierEligibilityOnClick} />
        {notification.message}
      </FlexRow>
    </StyledAlert>
  )
}

type NotificationBarProps = {
  notifications: Notification[]
  refreshNotification: () => Promise<void>
}
export const NotificationsBar = ({ notifications, refreshNotification }: NotificationBarProps) => {
  const [isUserTierDialogOpen, setIsUserTierDialogOpen] = useState<boolean>(false)
  const { refresh: refreshUserInfo } = useUserInfo()
  const onTierConfirmed = useCallback(
    async (tier: Tier) => {
      try {
        await DefaultService.srcHandlersv2AccountUpdateTier({
          requestBody: { tier },
        })
      } catch (e) {
        log.error('Failed to update user tier:', e)
        errorToast('Failed to update user tier. Please contact support.')
        return
      }
      try {
        await refreshUserInfo()
        await refreshNotification()
      } catch (e) {
        log.warn('Failed to refresh user info after updating tier:', e)
      }
    },
    [refreshNotification, refreshUserInfo]
  )

  return (
    <>
      {notifications.map((notification) => (
        <NotificationMessage
          key={notification.id}
          notification={notification}
          freeTierEligibilityOnClick={() => setIsUserTierDialogOpen(true)}
        />
      ))}
      <SetUserTierDialog isOpen={isUserTierDialogOpen} setOpen={setIsUserTierDialogOpen} onConfirm={onTierConfirmed} />
    </>
  )
}

const StyledLink = styled.div`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
  user-select: none;
`
const FreeTierEligibility = ({ notification, onClick }: { notification: Notification; onClick: () => void }) => {
  const { data: userInfo } = useUserInfo()
  const hasUnknownTier = userInfo?.tier === Tier.UNKNOWN
  const isSubscriptionOwnerNotification = notification.type === Notification.type.SUBSCRIPTION_OWNER
  const shouldShowFreeTierEligibility = hasUnknownTier && isSubscriptionOwnerNotification
  return (
    shouldShowFreeTierEligibility && (
      <FlexRow gap={0.2}>
        Eligible for free tier? <StyledLink onClick={onClick}>Click here.</StyledLink>{' '}
      </FlexRow>
    )
  )
}
