import { Link } from 'react-router-dom'
import { routeToWorkspaceEdit } from '../../Routes'
import UndoIcon from '@mui/icons-material/Undo'
import styled from '@emotion/styled'
import { Styleable } from '../../theme'
import { Ellipsis } from '../base/Ellipsis'

type Props = Styleable & {
  repoId: string
  workspaceId: string
}

const StyledLink = styled(Link)`
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.header.border};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  align-content: center;
  margin: 0 1rem;
  ${Ellipsis};
`

const Text = styled.span`
  margin-left: 0.5rem;
`

export const ToWorkspace = ({ className, repoId, workspaceId }: Props) => {
  const text = 'Back to Workspace'
  return (
    <StyledLink to={routeToWorkspaceEdit(repoId, workspaceId)} title={text} className={className}>
      <UndoIcon />
      <Text>{text}</Text>
    </StyledLink>
  )
}
