/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';
import type { NewRepo } from '../models/NewRepo';
import type { Repo } from '../models/Repo';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RepositoryManagementService {
    /**
     * Create a repo
     * Create an empty repo
     * @returns Error An error occurred
     * @returns Repo The newly created repo object
     * @throws ApiError
     */
    public static srcHandlersv2RepoPost({
        requestBody,
    }: {
        requestBody: (NewRepo & {
            branchless?: boolean;
            use_existing_dvignore?: boolean;
        }),
    }): CancelablePromise<Error | Repo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Repo with the same name already exists`,
            },
        });
    }
    /**
     * List user's repositories
     * Get a list of repositories accessibly by the authenticated user
     * @returns any Success
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2RepoListAll({
        owned,
    }: {
        /**
         * If specified, filters repositories by ownership of the authenticated user. True would mean only repos they own will be returned. False would mean only repos they collaborate on will be returned.
         *
         */
        owned?: boolean,
    }): CancelablePromise<{
        object: 'Repo';
        items: Array<Repo>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos',
            query: {
                'owned': owned,
            },
        });
    }
}
