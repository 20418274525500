import { useCallback, useEffect, useState } from 'react'
import { filterBy } from '../utils/objectUtil'

export const useFiltered = <T>(
  items: T[],
  itemFilterableProperties: (item: T) => string[]
): [T[], (query: string) => void] => {
  const [filteredItems, setFilteredItems] = useState(items)

  const filter = useCallback(
    (query: string) => setFilteredItems(filterBy(items, itemFilterableProperties, query)),
    [items, itemFilterableProperties]
  )

  useEffect(() => {
    setFilteredItems(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)])

  return [filteredItems, filter]
}
