import { AnalyticsEvent, AnalyticsEventProperty } from '../models/Analytics'

export const rawReplace = (to: string) => to !== window.location.pathname && window.location.replace(to)
export const rawNavigate = (to: string) => to !== window.location.pathname && window.location.assign(to)
export const rawReload = () => window.location.reload()
export const openInNewTab = (
  to: string,
  postAnalytics: (event: AnalyticsEvent, properties: Partial<Record<AnalyticsEventProperty, string>>) => void
) => {
  postAnalytics('ExternalNavigation', { external_url: to })
  const newWindow = window.open(to, '_blank', 'noopener,noreferrer')
  if (newWindow) {
    newWindow.opener = null
  }
}
