import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

const LOAD_DURATION_MS = 1000

export const useDelayedLoad = (onLoaded?: () => void) => {
  const { pathname } = useLocation()
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(false)
    const id = setTimeout(() => {
      if (onLoaded) {
        onLoaded()
      }
      setLoaded(true)
    }, LOAD_DURATION_MS)
    return () => clearTimeout(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  return loaded
}
