import { LinkWithDisable } from '../../base/LinkWithDisable'
import styled from '@emotion/styled'

export type TabProps = {
  selected: boolean
  title: string
  redirectTo: string
  position: number
}

const StyledLink = styled(LinkWithDisable)`
  flex: 1 1 0;
`

const Text = styled.div<{ selected: boolean; isFirstTab: boolean }>`
  padding: 0.75rem 0;
  text-align: center;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.blue.hover : theme.colors.white.primary)};
  color: ${({ theme, selected }) => (selected ? theme.colors.blue.primary : theme.colors.black.secondary)};
  font-weight: ${({ selected }) => (selected ? 500 : 300)};
  border-left: ${({ isFirstTab }) => (isFirstTab ? 0 : 1)}px solid ${({ theme }) => theme.colors.stroke};
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
`

export const Tab = ({ title, selected, redirectTo, position }: TabProps) => {
  return (
    <StyledLink to={redirectTo} disabled={selected}>
      <Text selected={selected} isFirstTab={position === 0}>
        {title}
      </Text>
    </StyledLink>
  )
}
