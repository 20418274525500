import styled from '@emotion/styled'
import { TextBig } from '../base/TextStyle'
import React from 'react'
import { FlexColumn, FlexRow } from '../base/Flex'
import { Separator, VerticalSeparator } from '../base/Separator'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { routeToApiTokens, routeToWebhooks } from '../../Routes'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const Page = styled.div`
  padding: ${({ theme }) => theme.padding.xl}rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: ${({ theme }) => theme.colors.black.primary};
  width: 100%;
`

const Title = styled.div`
  ${TextBig};
  color: ${({ theme }) => theme.colors.black.primary};
`

const TabsColumn = styled(FlexColumn)`
  width: 12rem;
  gap: 1rem;
`

const TabTitle = styled.div<{ active: boolean }>`
  color: ${({ theme }) => theme.colors.blue.primary};
  font-weight: ${({ active }) => (active ? 600 : 400)};
  cursor: pointer;
`

export const IntegrationsRoute = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  return (
    <Container>
      <Page>
        <FlexRow gap={2}>
          <TabsColumn>
            <Title>Integrations Settings</Title>
            <Separator />
            <TabTitle active={pathname === routeToApiTokens()} onClick={() => navigate(routeToApiTokens())}>
              API Tokens
            </TabTitle>
            <TabTitle active={pathname === routeToWebhooks()} onClick={() => navigate(routeToWebhooks())}>
              Webhooks
            </TabTitle>
          </TabsColumn>
          <VerticalSeparator />
          <Outlet />
        </FlexRow>
      </Page>
    </Container>
  )
}
