/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';
import type { RecommendationRequest } from '../models/RecommendationRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RecommendationsService {
    /**
     * Send recommendations form a user to his friends
     * @returns any Request completed successfully
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2RecommendationSendRecommendation({
        requestBody,
    }: {
        requestBody: RecommendationRequest,
    }): CancelablePromise<any | Error> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/recommendation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The request does not meet the required conditions`,
            },
        });
    }
}
