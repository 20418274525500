/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommitMessage } from '../models/CommitMessage';
import type { DetailedMerge } from '../models/DetailedMerge';
import type { Error } from '../models/Error';
import type { FileMode } from '../models/FileMode';
import type { Merge } from '../models/Merge';
import type { MergeId } from '../models/MergeId';
import type { NewResourceId } from '../models/NewResourceId';
import type { StorageBackend } from '../models/StorageBackend';
import type { StorageUri } from '../models/StorageUri';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RepositoryMergeManipulationService {
    /**
     * Details of a specific merge in progress
     * @returns DetailedMerge An object describing the merge.
     * @throws ApiError
     */
    public static srcHandlersv2MergeGetOpenMerge({
        repoId,
        mergeId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a merge attempt
         */
        mergeId: string,
    }): CancelablePromise<DetailedMerge> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/merges/{merge_id}',
            path: {
                'repo_id': repoId,
                'merge_id': mergeId,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * After all conflicts are resolved, finalizes the merge with a commit
     * @returns any Request completed successfully
     * @returns NewResourceId Resource created
     * @throws ApiError
     */
    public static srcHandlersv2MergeFinalize({
        repoId,
        mergeId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a merge attempt
         */
        mergeId: string,
        requestBody?: CommitMessage,
    }): CancelablePromise<any | NewResourceId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/merges/{merge_id}',
            path: {
                'repo_id': repoId,
                'merge_id': mergeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized`,
                404: `The specified resource was not found`,
                405: `An error occurred`,
                503: `Service unavailable`,
            },
        });
    }
    /**
     * Close an open merge without resolving it
     * @returns any Request completed successfully
     * @throws ApiError
     */
    public static srcHandlersv2MergeCloseMerge({
        repoId,
        mergeId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a merge attempt
         */
        mergeId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/merges/{merge_id}/close',
            path: {
                'repo_id': repoId,
                'merge_id': mergeId,
            },
            errors: {
                403: `Unauthorized`,
                404: `The specified resource was not found`,
                503: `Service unavailable`,
            },
        });
    }
    /**
     * Accepts a change from one of the branches (base or other)
     * @returns any Request completed successfully
     * @throws ApiError
     */
    public static srcHandlersv2MergeAcceptSide({
        repoId,
        mergeId,
        conflictId,
        indexId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a merge attempt
         */
        mergeId: string,
        /**
         * An identifier of a conflict retrieved in conflicts property of GET /repos/{repo_id}/merges/{merge_id}
         */
        conflictId: string,
        /**
         * An identifier of a conflict index retrieved in conflicts property of GET /repos/{repo_id}/merges/{merge_id}
         */
        indexId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/merges/{merge_id}/conflicts/{conflict_id}/accept',
            path: {
                'repo_id': repoId,
                'merge_id': mergeId,
                'conflict_id': conflictId,
            },
            query: {
                'index_id': indexId,
            },
        });
    }
    /**
     * Update a conflicting file, potentially resolving the conflict.
     * @returns Error An error occurred
     * @returns any Request completed successfully
     * @throws ApiError
     */
    public static srcHandlersv2MergeSetResult({
        repoId,
        mergeId,
        conflictId,
        mode,
        requestBody,
        size,
        sha1,
        storageBackend,
        storageUri,
        path,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a merge attempt
         */
        mergeId: string,
        /**
         * An identifier of a conflict retrieved in conflicts property of GET /repos/{repo_id}/merges/{merge_id}
         */
        conflictId: string,
        /**
         * The file mode (as Unix mode)
         */
        mode: FileMode,
        requestBody: Blob,
        /**
         * Blob size in bytes
         */
        size?: number,
        /**
         * A sha1 hexdigest
         */
        sha1?: string,
        /**
         * An optional storage type for async upload.
         */
        storageBackend?: StorageBackend,
        /**
         * An optional storage uri to be sent along storage_backend.
         */
        storageUri?: StorageUri,
        /**
         * Updates the path of the file with the value passed here. Can be used to resolve path conflicts.
         */
        path?: string,
    }): CancelablePromise<Error | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/merges/{merge_id}/conflicts/{conflict_id}',
            path: {
                'repo_id': repoId,
                'merge_id': mergeId,
                'conflict_id': conflictId,
            },
            query: {
                'size': size,
                'sha1': sha1,
                'storage_backend': storageBackend,
                'storage_uri': storageUri,
                'mode': mode,
                'path': path,
            },
            body: requestBody,
            mediaType: 'application/octet-stream',
        });
    }
    /**
     * Retrieve conflicted merges in this repo
     * @returns any Success
     * @throws ApiError
     */
    public static srcHandlersv2MergeListOpenMerges({
        repoId,
        baseId,
        otherId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A reference to a base unto which changes will be applied
         */
        baseId?: string,
        /**
         * A reference to a source version from which changes will be taken
         */
        otherId?: string,
    }): CancelablePromise<{
        object: 'Merge';
        items: Array<Merge>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/merges',
            path: {
                'repo_id': repoId,
            },
            query: {
                'base_id': baseId,
                'other_id': otherId,
            },
        });
    }
    /**
     * Merge ref into a branch
     * @returns any Request completed successfully
     * @returns NewResourceId The merge into a branch was completed successfully, and the response contains the new commit ID
     * @returns MergeId The merge is in progress. It has merge conflicts requiring active input from the user for conflict resolution. The response contains a merge ID, which can be used for querying /repos/{repo_id}/merges/{merge_id}
     * @throws ApiError
     */
    public static srcHandlersv2MergePost({
        repoId,
        baseId,
        otherId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A reference to a base unto which changes will be applied
         */
        baseId?: string,
        /**
         * A reference to a source version from which changes will be taken
         */
        otherId?: string,
    }): CancelablePromise<any | NewResourceId | MergeId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/merges',
            path: {
                'repo_id': repoId,
            },
            query: {
                'base_id': baseId,
                'other_id': otherId,
            },
            errors: {
                412: `The specified resource is no longer up to date`,
                503: `Service unavailable`,
            },
        });
    }
    /**
     * Preview Merging two branches and request a review
     * @returns any A URL to a webapp page showing the incoming changes in the future merge.
     * @throws ApiError
     */
    public static srcHandlersv2MergeMergeRequest({
        repoId,
        baseId,
        otherId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A reference to a base unto which changes will be applied
         */
        baseId?: string,
        /**
         * A reference to a source version from which changes will be taken
         */
        otherId?: string,
    }): CancelablePromise<{
        url?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/merge_request',
            path: {
                'repo_id': repoId,
            },
            query: {
                'base_id': baseId,
                'other_id': otherId,
            },
            errors: {
                400: `The request does not meet the required conditions`,
            },
        });
    }
}
