import { Outlet } from 'react-router'
import { useUrlState } from '../../hooks/useUrlState'
import { CommitsHistorySidePanel } from '../sidepanel/CommitsHistorySidePanel'

export const CommitHistoryRoute = () => {
  const { repoId, branchId, commitId, workspaceId } = useUrlState()
  return (
    <>
      <CommitsHistorySidePanel
        repoId={repoId!}
        branchId={branchId}
        commitId={commitId}
        workspaceId={workspaceId}
        hasOutletContent
      />
      <Outlet />
    </>
  )
}
