import { useItemListApi } from '../useApi'
import { Branch, RepositoryBranchManipulationService } from '../../api/coreapi'
import { useMemo } from 'react'
import isNil from 'lodash/isNil'
import { sortBy } from 'lodash'
import { BranchEx } from '../../models/BranchEx'
import { useRepo } from './useRepo'
import { groupBy } from '../../utils/objectUtil'

const useBranches = (repoId: string | undefined) => {
  const { data, loading, refresh } = useItemListApi<Branch>(
    ['repos', repoId, 'branches'],
    repoId
      ? () =>
          RepositoryBranchManipulationService.srcHandlersv2BranchListAll({
            repoId,
          })
      : null
  )
  return { branches: data, branchesLoading: loading, refresh }
}

const useBranchesEx = (repoId: string) => {
  const { repo, repoLoading } = useRepo(repoId)
  const { branches, branchesLoading } = useBranches(repoId)
  const sortedBranches: BranchEx[] | undefined = useMemo(() => {
    if (isNil(branches)) {
      return undefined
    }
    const defaultBranchId = repo?.default_branch_id
    return sortBy(
      branches!.map((branch) => ({ ...branch, repoId, isDefault: branch.branch_id === defaultBranchId })),
      (branch) => [!branch.isDefault, branch.branch_name.toLowerCase()]
    )
  }, [branches, repo?.default_branch_id, repoId])

  return { branches: sortedBranches, loading: repoLoading || branchesLoading }
}

export const useBranchesById = (repoId: string) => {
  const { branches, loading } = useBranchesEx(repoId)
  const branchesById = useMemo(
    () => (branches ? groupBy<BranchEx, BranchEx>(branches, (b) => b.branch_id) : {}),
    [branches]
  )
  return { branchesById, loading }
}

export const useRefreshBranches = (repoId: string | undefined) => {
  const { refresh } = useBranches(repoId)
  return refresh
}
