import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

export const signInAsync = async (username: string, password: string) => await Auth.signIn(username, password)

export const signInWithGoogleAsync = async (state?: string) =>
  await Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState: state || window.location.origin,
  })

export const signUpAsync = async (username: string, password: string, email: string) =>
  await Auth.signUp({ username, password, attributes: { email }, autoSignIn: { enabled: true } })

export const resetPasswordAsync = async (username: string) => await Auth.forgotPassword(username)

export const resetPasswordVerifyAsync = async (username: string, code: string, newPassword: string) =>
  await Auth.forgotPasswordSubmit(username, code, newPassword)

export const signUpVerifyAsync = async (username: string, code: string) => await Auth.confirmSignUp(username, code)

export const signUpResendCodeAsync = async (username: string) => await Auth.resendSignUp(username)
