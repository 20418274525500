import * as React from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { ViewChangesSwitch } from '../../base/ViewChangesSwitch'
import { WorkspaceTopBar } from './WorkspaceTopBar'
import { Loader } from '../../base/Loader'
import { Z_INDEX_TOPMOST } from '../../../theme'
import { appendFilePath, routeToWorkspaceEdit } from '../../../Routes'
import { Separator } from '../../base/Separator'
import { DirStructureTreeView, ReloadIfHasUnloadedChild } from '../../tree/DirStructureTreeView'
import isEmpty from 'lodash/isEmpty'
import { useSessionStorage } from 'usehooks-ts'
import { useTreeData } from '../../../hooks/api/useTreeData'
import { Tree } from '../../../models/Tree'
import { WorkspaceRevisionContext } from '../../workspace/useWorkspaceRevisionUpdater'
import { useTreeSearch } from '../../../hooks/api/useTreeSearch'
import debounce from 'lodash/debounce'
import { TreeViewOptions } from '../../tree/TreeViewOptions'
import { FlatDirsView } from '../../tree/FlatDirsView'
import { useWorkspace } from '../../../hooks/api/useWorkspace'
import { FlexColumn, FlexFiller, FlexRowStyle } from '../../base/Flex'
import { WorkspaceFileUpload } from './upload/WorkspaceFileUpload'
import { useDropzone } from 'react-dropzone'
import { TextTitle } from '../../base/TextStyle'
import { useAnalytics } from '../../../hooks/api/useAnalytics'
import { useOtherRefsStatus } from '../../../hooks/api/useOtherRefsStatus'
import { log } from '../../../utils/log'
import isNil from 'lodash/isNil'
import { WorkspaceCreateDir } from './WorkspaceCreateDir'
import compact from 'lodash/compact'
import { useBranch } from '../../../hooks/api/useBranch'
import Split from 'react-split'
import { FileEntry } from '../../../api/coreapi'
import { useUploadingAgents } from '../../../hooks/api/useUploadingAgents'
import { pluralize } from '../../../utils/textUtils'
import { IsDesktopApp } from '../../../desktop/components/utils/DesktopAppApi'
import { useWorkspaceStatus } from '../../../hooks/api/useWorkspaceStatus'
import WarningIcon from '@mui/icons-material/Warning'
import {
  getFastUploadActionName,
  useOpenLocalWorkspaceFolderItem,
} from '../../../desktop/hooks/useOpenLocalWorkspaceFolderItem'
import { Tooltip } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { errorToast } from '../../../utils/toast'
import { ActionValidationContext, ActionValidationState } from './actionValidationContext'
import { FileOpsActionContext, useFileOpsActions } from './useFileOpsActions'
import { WarningContainer } from '../../base/WarningContainer'
import { CommitMessageSection } from './CommitMessageSection'
import { useCommits } from '../../../hooks/api/useCommits'
import { ResetDialog } from './reset/ResetDialog'
import { WorkspaceStatusBarUpdater } from './WorkspaceStatusBarUpdater'
import { TriggerRefreshAgent } from '../../../desktop/components/utils/refreshAgent'

type Props = {
  repoId: string
  workspaceId: string
  selectedFilePath?: string
}

const Container = styled(FlexColumn)<{ canCommit: boolean }>`
  min-height: 0;
  flex-grow: 1;
`

const StyledSplit = styled(Split)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > .gutter {
    background-color: ${({ theme }) => theme.colors.white.secondary};
    background-repeat: no-repeat;
    background-position: 50%;
  }

  & > .gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
  }
  min-height: 0;
`

const UpperRowLabel = ({
  allChecked,
  checkedFilesCount,
}: {
  allChecked: boolean | null
  checkedFilesCount: number
}) => {
  if (allChecked === true) {
    return <div>All paths checked</div>
  }
  if (allChecked === false) {
    return <div>No paths checked</div>
  }
  return <div>{`${checkedFilesCount} ${pluralize(checkedFilesCount, 'path', 's', false)} checked`}</div>
}

const getCheckableFirstLevelKeys = (tree?: Tree) =>
  tree?.root.children?.filter((node) => !node.disableCheckbox).map((node) => node.key) || []

const getListTreeViewKeys = (items?: FileEntry[]) => {
  if (isNil(items)) {
    return []
  }
  return items.map((i) => i.path)
}

const getCheckableKeys = (tree?: Tree) =>
  Object.values(tree?.nodeByKey || {})
    .filter((node) => !node.disableCheckbox)
    .map((node) => node.key)

const useRefreshOnWorkspaceRevisionUpdated = (refresh: () => void) => {
  const [lastSawRevision, setLastSawRevision] = useState<number>()
  const { workspaceRevision } = useContext(WorkspaceRevisionContext)
  useEffect(() => {
    if (lastSawRevision && workspaceRevision && lastSawRevision !== workspaceRevision) {
      // We want refresh our page only when we see a new revision and don't refresh in the initialization since we already build all the data at this state
      refresh()
    }
    if (workspaceRevision !== lastSawRevision) {
      setLastSawRevision(workspaceRevision)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceRevision, lastSawRevision])
}

const TreeControls = styled.div`
  ${FlexRowStyle};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`

const StyledFileUpload = styled(WorkspaceFileUpload)`
  margin-left: 1rem;
`

const StyledCreateDir = styled(WorkspaceCreateDir)`
  margin: 0 1rem;
`

const StyledOpenExplorerIcon = styled(LaunchIcon)`
  cursor: pointer;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.blue.contrastText};
  background-color: ${({ theme }) => theme.colors.blue.primary};
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.padding.s}rem;
`

const DragFilesOverlay = styled.div`
  height: 100%;
  width: inherit;
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blue.hover};
  z-index: ${Z_INDEX_TOPMOST};
`

const DragFilesMessage = styled.div`
  ${TextTitle};
  height: fit-content;
  padding: ${({ theme }) => theme.padding.l}rem;
  border-radius: 0.5rem;
  margin-top: 6rem;
  color: ${({ theme }) => theme.colors.blue.primary};
  background-color: ${({ theme }) => theme.colors.white.primary};
  box-shadow: 0 0 4px ${({ theme }) => theme.colors.white.primary};
`

const WORKSPACE_IS_CLEAN_MESSAGE = 'Workspace is clean'
const maxDepthToFetchInTree = 2
export const WorkspacePanel = ({ repoId, workspaceId, selectedFilePath }: Props) => {
  const postAnalytics = useAnalytics()
  const [treeViewOption, setTreeViewOption] = useSessionStorage<TreeViewOptions>('workspace.treeView', 'all')
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const { workspace, refresh: refreshWorkspaceInstance } = useWorkspace(repoId, workspaceId)
  const canCommit = !isEmpty(workspace?.branch_id)
  const [checkedPathsCount, setCheckedPathsCount] = useState<number>(0) // might not be valid count if all checkbox was triggered
  const [actionValidationState, setActionValidationState] = useState(ActionValidationState.ReadyForAction)
  const { doAction, dialogs: ActionDialogs } = useFileOpsActions(repoId!, workspaceId!)
  const {
    treeData,
    loading,
    onExpandNodeAsync,
    refresh: refreshTree,
    isNewTree,
    frequentUploadMode,
  } = useTreeData(
    repoId,
    workspaceId,
    undefined,
    {},
    maxDepthToFetchInTree,
    workspace?.branch_id || workspace?.base_commit_id
  )
  const {
    data: changesData,
    loading: changesLoading,
    refresh: refreshChanges,
  } = useWorkspaceStatus('TreeData', repoId, workspaceId)
  useRefreshOnWorkspaceRevisionUpdated(() => {
    log.info('workspace revision updated')
    refreshWorkspaceInstance()
    refreshTree()
    refreshChanges()
  })
  const [resetDialogIsOpen, setResetDialogIsOpen] = useState(false)
  const [query, setQuery] = useState<string>()
  const { data: searchResultKeys, loading: searchLoading } = useTreeSearch(repoId, workspaceId, query)
  const checkableKeys = useMemo(
    () =>
      treeViewOption === 'list_changes'
        ? getListTreeViewKeys(changesData?.items)
        : getCheckableFirstLevelKeys(treeData),
    [treeData, changesData, treeViewOption]
  )
  const allChecked = useMemo(
    () => (isEmpty(checkedKeys) ? false : checkableKeys.every((key) => checkedKeys.includes(key)) ? true : null),
    [checkableKeys, checkedKeys]
  )
  const { refresh: refreshBranch } = useBranch(repoId, workspace?.branch_id)
  const { refresh: refreshCommits } = useCommits(repoId!!, workspace?.branch_id || workspace?.base_commit_id, 5)
  const cleanWorkspace = useCallback(
    (clearChecked: boolean = true) => {
      log.info('cleaning workspace state', { clearChecked })
      setActionValidationState(ActionValidationState.PostAction)
      refreshTree().finally(() => {
        setActionValidationState(ActionValidationState.ReadyForAction)
      })
      refreshChanges()
      refreshBranch()
      if (clearChecked) {
        setCheckedKeys([])
        setCheckedPathsCount(0)
      }
      refreshCommits()
    },
    [refreshChanges, refreshTree, refreshBranch, refreshCommits]
  )
  useEffect(() => {
    const checkableKeysSet = new Set(getCheckableKeys(treeData))
    setCheckedKeys((keys) => keys.filter((key) => checkableKeysSet.has(key)))
  }, [treeData, treeViewOption])
  const workspaceEditRoute = useMemo(() => routeToWorkspaceEdit(repoId, workspaceId), [repoId, workspaceId])
  const { openLocalWorkspaceFolderItem } = useOpenLocalWorkspaceFolderItem()
  const { otherStatusesByPath, loadPath } = useOtherRefsStatus(repoId, workspaceId)
  const [createdDirPath, setCreatedDirPath] = useState<string>()
  const [droppedFiles, setDroppedFiles] = useState<File[]>([])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDrop: (files, rejections) => {
      if (rejections.length > 0) {
        return
      }
      postAnalytics('UploadFilesDropped', {
        repo_id: repoId,
        workspace_id: workspaceId,
        files_count: files.length.toString(),
      })
      setDroppedFiles(files)
    },
  })

  useEffect(() => {
    if (createdDirPath) {
      setTimeout(() => {
        setCreatedDirPath(undefined)
      }, 1000)
    }
  }, [createdDirPath])
  const { data: uploadingAgents } = useUploadingAgents(repoId, workspaceId)
  const isUploading = (uploadingAgents?.uploaders?.length || 0) > 0
  const reloadIfSelected = useCallback(ReloadIfHasUnloadedChild, [])
  const redirectRouteOnClick = useCallback(
    (path: string) => appendFilePath(workspaceEditRoute, path),
    [workspaceEditRoute]
  )
  const onChecked = useCallback((keys: string[]) => setCheckedKeys(keys), [])
  return (
    <ActionValidationContext.Provider value={{ actionValidationState, revalidateState: () => cleanWorkspace() }}>
      <ActionDialogs />
      <FileOpsActionContext.Provider value={{ doAction }}>
        <Container canCommit={canCommit} {...getRootProps({ className: 'dropzone' })}>
          {loading && isNil(treeData) ? (
            <Loader addPadding />
          ) : (
            <>
              <input {...getInputProps()} />
              <WorkspaceStatusBarUpdater
                repoId={repoId}
                workspaceId={workspaceId}
                needToForwardWorkspace={changesData?.hasConflict}
                isUploading={isUploading}
              />
              {frequentUploadMode && <MassUploadWarning />}
              <ResetDialog
                isOpen={resetDialogIsOpen}
                setOpen={setResetDialogIsOpen}
                resetAll={allChecked === true}
                filePaths={checkedKeys}
              />
              <WorkspaceTopBar
                upperRowLabel={<UpperRowLabel allChecked={allChecked} checkedFilesCount={checkedPathsCount} />}
                allChecked={allChecked}
                refreshingViewAfterAction={actionValidationState !== ActionValidationState.ReadyForAction}
                cascadedChangesCount={changesData?.cascadedChangesCount || 0}
                onResetClick={() => setResetDialogIsOpen(true)}
                onAllChecked={(checked) => {
                  if (checked) {
                    setCheckedKeys(checkableKeys)
                  } else {
                    setCheckedKeys([])
                  }
                }}
              />
              <Separator />
              <TreeControls>
                <ViewChangesSwitch selected={treeViewOption} setSelected={setTreeViewOption} />
                <FlexFiller />
                {IsDesktopApp() ? (
                  <Tooltip title={getFastUploadActionName()} arrow>
                    <StyledOpenExplorerIcon
                      onClick={async () => {
                        postAnalytics('FastUploadInExplorerClicked', {})
                        try {
                          await openLocalWorkspaceFolderItem(repoId, workspaceId)
                        } catch (e: any) {
                          const msg = 'Failed opening local workspace'
                          log.warn(msg, e)
                          errorToast(msg)
                        }
                      }}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
                <StyledFileUpload dragDroppedFiles={droppedFiles} clearDroppedFiles={() => setDroppedFiles([])} />
                <StyledCreateDir onCreated={setCreatedDirPath} />
              </TreeControls>
              <StyledSplit direction={'vertical'} sizes={[90, 10]}>
                <div style={{ minHeight: 0, overflow: 'clip' }}>
                  {treeViewOption === 'list_changes' ? (
                    <FlatDirsView
                      changedItems={changesData?.items}
                      changesLoading={changesLoading}
                      noContentLabel={WORKSPACE_IS_CLEAN_MESSAGE}
                      selectedNodeKey={selectedFilePath}
                      redirectRouteOnClick={redirectRouteOnClick}
                      checkedKeys={checkedKeys}
                      onChecked={onChecked}
                      setCheckedPathsCount={setCheckedPathsCount}
                      enableWorkspaceActions
                    />
                  ) : (
                    <DirStructureTreeView
                      treeId={workspaceId}
                      treeData={treeData!}
                      checkedKeys={checkedKeys}
                      onChecked={onChecked}
                      setCheckedPathsCount={setCheckedPathsCount}
                      noContentLabel={WORKSPACE_IS_CLEAN_MESSAGE}
                      redirectRouteOnClick={redirectRouteOnClick}
                      selectedNodeKey={selectedFilePath}
                      changedOnly={treeViewOption === 'tree_changes'}
                      onExpandNodeAsync={async (key) => {
                        loadPath(key)
                        return await onExpandNodeAsync(key)
                      }}
                      onSearch={debounce(setQuery)}
                      searchLoading={searchLoading}
                      searchResultKeys={searchResultKeys}
                      otherStatusesByPath={otherStatusesByPath}
                      expandHints={compact([selectedFilePath, createdDirPath])}
                      enableWorkspaceActions
                      loadOnSelectOrExpand={reloadIfSelected}
                      isNewTree={isNewTree}
                      minHeight={0}
                    />
                  )}
                </div>
                {canCommit && (
                  <div style={{ minHeight: '13rem', flexGrow: 1 }}>
                    <CommitMessageSection
                      workspaceId={workspaceId}
                      repoId={repoId}
                      checkedKeys={checkedKeys}
                      allChecked={allChecked}
                      afterCommit={() => {
                        cleanWorkspace()
                        TriggerRefreshAgent(workspaceId, repoId)
                      }}
                      disabled={
                        isUploading || changesLoading || actionValidationState !== ActionValidationState.ReadyForAction
                      }
                    />
                  </div>
                )}
              </StyledSplit>
            </>
          )}
          {isDragActive && (
            <DragFilesOverlay>
              <DragFilesMessage>Drop files to upload to workspace</DragFilesMessage>
            </DragFilesOverlay>
          )}
        </Container>
      </FileOpsActionContext.Provider>
    </ActionValidationContext.Provider>
  )
}

const MassUploadWarning = () => (
  <WarningContainer>
    <WarningIcon /> During uploads tree view might be outdated
  </WarningContainer>
)
