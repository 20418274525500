import { Checkbox as AntCheckbox } from 'antd'
import styled from '@emotion/styled'

type Props = {
  title: string
  checked: boolean | null
  setChecked: (checked: boolean) => void
  disabled?: boolean
}

const Wrapper = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.blue.primary};
    border-color: ${({ theme }) => theme.colors.blue.primary};
  }
`

export const Checkbox = ({ title, checked, setChecked, disabled }: Props) => (
  <Wrapper title={title}>
    <AntCheckbox
      checked={checked === true}
      disabled={disabled}
      indeterminate={checked === null}
      onChange={(event) => {
        setChecked(event.target.checked)
      }}
    />
  </Wrapper>
)
