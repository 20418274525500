import { UserInfo } from '../models/userInfo'

// @ts-ignore
const hubspotQueue = () => window._hsq || []

export const hubspotIdentifyUser = (user: UserInfo) => {
  const hsq = hubspotQueue()
  hsq.push(['identify', user])
  hsq.push(['trackPageView'])
}
