import DeleteIcon from '@mui/icons-material/DeleteSharp'
import { Tooltip } from '@mui/material'
import styled from '@emotion/styled'
import { useContext } from 'react'
import { FileOpsActionContext } from '../useFileOpsActions'

type Props = {
  filePath: string
}

const DeleteIconButton = styled.div`
  color: ${({ theme }) => theme.colors.red.contrastText};
  background-color: ${({ theme }) => theme.colors.red.primary};
  border-radius: 50%;
  padding: 0.3rem;
  cursor: pointer;
`

export const DeleteFileButton = ({ filePath }: Props) => {
  const { doAction } = useContext(FileOpsActionContext)
  return (
    <Tooltip title="Delete File" arrow>
      <DeleteIconButton onClick={() => doAction('delete', filePath)}>
        <DeleteIcon />
      </DeleteIconButton>
    </Tooltip>
  )
}
