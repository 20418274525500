import { Commit } from '../api/coreapi'
import first from 'lodash/first'
import isNil from 'lodash/isNil'

export const PLACEHOLDER_FOR_NO_PARENT = 'no-parent'

export const getCommitParentCommitId = (commit?: Commit): string | undefined => {
  if (isNil(commit)) {
    return undefined
  }
  return first(commit.parents) || PLACEHOLDER_FOR_NO_PARENT
}
