import { useCallback } from 'react'
import { FileMode, RepositoryMergeManipulationService } from '../../api/coreapi'
import { callAsync, OnError, SetLoading } from '../../utils/callAsync'
import { isMergeObsoleteError } from '../../utils/errorClassify'
import { handleObsoleteMerge } from '../../hooks/api/useMerge'
import { useNavigate } from 'react-router'
import { useFileShaWorker } from '../../hooks/useFileShaWorker'
import { uploadFileToRepoAsync } from '../../api/upload'

type OnResolved = () => void

export const usePostMergeCommitAsync = (
  repoId: string,
  mergeId: string,
  commitMessage: string,
  onResolved: OnResolved
) => {
  const navigate = useNavigate()
  return useCallback(async () => {
    try {
      await RepositoryMergeManipulationService.srcHandlersv2MergeFinalize({
        repoId,
        mergeId,
        requestBody: { commit_message: commitMessage },
      })
    } catch (e) {
      if (isMergeObsoleteError(e)) {
        handleObsoleteMerge(repoId, mergeId, navigate)
        return
      }
      throw e
    }
    onResolved()
  }, [commitMessage, mergeId, navigate, onResolved, repoId])
}

export const useResolveConflictSideAsync = (
  repoId: string,
  mergeId: string,
  onResolved: OnResolved,
  setLoading: SetLoading,
  onError: OnError
) => {
  const navigate = useNavigate()
  return useCallback(
    async (conflictId: string, indexId: string) => {
      await callAsync(
        async () => {
          try {
            await RepositoryMergeManipulationService.srcHandlersv2MergeAcceptSide({
              repoId,
              mergeId,
              indexId,
              conflictId,
            })
          } catch (e) {
            if (isMergeObsoleteError(e)) {
              handleObsoleteMerge(repoId, mergeId, navigate)
              return
            }
            throw e
          }
          onResolved()
        },
        setLoading,
        onError
      )
    },
    [mergeId, navigate, onError, onResolved, repoId, setLoading]
  )
}

export const useResolveConflictContentAsync = (
  repoId: string,
  mergeId: string,
  conflictId: string,
  onResolved: OnResolved,
  setLoading: SetLoading,
  onError: OnError
) => {
  const fileShaWorker = useFileShaWorker()
  const navigate = useNavigate()
  return useCallback(
    async (content: string, path: string, mode: FileMode) => {
      await callAsync(
        async () => {
          try {
            const blob = new Blob([content], { type: 'application/octet-stream' })
            const { storage_backend, storage_uri, sha1, size } = await uploadFileToRepoAsync(
              repoId,
              blob,
              fileShaWorker,
              () => {}
            )
            await RepositoryMergeManipulationService.srcHandlersv2MergeSetResult({
              repoId,
              mergeId,
              conflictId,
              path,
              mode,
              requestBody: new Blob(),
              size,
              sha1,
              storageBackend: storage_backend,
              storageUri: storage_uri,
            })
          } catch (e) {
            if (isMergeObsoleteError(e)) {
              handleObsoleteMerge(repoId, mergeId, navigate)
              return
            }
            throw e
          }
          onResolved()
        },
        setLoading,
        onError
      )
    },
    [conflictId, fileShaWorker, mergeId, navigate, onError, onResolved, repoId, setLoading]
  )
}
