import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Conflict } from '../../api/coreapi'
import { routeToMerge, routeToMergeConflict, routeToMerges } from '../../Routes'
import { Loader } from '../base/Loader'
import styled from '@emotion/styled'
import { BannerPadding } from '../base/PaddingStyle'
import { capitalize, joinWith, pluralize } from '../../utils/textUtils'
import { CommitMessage } from '../commitAction/CommitMessage'
import { usePostMergeCommitAsync, useResolveConflictSideAsync } from './useMergeResolve'
import { CommitButton } from '../commitAction/CommitButton'
import { useNavigate } from 'react-router'
import { FlexColumn, FlexFiller, FlexRow } from '../base/Flex'
import { useSessionStorage } from 'usehooks-ts'
import { TextSmall } from '../base/TextStyle'
import { CheckableSelectItem } from '../selector/SelectItem'
import { BASE_SIDE, getConflictTypes, OTHER_SIDE } from '../../utils/conflictUtils'
import ConflictIcon from '@mui/icons-material/Error'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import { css } from '@emotion/react'
import { getFileName, getParentPath } from '../../utils/pathUtils'
import { Ellipsis } from '../base/Ellipsis'
import { isMergeIntoWorkspace, useMerge } from '../../hooks/api/useMerge'
import { MergeCloseButton } from './MergeCloseButton'
import { log } from '../../utils/log'
import { infoToast } from '../../utils/toast'
import { PublishApiErrorContext } from '../../App'
import { Checkbox } from '../base/Checkbox'
import { SmallButton } from '../base/PrimaryButton'

const IconStyle = css`
  font-size: 1.2rem;
  margin-top: 0.125rem;
  background: white;
  border-radius: 50%;
`

const StyledConflictIcon = styled(ConflictIcon)`
  ${IconStyle};
  color: ${({ theme }) => theme.colors.red.primary};
`

const StyledBaseIcon = styled(ArrowForwardIcon)`
  ${IconStyle};
  color: ${({ theme }) => theme.colors.blue.primary};
`

const StyledOtherIcon = styled(ArrowBackIcon)`
  ${IconStyle};
  color: ${({ theme }) => theme.colors.blue.primary};
`

const StyledCombinedIcon = styled(CompareArrowsIcon)`
  ${IconStyle};
  color: ${({ theme }) => theme.colors.blue.primary};
`

type Props = {
  repoId: string
  mergeId: string
  selectedConflictId?: string
}

const Container = styled(FlexColumn)`
  height: 100%;
  padding-bottom: 8rem;
  border-right: 1px solid ${({ theme }) => theme.colors.stroke};
  gap: 1rem;
`

const Title = styled(FlexRow)`
  ${BannerPadding};
  ${TextSmall};
  color: ${({ theme }) => theme.colors.black.primary};
  background-color: ${({ theme }) => theme.colors.blue.hover};
  gap: 1rem;
`

const StyledCommitButton = styled(CommitButton)`
  margin: 0 1rem;
`

const ConflictTitleRow = styled(FlexRow)`
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`

const ItemPathComponent = styled(FlexColumn)`
  max-width: calc(100% - 1rem);
`

const ItemInsight = styled.div<{ selected: boolean }>`
  ${Ellipsis};
  ${TextSmall};
  color: ${({ theme, selected }) => (selected ? theme.colors.black.primary : theme.colors.black.secondary)};
`

const ItemName = styled.div`
  ${Ellipsis};
`

const ConflictLabel = styled.span<{ selected: boolean }>`
  padding-right: ${({ theme }) => theme.padding.l}rem;
  color: ${({ theme, selected }) => (selected ? theme.colors.white.secondary : theme.colors.red.dark)};
`

const getConflictIcon = (conflict: Conflict) => {
  if (conflict.is_resolved) {
    switch (conflict.resolved_side) {
      case Conflict.resolved_side.BASE:
        return <StyledBaseIcon />
      case Conflict.resolved_side.OTHER:
        return <StyledOtherIcon />
      default:
        return <StyledCombinedIcon />
    }
  } else {
    return <StyledConflictIcon />
  }
}

const ConflictTitle = ({ conflict, selected }: { conflict: Conflict; selected: boolean }) => {
  const basePath = conflict.base.path!
  const parentPath = getParentPath(basePath)
  const name = getFileName(basePath)
  const conflictLabel = capitalize(joinWith(', ', ...getConflictTypes(conflict)))
  return (
    <ConflictTitleRow title={conflict.is_resolved ? 'Resolved' : conflictLabel}>
      {getConflictIcon(conflict)}
      <ItemPathComponent title={`${conflictLabel}: ${basePath}`}>
        <ItemInsight selected={selected}>
          <ConflictLabel selected={selected}>{conflictLabel}</ConflictLabel>
        </ItemInsight>
        {parentPath && <ItemInsight selected={selected}>{parentPath}/</ItemInsight>}
        <ItemName>{name}</ItemName>
      </ItemPathComponent>
    </ConflictTitleRow>
  )
}

const StyledSelectedItem = styled(CheckableSelectItem)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: auto;
    `}
`

const ConflictList = styled.div`
  overflow: auto;
  margin-top: -1rem;
  margin-bottom: -1rem;
  height: 100%;
`
const SelectAllRow = styled(FlexRow)`
  gap: 1.5rem;
  color: ${({ theme }) => theme.colors.black.secondary};
  ${BannerPadding};
`

const SelectManySection = styled(FlexColumn)`
  gap: 1.5rem;
  ${BannerPadding};
`

export const MergeConflictsList = ({ repoId, mergeId, selectedConflictId }: Props) => {
  const navigate = useNavigate()
  const { merge, loading, failed, refresh } = useMerge(repoId, mergeId)
  const mergeIntoWorkspace = isMergeIntoWorkspace(merge)
  const conflictsCount = merge?.conflicts?.length || 0
  const resolvedCount = useMemo(
    () => merge?.conflicts.filter((conflict) => conflict.is_resolved).length || 0,
    [merge?.conflicts]
  )
  const [commitMessage, setCommitMessage] = useSessionStorage<string | null>(`merge.${mergeId}.commitMessage`, null)
  const onFinalized = useCallback(() => navigate(routeToMerges(repoId)), [navigate, repoId])
  const postMergeCommitAsync = usePostMergeCommitAsync(repoId, mergeId, commitMessage || '', onFinalized)
  const [selectedConflictIds, setSelectedConflictIds] = useState<string[]>([])
  const postConflictResolve = useCallback(() => {
    setSelectedConflictIds([])
    refresh()
    navigate(routeToMerge(repoId, mergeId))
  }, [mergeId, navigate, repoId, refresh])

  // Update selected conflict ids when merge is refreshed
  useEffect(() => {
    setSelectedConflictIds((currentSelectedConflictIds) => {
      const newConflictIds = merge?.conflicts.map((c) => c.conflict_id)
      const validSelectedConflictIds = currentSelectedConflictIds.filter((c) => newConflictIds?.includes(c))
      if (currentSelectedConflictIds.length !== validSelectedConflictIds.length) {
        infoToast('One of the chosen conflicts has been updated through workspace sync')
        return validSelectedConflictIds
      } else {
        return currentSelectedConflictIds
      }
    })
  }, [merge])

  const onApiError = useContext(PublishApiErrorContext)
  const resolveConflictSideAsync = useResolveConflictSideAsync(
    repoId,
    mergeId,
    () => {},
    () => {},
    onApiError
  )
  useEffect(() => {
    if (!loading && !merge && !failed) {
      log.info('Merge not found, redirecting back to merges page', repoId)
      infoToast('Merge not found')
      navigate(routeToMerges(repoId), { replace: true })
    }
  }, [failed, loading, merge, navigate, repoId])

  const checkConflict = (conflictId: string) => {
    if (selectedConflictIds.includes(conflictId)) {
      setSelectedConflictIds(selectedConflictIds.filter((c) => c !== conflictId))
    } else {
      setSelectedConflictIds([...selectedConflictIds, conflictId])
    }
  }

  const allChecked: boolean | null =
    conflictsCount === selectedConflictIds.length ? true : selectedConflictIds.length === 0 ? false : null
  const onAllChecked = () => {
    if (allChecked) {
      setSelectedConflictIds([])
    } else {
      setSelectedConflictIds(merge!.conflicts.map((c) => c.conflict_id))
    }
  }

  const resolveOtherSide = async () => {
    try {
      await Promise.all(
        selectedConflictIds.map((conflictId) => {
          const conflict = merge!.conflicts.find((c) => c.conflict_id === conflictId)
          if (conflict) {
            return resolveConflictSideAsync(conflictId, conflict.other.conflict_index_id)
          } else {
            return Promise.resolve()
          }
        })
      )
    } finally {
      postConflictResolve()
    }
  }

  const resolveBaseSide = async () => {
    try {
      await Promise.all(
        selectedConflictIds.map((conflictId) => {
          const conflict = merge!.conflicts.find((c) => c.conflict_id === conflictId)
          if (conflict) {
            return resolveConflictSideAsync(conflictId, conflict.base.conflict_index_id)
          } else {
            return Promise.resolve()
          }
        })
      )
    } finally {
      postConflictResolve()
    }
  }

  return (
    <>
      {loading || !merge ? (
        <Loader addPadding />
      ) : (
        <Container>
          <Title>
            <div>{pluralize(conflictsCount, 'Conflict')}</div>
            <FlexFiller />
            <div>{resolvedCount} resolved</div>
          </Title>
          <SelectAllRow>
            <Checkbox title="Select all" checked={allChecked} setChecked={onAllChecked} />
            <div>Select all</div>
          </SelectAllRow>
          <ConflictList>
            {merge!.conflicts.map((conflict) => (
              <StyledSelectedItem
                key={conflict.conflict_id}
                description={`Merge ${merge.id} Conflict ${conflict.conflict_id}`}
                disabled={conflict.is_resolved}
                isSelected={conflict.conflict_id === selectedConflictId}
                redirectToOnClick={routeToMergeConflict(merge!.repo_id, merge!.id, conflict.conflict_id)}
                title={<ConflictTitle selected={conflict.conflict_id === selectedConflictId} conflict={conflict} />}
                onCheck={() => checkConflict(conflict.conflict_id)}
                isChecked={selectedConflictIds.includes(conflict.conflict_id)}
              />
            ))}
          </ConflictList>
          <SelectManySection>
            <SmallButton disabled={selectedConflictIds.length === 0} onClick={resolveOtherSide}>
              Choose {OTHER_SIDE} for selected
            </SmallButton>
            <SmallButton disabled={selectedConflictIds.length === 0} onClick={resolveBaseSide}>
              Choose {BASE_SIDE} for selected
            </SmallButton>
          </SelectManySection>
          {!mergeIntoWorkspace && <CommitMessage value={commitMessage} setCommitMessage={setCommitMessage} />}
          <StyledCommitButton
            enabled={resolvedCount === conflictsCount && (mergeIntoWorkspace || Boolean(commitMessage))}
            postCommitAsync={postMergeCommitAsync}
            mergeIntoWorkspace={mergeIntoWorkspace}
          />
          <MergeCloseButton repoId={repoId} mergeId={mergeId} onMergeClosed={onFinalized} />
        </Container>
      )}
    </>
  )
}
