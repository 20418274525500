import { log } from '../utils/log'
import { ApiError } from './coreapi'
import isEmpty from 'lodash/isEmpty'
import axios, { AxiosError, AxiosRequestHeaders } from 'axios'
import { isSuccess } from './coreapi/core/request'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'

type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH'

const withApiErrorHandling = async <T>(url: string, method: HttpMethod | undefined, action: () => Promise<T>) => {
  try {
    return await action()
  } catch (e: any) {
    if (e instanceof ApiError) {
      throw e
    }
    throw new ApiError(
      { method: method || 'GET', url },
      {
        url,
        ok: false,
        status: 0,
        statusText: e.message,
        body: '',
        headers: {},
      },
      isEmpty(e) ? e.message : JSON.stringify(e)
    )
  }
}

const fetchRawAsync = async (url: string, headers?: HeadersInit, method?: HttpMethod, body?: any) =>
  await withApiErrorHandling(url, method, async () => {
    const response = await fetch(url, {
      method: method || 'GET',
      headers,
      body,
    })
    if (!response.ok) {
      throw new ApiError({ method: 'GET', url }, response, response.statusText)
    }
    return response
  })

const fetchGetAsync = async <T>(url: string, token: string | undefined): Promise<T> => {
  if (!token) {
    log.error('token is missing')
    throw Error()
  }

  let response = await fetchRawAsync(url, {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  })

  return response.json()
}

axiosRetry(axios, {
  retries: 5, // Number of retries
  retryDelay: (retryCount) => {
    return axiosRetry.exponentialDelay(retryCount)
  },
  retryCondition: (error: AxiosError) => {
    return isNetworkOrIdempotentRequestError(error) || error.response?.status === 503
  },
})

export const postWithProgressAsync = async (
  url: string,
  headers: AxiosRequestHeaders,
  body: any,
  onProgress: (progressPercentage: number) => void
) => {
  const response = await axios.request({
    method: 'POST',
    url,
    data: body,
    headers,
    onUploadProgress: (p) => {
      onProgress((100 * p.loaded) / p.total)
    },
  })
  if (!isSuccess(response.status)) {
    throw new ApiError(
      { method: 'POST', url },
      {
        url,
        ok: isSuccess(response.status),
        status: response.status,
        statusText: response.statusText,
        body: response.data,
        headers: response.headers,
      },
      response.statusText
    )
  }
}

export { fetchGetAsync, fetchRawAsync }
