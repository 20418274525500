import isArray from 'lodash/isArray'
import { useCallback, useEffect, useRef } from 'react'
import * as KeyCode from 'keycode-js'

type KeyboardEventCallback = (event: KeyboardEvent) => void
type KeyboardEventPredicate = (event: KeyboardEvent) => boolean

type KeyCodes = typeof KeyCode.CODE_ESCAPE | typeof KeyCode.CODE_ENTER | typeof KeyCode.CODE_TAB

export const useKeyPress = (
  keysOrPredicate: KeyCodes | KeyCodes[] | KeyboardEventPredicate,
  callback: KeyboardEventCallback,
  enabled: boolean
) => {
  const shouldHandleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (keysOrPredicate instanceof Function) {
        return keysOrPredicate(event)
      } else if (isArray(keysOrPredicate)) {
        return keysOrPredicate.includes(event.key as KeyCodes)
      } else {
        return event.key === keysOrPredicate
      }
    },
    [keysOrPredicate]
  )

  const eventListenerRef = useRef<KeyboardEventCallback>()

  useEffect(() => {
    eventListenerRef.current = (event: KeyboardEvent) => {
      if (shouldHandleKeyPress(event)) {
        event.preventDefault()
        event.stopPropagation()
        callback(event)
      }
    }
  }, [shouldHandleKeyPress, callback])

  useEffect(() => {
    if (!enabled) {
      return
    }
    const eventListener = (event: KeyboardEvent) => {
      eventListenerRef.current?.(event)
    }
    window.addEventListener('keydown', eventListener)
    return () => window.removeEventListener('keydown', eventListener)
  }, [enabled])
}
