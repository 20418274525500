import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { ChangeType, getChangeTypeColor } from '../../models/ChangeType'

const SIZE_PX = 18

export const GetStatusIconStyle = (color: string, theme: Theme, sizePx?: number, textColor?: string) => {
  sizePx = sizePx || SIZE_PX
  textColor = textColor || theme.colors.white.primary
  return css`
    height: ${sizePx}px;
    width: ${sizePx}px;
    background-color: ${color};
    color: ${textColor};
    border-radius: 0.2rem;

    svg {
      font-size: ${sizePx}px;
      margin-bottom: ${sizePx / 2}px;
    }
  `
}

export const StatusIcon = styled.div<{ changeType: ChangeType }>`
  ${({ theme, changeType }) =>
    GetStatusIconStyle(getChangeTypeColor(changeType, theme) || theme.colors.black.primary, theme)};
`

export const StatusText = styled.span<{ changeType: ChangeType }>`
  color: ${({ theme, changeType }) => getChangeTypeColor(changeType, theme) || theme.colors.black.primary};
`
