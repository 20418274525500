/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTag } from '../models/CreateTag';
import type { Error } from '../models/Error';
import type { ModifyTag } from '../models/ModifyTag';
import type { NewResourceId } from '../models/NewResourceId';
import type { Tag } from '../models/Tag';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RepositoryTagManipulationService {
    /**
     * Get a list of all tags in the repo.
     * @returns any A list of tag objects
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2TagListAll({
        repoId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
    }): CancelablePromise<{
        object: 'Tag';
        items: Array<Tag>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/tags',
            path: {
                'repo_id': repoId,
            },
        });
    }
    /**
     * Create a tag
     * @returns Error An error occurred
     * @returns NewResourceId Resource created
     * @throws ApiError
     */
    public static srcHandlersv2TagPost({
        repoId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        requestBody: CreateTag,
    }): CancelablePromise<Error | NewResourceId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/tags',
            path: {
                'repo_id': repoId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Tag with the same name already exists`,
                503: `Service unavailable`,
            },
        });
    }
    /**
     * Get tag details
     * @returns Tag Tag details returned
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2TagGet({
        repoId,
        tagId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Tag identifier
         */
        tagId: string,
    }): CancelablePromise<Tag | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/tags/{tag_id}',
            path: {
                'repo_id': repoId,
                'tag_id': tagId,
            },
        });
    }
    /**
     * Update a tag
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2TagPatch({
        repoId,
        tagId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Tag identifier
         */
        tagId: string,
        requestBody: ModifyTag,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/repos/{repo_id}/tags/{tag_id}',
            path: {
                'repo_id': repoId,
                'tag_id': tagId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Tag with the same name already exists`,
            },
        });
    }
    /**
     * Delete a tag
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2TagDelete({
        repoId,
        tagId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Tag identifier
         */
        tagId: string,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/repos/{repo_id}/tags/{tag_id}',
            path: {
                'repo_id': repoId,
                'tag_id': tagId,
            },
            errors: {
                503: `Service unavailable`,
            },
        });
    }
}
