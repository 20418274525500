import { Outlet } from 'react-router'
import { useUrlState } from '../../hooks/useUrlState'
import { FileViewerPathContext } from '../file/useFileAndBaseFile'
import { WorkspaceEditSidePanel } from '../sidepanel/WorkspaceEditSidePanel'
import { useEffect, useState } from 'react'

export const WorkspaceEditRoute = () => {
  const { repoId, workspaceId, '*': filePath } = useUrlState()
  const [selectedFileBasePath, setSelectedFileBasePath] = useState<string>()

  useEffect(() => {
    setSelectedFileBasePath(undefined)
  }, [filePath])

  return (
    <>
      <FileViewerPathContext.Provider
        value={{ basePath: selectedFileBasePath, setBasePath: setSelectedFileBasePath, setPath: () => {} }}
      >
        <WorkspaceEditSidePanel
          repoId={repoId!}
          workspaceId={workspaceId!}
          hasOutletContent
          selectedFilePath={filePath}
        />
        <Outlet />
      </FileViewerPathContext.Provider>
    </>
  )
}
