import { useItemListApi } from '../useApi'
import { Notification, DefaultService, type NotificationList, type Error } from '../../api/coreapi'
import { sortBy } from 'lodash'

const isNotificationList = (data: NotificationList | Error): data is NotificationList =>
  (data as NotificationList).items !== undefined

export const useNotifications = () =>
  useItemListApi<Notification>(['notifications'], async () => {
    const notifications = await DefaultService.srcHandlersv2NotificationsListAll()

    return isNotificationList(notifications)
      ? { items: sortBy(notifications.items, (notification) => notification.message) }
      : notifications
  })
