import { createContext, ReactNode, useCallback, useState } from 'react'
import { log } from '../../utils/log'
import { Workspace } from '../../api/coreapi'
import { DeleteWorkspaceDialog } from './DeleteWorkspaceDialog'
import { RenameWorkspaceDialog } from './RenameWorkspaceDialog'

type WorkspaceActions = 'delete' | 'rename'
export type WorkspaceActionExec = (action: WorkspaceActions, workspace: Workspace) => void

interface WorkspaceActionsContextProps {
  doAction: WorkspaceActionExec
}

export const WorkspaceActionContext = createContext<WorkspaceActionsContextProps>({
  doAction: () => {},
})

export const useWorkspaceActions = (): { doAction: WorkspaceActionExec; dialogs: () => ReactNode } => {
  const [workspace, setWorkspace] = useState<Workspace>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [renameDialogOpen, setRenameDialogOpen] = useState<boolean>(false)

  const doAction = useCallback<WorkspaceActionExec>((action, workspace) => {
    setWorkspace(workspace)
    switch (action) {
      case 'delete':
        setDeleteDialogOpen(true)
        break
      case 'rename':
        setRenameDialogOpen(true)
        break
      default:
        log.error(`Unknown action: ${action}`, { workspace })
    }
  }, [])

  const renderDialogs = () =>
    workspace && (
      <>
        <DeleteWorkspaceDialog isOpen={deleteDialogOpen} setOpen={setDeleteDialogOpen} workspace={workspace} />
        <RenameWorkspaceDialog isOpen={renameDialogOpen} setOpen={setRenameDialogOpen} workspace={workspace} />
      </>
    )

  return { doAction, dialogs: renderDialogs }
}
