import { useApi } from '../useApi'
import { FileEntry, RepositoryManipulationService } from '../../api/coreapi'
import { sanitizePath } from '../../utils/pathUtils'
import config from '../../env/config'

export const SEARCH_LIMIT = 10

const shouldSearch = (query?: string) => query && query.length >= config.QUERY_MIN_LENGTH

export const useTreeSearch = (repoId: string, refId: string, query?: string, dirsOnly?: boolean) =>
  useApi<string[]>(
    ['repos', refId, 'trees', refId, 'query', query],
    shouldSearch(query)
      ? async () =>
          (
            (await RepositoryManipulationService.srcHandlersv2TreeFiletree({
              repoId,
              refId,
              recurse: true,
              limit: SEARCH_LIMIT,
              includeDeleted: true,
              itemNameQuery: query,
              dirsOnly,
            })) as { items: FileEntry[] }
          ).items.map((item) => sanitizePath(item.path))
      : null
  )
