import React, { createContext } from 'react'
import { StyledDialogActions, StyledDialogContent } from './DialogStyle'
import { SecondaryButton } from '../base/PrimaryButton'
import Dialog from '@mui/material/Dialog'
import styled from '@emotion/styled'
import { TextBold } from '../base/TextStyle'
import { capitalize, pluralize } from '../../utils/textUtils'
import { changeToActionDescription, fromFileEntryStatus } from '../../models/ChangeType'
import { StatusIcon, StatusText } from '../base/StatusIcon'
import { FlexColumnStyle, FlexFiller, FlexRowStyle } from '../base/Flex'
import { getIcon } from '../tree/ChangeTypeIcons'
import { UserCard } from '../base/UserCard'
import { formatTimestamp, timeAgo } from '../../utils/dateUtils'
import { Tooltip } from '@mui/material'
import { useNavigate } from 'react-router'
import { routeToBranchView } from '../../Routes'
import { CodeRef } from '../base/CodeRef'
import { useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { CopyIcon } from '../base/CopyIcon'
import WarnIcon from '@mui/icons-material/Error'
import { RefFileStatus, RefsFileStatus } from '../../api/coreapi'

type OtherRefsFileStatus = {
  refsFileStatus?: RefsFileStatus
  setFileStatus: (refsFileStatus: RefsFileStatus) => void
}

export const OtherRefsFileStatusContext = createContext<OtherRefsFileStatus>({ setFileStatus: () => {} })

type Props = {
  repoId: string
  isOpen: boolean
  onClose: () => void
  refsFileStatus?: RefsFileStatus
}

const ClashIcon = styled(WarnIcon)`
  color: ${({ theme }) => theme.colors.warning.primary};
`

const Title = styled.div`
  ${TextBold};
  font-size: 1.2rem;
`

const DetailsRow = styled.div`
  ${FlexRowStyle};
  align-items: center;
  gap: 0.5rem;
`

const StyledUserCard = styled(UserCard)`
  width: fit-content;
`

const SubDetails = styled.div`
  ${FlexColumnStyle};
  margin-left: 2.5rem;
  gap: 0.5rem;
`

const RefFileStatusDetails = ({
  fileStatus,
  navigateToBranch,
}: {
  fileStatus: RefFileStatus
  navigateToBranch: () => void
}) => {
  const changeType = fromFileEntryStatus(fileStatus.status) || 'Intact'
  const commitStatus = fileStatus.workspace_id ? 'not-committed' : 'committed'
  const icon = getIcon(changeType)
  return (
    <DetailsRow>
      <StyledUserCard
        name={fileStatus.author.full_name || fileStatus.author.name}
        email={fileStatus.author.email}
        picture={fileStatus.author.email}
      />
      <SubDetails>
        <DetailsRow>
          <StatusIcon changeType={changeType}>{icon}</StatusIcon>{' '}
          <StatusText changeType={changeType}>{capitalize(changeToActionDescription(changeType))}</StatusText>
        </DetailsRow>
        <DetailsRow>
          {fileStatus.mtime && (
            <>
              At{' '}
              <Tooltip title={timeAgo(fileStatus.mtime)} arrow>
                <CodeRef>{formatTimestamp(fileStatus.mtime)}</CodeRef>
              </Tooltip>
            </>
          )}
          {fileStatus.branch_name && (
            <>
              On{' '}
              <Tooltip title="Click to jump to branch" arrow>
                <CodeRef onClick={navigateToBranch} clickable>
                  {fileStatus.branch_name}
                </CodeRef>
              </Tooltip>
              {` (${commitStatus})`}
            </>
          )}
        </DetailsRow>
      </SubDetails>
      <FlexFiller />
    </DetailsRow>
  )
}

export const FileStatusInOtherRefsDialog = ({ repoId, isOpen, onClose, refsFileStatus }: Props) => {
  const navigate = useNavigate()
  const copyToClipboard = useCopyToClipboardAction()
  if (!refsFileStatus) {
    return <></>
  }
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <StyledDialogContent maxHeight="48rem" width="48rem">
        <Title>
          <ClashIcon /> Files with changes in other branches/workspaces
        </Title>
        <div>
          <CodeRef>{refsFileStatus.path}</CodeRef>
          <CopyIcon copyToClipboard={copyToClipboard} text={refsFileStatus.path} title="Copy path" />
          was changed in {pluralize(refsFileStatus.file_statuses.length, 'other ref')}.
        </div>
        {refsFileStatus.file_statuses.map((fileStatus) => (
          <RefFileStatusDetails
            key={fileStatus.commit_id}
            fileStatus={fileStatus}
            navigateToBranch={() => {
              onClose()
              navigate(routeToBranchView(repoId, fileStatus.branch_id!, fileStatus.commit_id))
            }}
          />
        ))}
      </StyledDialogContent>
      <StyledDialogActions>
        <SecondaryButton onClick={onClose}>Close</SecondaryButton>
        <FlexFiller />
      </StyledDialogActions>
    </Dialog>
  )
}
