import { ReportHandler } from 'web-vitals'
import { log } from './log'

export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  try {
    if (onPerfEntry && onPerfEntry instanceof Function) {
      import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry)
        getFID(onPerfEntry)
        getFCP(onPerfEntry)
        getLCP(onPerfEntry)
        getTTFB(onPerfEntry)
      })
    }
  } catch (e) {
    log.warn('reportWebVitals failed', e)
  }
}
