import { ActionDialog } from '../dialogs/ActionDialog'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  postCommitAsync: () => Promise<void>
  mergeIntoWorkspace: boolean
}

const dialogText = (mergeIntoWorkspace: boolean) =>
  mergeIntoWorkspace
    ? {
        label: 'Apply',
        message: 'You are about to apply changes, do you want to proceed?',
        loadingMessage: 'Applying your changes...',
      }
    : {
        label: 'Commit',
        message: 'You are about to commit changes, do you want to proceed?',
        loadingMessage: 'Committing your changes...',
      }

export const CommitDialog = ({ isOpen, setOpen, postCommitAsync, mergeIntoWorkspace }: Props) => {
  const { label, message, loadingMessage } = dialogText(Boolean(mergeIntoWorkspace))
  return (
    <ActionDialog
      title={label}
      isOpen={isOpen}
      onConfirmAsync={postCommitAsync}
      message={message}
      setOpen={setOpen}
      confirmButtonLabel={label}
      loadingMessage={loadingMessage}
    />
  )
}
