import { useAgentApi } from '../../hooks/useAgentApi'
import { DefaultService } from '../../api/agentapi'
import { sortBy } from 'lodash'
import { milliseconds } from 'date-fns'
import { IsDesktopApp } from '../components/utils/DesktopAppApi'

const LOCAL_WORKSPACE_FETCH_INTERVAL: Duration = { seconds: 2 }

export const useLocalWorkspaces = () =>
  useAgentApi(
    'fetch_local_workspace',
    IsDesktopApp()
      ? async () => {
          try {
            const allWorkspaces = await DefaultService.getAllWorkspaces()
            return { data: sortBy(Object.values(allWorkspaces), 'RepoName', 'Path') }
          } catch (error: any) {
            return { error }
          }
        }
      : null,
    true,
    { refreshInterval: milliseconds(LOCAL_WORKSPACE_FETCH_INTERVAL) }
  )
