import { useApi } from '../useApi'
import { RepoUsers } from '../../models/RepoUsers'
import { AccessMode, CollaboratorsService } from '../../api/coreapi'
import { useCurrentUser } from './useCurrentUser'
import { useMemo } from 'react'
import first from 'lodash/first'

export const useRepoPermission = (repoId: string) => {
  const {
    data: users,
    loading: usersLoading,
    refresh,
  } = useApi<RepoUsers>(['repos', repoId, 'collaborators'], () =>
    CollaboratorsService.srcHandlersCollaborationListCollaborators({ repoId })
  )
  const { userData: currentUser, loading: currentUserLoading } = useCurrentUser()

  const accessMode = useMemo(() => {
    if (!currentUser || !users) {
      return AccessMode.READ
    }
    return (
      first(users.collaborators?.filter((user) => user.user_id === currentUser.username))?.access_mode ||
      AccessMode.READ
    )
  }, [currentUser, users])

  return { data: accessMode, loading: usersLoading || currentUserLoading, refresh }
}

export const isAdminOrAbove = (mode: AccessMode) => mode === AccessMode.ADMIN || mode === AccessMode.OWNER
