import styled from '@emotion/styled'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import { TextTitle } from '../base/TextStyle'
import { useCurrentUser } from '../../hooks/api/useCurrentUser'
import { Loader } from '../base/Loader'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { firstWord } from '../../utils/textUtils'
import { useNavigate } from 'react-router-dom'
import { routeToRepo, routeToWelcome } from '../../Routes'
import { SearchBox } from '../selector/SearchBox'
import { PaddingExtra } from '../base/PaddingStyle'
import isEmpty from 'lodash/isEmpty'
import { NoContentMessage } from '../selector/NoContentMessage'
import { Checkbox } from '../base/Checkbox'
import { useSessionStorage } from 'usehooks-ts'
import { filterBy } from '../../utils/objectUtil'
import { useRepos } from '../../hooks/api/useRepos'
import { RepoCard } from './RepoCard'
import { FlexColumn, FlexColumnStyle, FlexFiller, FlexRow } from '../base/Flex'
import { useNewRepoDialog } from '../dialogs/useNewRepoDialog'
import { useImportRepoDialog } from '../dialogs/useImportRepoDialog'
import { PrimaryButton, SecondaryButton } from '../base/PrimaryButton'
import { OptionsDropdown } from '../dropdown/OptionsDropdown'
import { Repo } from '../../api/coreapi'
import { ClonedRepoCard } from '../../desktop/components/ClonedRepoCard'
import { keyBy } from 'lodash'
import { log } from '../../utils/log'
import { isNetworkError } from '../../utils/errorClassify'
import { IsDesktopApp } from '../../desktop/components/utils/DesktopAppApi'
import { AgentDownDescription } from '../../desktop/components/AgentDownDescription'
import { useInitRepo } from '../../desktop/hooks/useInitRepo'
import { useLocalWorkspaces } from '../../desktop/hooks/useLocalWorkspaces'
import { ReattachWorkspaceDialog } from '../../desktop/components/ReattachWorkspaceDialog'
import { Tooltip } from '@mui/material'
import { SettingsBackupRestoreOutlined } from '@mui/icons-material'
import { MacDesktopUpdateNotice } from './MacDesktopUpdateNotice'
import { RecommendationModal } from '../dialogs/RecommendationModal'
import { useAnalytics } from '../../hooks/api/useAnalytics'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.black.primary};
  ${FlexColumnStyle};
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.padding.xl}rem;
  align-items: center;
  justify-content: start;
  gap: 2rem;
`

const Title = styled.div`
  ${TextTitle};
`

const Section = styled(FlexColumn)`
  max-height: 100%;
  width: 72rem;
  max-width: 100%;
  ${PaddingExtra};
  overflow: auto;
  gap: 1.5rem;
  align-items: start;
  justify-content: start;
  background-color: ${({ theme }) => theme.colors.white.primary};
  border-radius: 0.7rem;
  margin-bottom: 4rem;
`

const SearchRow = styled(FlexRow)`
  width: 100%;
  gap: 1rem;
  align-items: center;
`

const StyledSearch = styled(SearchBox)`
  margin: 0;
`

const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${({ theme }) => theme.padding.l}rem;
  margin-bottom: 4rem;
`

const NewRepoButton = ({
  openNewRepoDialog,
  openImportDialog,
}: {
  openNewRepoDialog: () => void
  openImportDialog: () => void
}) => (
  <OptionsDropdown
    items={[
      {
        key: 'new',
        title: (
          <FlexRow gap={0.5} centered={true}>
            <AddBoxOutlinedIcon />
            Create New Repository
          </FlexRow>
        ),
        onSelected: openNewRepoDialog,
      },
      {
        key: 'import',
        title: (
          <FlexRow gap={0.5} centered={true}>
            <ExitToAppOutlinedIcon />
            Import Git Repository
          </FlexRow>
        ),
        onSelected: openImportDialog,
      },
    ]}
    button={<PrimaryButton disabled={false}>New Repository</PrimaryButton>}
  />
)

const StyledLink = styled.div`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
  user-select: none;
`
const RecommendToAFriend = ({ onClick }: { onClick: () => void }) => (
  <StyledLink onClick={onClick}>Spread the word 🤗</StyledLink>
)

export const DashboardRoute = () => {
  const navigate = useNavigate()
  const [onlyMyRepos, setOnlyMyRepos] = useSessionStorage<boolean>('dashboard.myRepos', false)
  const { repos, loading: reposLoading, refresh: refreshRepos } = useRepos(onlyMyRepos)
  const [repoQuery, setRepoQuery] = useState<string>('')
  const { userData } = useCurrentUser()
  const userName = useMemo(() => firstWord(userData?.name || '') || userData?.email || '', [userData])
  const [isAgentDown, setIsAgentDown] = useState<boolean>(false)
  const [recommendationModalOpen, setRecommendationModalOpen] = useState<boolean>(false)
  const postAnalytics = useAnalytics()
  const openRecommendationModal = useCallback(() => {
    postAnalytics('RecommendationModalOpened', {})
    setRecommendationModalOpen(true)
  }, [postAnalytics])

  const noRepos = repos?.length === 0
  useEffect(() => {
    if (noRepos && !IsDesktopApp()) {
      navigate(routeToWelcome(), { replace: true })
    }
  }, [navigate, noRepos])

  const filteredRepos = useMemo(
    () => filterBy(repos || [], (repo) => [repo.repo_name, repo.repo_id], repoQuery),
    [repos, repoQuery]
  )

  return (
    <>
      <Container>
        <FlexColumn centered={true} gap={0.5}>
          <Title>Welcome Back{isEmpty(userName) ? '' : `, ${userName}`}</Title>
          {!isAgentDown && <RecommendToAFriend onClick={openRecommendationModal} />}
        </FlexColumn>
        <MacDesktopUpdateNotice />
        {isAgentDown && (
          <FlexRow>
            <AgentDownDescription />
          </FlexRow>
        )}
        <Section>
          {IsDesktopApp() && (
            <DesktopSearchBar
              onlyMyRepos={onlyMyRepos}
              refreshRepos={refreshRepos}
              setOnlyMyRepos={setOnlyMyRepos}
              setRepoQuery={setRepoQuery}
            />
          )}
          {IsDesktopApp() && <ClonedRepos repos={filteredRepos} setIsAgentDown={setIsAgentDown} />}
          <Title>All Repositories</Title>
          {!IsDesktopApp() && (
            <WebSearchBarAndActions
              onCreateRepo={refreshRepos}
              onlyMyRepos={onlyMyRepos}
              setOnlyMyRepos={setOnlyMyRepos}
              setRepoQuery={setRepoQuery}
            />
          )}
          <Cards>
            {reposLoading ? (
              <Loader addPadding />
            ) : isEmpty(filteredRepos) ? (
              <NoContentMessage>No repositories found</NoContentMessage>
            ) : (
              filteredRepos.map((repo) => <RepoCard repo={repo} key={repo.repo_id} onDeleted={() => refreshRepos()} />)
            )}
          </Cards>
        </Section>
        <RecommendationModal
          isOpen={recommendationModalOpen}
          setIsOpen={setRecommendationModalOpen}
          userName={userName}
        />
      </Container>
    </>
  )
}

type WebSearchBarAndActionsProps = {
  onCreateRepo: () => void
  setRepoQuery: (query: string) => void
  onlyMyRepos: boolean
  setOnlyMyRepos: (onlyMyRepos: boolean) => void
}
const WebSearchBarAndActions = ({
  onCreateRepo,
  setRepoQuery,
  onlyMyRepos,
  setOnlyMyRepos,
}: WebSearchBarAndActionsProps) => {
  const { Dialog: NewRepoDialog, setOpen: setNewRepoDialogOpen } = useNewRepoDialog({
    onCreated: (repoId: string) => {
      onCreateRepo()
      navigate(routeToRepo(repoId))
    },
    locationContext: 'Dashboard',
  })
  const { Dialog: ImportRepoDialog, setOpen: setImportRepoDialogOpen } = useImportRepoDialog({
    locationContext: 'Dashboard',
  })
  const navigate = useNavigate()
  return (
    <>
      <NewRepoDialog />
      <ImportRepoDialog />
      <SearchRow>
        <StyledSearch hint={'Search for a repo'} onChange={setRepoQuery} noPersistSearch />
        <div />
        <Checkbox title="Show only your repositories" checked={onlyMyRepos} setChecked={setOnlyMyRepos} />
        <span>Mine Only</span>
        <FlexFiller />
        <NewRepoButton
          openNewRepoDialog={() => setNewRepoDialogOpen(true)}
          openImportDialog={() => setImportRepoDialogOpen(true)}
        />
      </SearchRow>
    </>
  )
}

type DesktopSearchBarProps = {
  setRepoQuery: (query: string) => void
  onlyMyRepos: boolean
  setOnlyMyRepos: (onlyMyRepos: boolean) => void
  refreshRepos: () => void
}
const DesktopSearchBar = ({ setRepoQuery, onlyMyRepos, setOnlyMyRepos, refreshRepos }: DesktopSearchBarProps) => {
  const {
    Dialog: InitRepoDialog,
    Button: InitRepoButton,
    isOpen: initRepoDialogOpen,
  } = useInitRepo('Dashboard', () => {}, refreshRepos)
  const onOpen = useCallback((open: boolean) => {}, [])
  const [reattachWorkspaceDialogOpen, setReattachWorkspaceDialogOpen] = useState(false)
  const onModalOpen = useCallback(
    (open: boolean) => {
      setReattachWorkspaceDialogOpen(open)
      onOpen(open)
    },
    [setReattachWorkspaceDialogOpen, onOpen]
  )
  return (
    <SearchRow>
      <StyledSearch hint={'Search for a repo'} onChange={setRepoQuery} noPersistSearch />
      <div />
      <Checkbox title="Show only your repositories" checked={onlyMyRepos} setChecked={setOnlyMyRepos} />
      <span>Mine Only</span>
      <FlexFiller />
      {initRepoDialogOpen && <InitRepoDialog />}
      <InitRepoButton />
      {reattachWorkspaceDialogOpen && (
        <ReattachWorkspaceDialog
          isOpen={reattachWorkspaceDialogOpen}
          setOpen={onModalOpen}
          onSuccess={refreshRepos}
          locationContext={'Dashboard'}
        />
      )}
      <Tooltip
        title={
          'Re-attach to a local clone of a repo. Useful if you moved a repo to a different path, or if workspace is not showing up after detaching a portable drive.'
        }
        arrow
      >
        <SecondaryButton onClick={() => onModalOpen(true)}>
          <FlexRow gap={0.5} centered={true} centerContent={true}>
            <SettingsBackupRestoreOutlined />
            Attach Workspace
          </FlexRow>
        </SecondaryButton>
      </Tooltip>
    </SearchRow>
  )
}

interface ClonedReposProps {
  repos: Repo[]
  setIsAgentDown: (isDown: boolean) => void
}

const ClonedRepos = ({ repos, setIsAgentDown }: ClonedReposProps) => {
  const { data: localWorkspacesRes, loading } = useLocalWorkspaces()
  const error = localWorkspacesRes?.error
  if (error) {
    log.warn('Error fetching local workspace', error)
    setIsAgentDown(isNetworkError(error))
  } else {
    setIsAgentDown(false)
  }

  const localWorkspaces = localWorkspacesRes?.data
  const repoByRepoId = keyBy(repos, 'repo_id')
  return (
    !error && (
      <>
        <Title>Local Workspaces</Title>
        {loading ? (
          <Loader addPadding />
        ) : localWorkspaces && !isEmpty(localWorkspaces) ? (
          <Cards>
            {localWorkspaces
              .filter((ws) => repoByRepoId[ws.RepoID] !== undefined)
              .map((ws) => (
                <ClonedRepoCard repo={repoByRepoId[ws.RepoID]!} key={ws.WorkspaceID} workspace={ws} />
              ))}
          </Cards>
        ) : (
          <NoContentMessage>No repositories found</NoContentMessage>
        )}
      </>
    )
  )
}
