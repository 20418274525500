/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrganizationInvite = {
    /**
     * Unique identifier for the organization invite
     */
    id?: string;
    /**
     * ID of the organization
     */
    organization_id?: string;
    /**
     * ID of the user who is inviting to the organization
     */
    inviting_user_id?: string;
    /**
     * Email of the user who is invited to the organization
     */
    invited_email?: string;
    /**
     * Status of the invite
     */
    status?: OrganizationInvite.status;
    /**
     * Date and time when the invite was created
     */
    created?: string;
    /**
     * Date and time when the invite expires
     */
    expires?: string;
};
export namespace OrganizationInvite {
    /**
     * Status of the invite
     */
    export enum status {
        PENDING = 'PENDING',
        ACCEPTED = 'ACCEPTED',
        REJECTED = 'REJECTED',
    }
}

