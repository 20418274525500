import { ActionDialog } from '../dialogs/ActionDialog'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import React, { useCallback, useState } from 'react'
import { Commit, RepositoryCommitManipulationService } from '../../api/coreapi'
import { errorToast, infoToast } from '../../utils/toast'
import { FilePath } from '../base/TextStyle'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { isNotSupportedWithGitSyncError } from '../../utils/errorClassify'
import { CommitMessage } from '../commitAction/CommitMessage'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  repoId: string
  commit: Commit
}

const EditBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const useEditAsync = () => {
  const postAnalytics = useAnalytics()
  return useCallback(
    async (repoId: string, branchId: string, commitId: string, newMessage: string) => {
      try {
        await RepositoryCommitManipulationService.srcHandlersv2CommitPatch( {
          repoId,
          commitId: commitId,
          requestBody: { commit_message: newMessage },
        })
      } catch (e) {
        if (isNotSupportedWithGitSyncError(e)) {
          errorToast('Updating commit message is not supported for repositories synced with git')
          return
        }
        throw e
      }
      infoToast('Commit message updated successfully', true)
      postAnalytics('CommitMessageUpdated', {
        branch_id: branchId,
        commit_id: commitId
      })
    },
    [postAnalytics]
  )
}

export const EditMessageDialog = ({ isOpen, setOpen, repoId, commit }: Props) => {
  const editAsync = useEditAsync()
  const [newMessage, setNewMessage] = useState<string>(commit.commit_message!)

  return (
    <ActionDialog
      title="Edit commit message"
      isOpen={isOpen}
      onConfirmAsync={async () => {
        if (isEmpty(commit) || isEmpty(newMessage)) {
          return
        }
        await editAsync(repoId, commit.branch_id, commit.commit_id, newMessage)
      }}
      message={
        <EditBody>
          <div>
            Enter a new message for <FilePath>{commit?.commit_id}</FilePath>
          </div>
          <CommitMessage value={newMessage} setCommitMessage={setNewMessage} rows={6}/>
        </EditBody>
      }
      setOpen={setOpen}
      confirmButtonLabel="Update"
      loadingMessage="Updating message..."
      disabled={isEmpty(newMessage)}
    />
  )
}
