import React, { useCallback, useEffect, useState } from 'react'
import { Dialog, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import styled from '@emotion/styled'
import { AgentDownDialog } from './AgentDownDialog'
import { DefaultService } from '../../api/agentapi'
import { log } from '../../utils/log'
import { isNetworkError } from '../../utils/errorClassify'
import { errorToast, infoToast } from '../../utils/toast'
import isEmpty from 'lodash/isEmpty'
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop'
import { SupportService } from '../../api/coreapi'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import Avatar from '@mui/material/Avatar'
import { StyledDialogActions } from '../../components/dialogs/DialogStyle'
import { PrimaryButton, SecondaryButton } from '../../components/base/PrimaryButton'
import { Loader } from '../../components/base/Loader'
import { TextBold, TextTitle } from '../../components/base/TextStyle'
import { useUrlState } from '../../hooks/useUrlState'
import { UrgencyLevel } from '../../models/UrgencyLevel'
import { getLatestConsoleOutput } from '../../utils/logCapture'
import { useLocalStorage } from 'usehooks-ts'

const Message = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
  font-size: 1.1rem;
`

const Title = styled(Message)`
  ${TextBold};
  font-size: 1.5rem;
`

const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.colors.blue.light};
`

type SupportBundleDialogProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
}

const SupportBundleDialog = ({ isOpen, setOpen }: SupportBundleDialogProps) => {
  const { repoId } = useUrlState()
  const [agentIsDownDialogIsOpen, setAgentIsDownDialogIsOpen] = useState(false)
  const [supportMessage, setSupportMessage] = useState('')
  const [discordUsername, setDiscordUsername] = useLocalStorage('discordUsername', '')
  const [urgency, setUrgency] = useState(UrgencyLevel.Normal) // Default urgency set to 'Normal'
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSupportMessage('')
  }, [isOpen])

  const sendSupportBundle = useCallback(async () => {
    const fullMessage = discordUsername ? `${discordUsername}: ${supportMessage}` : supportMessage
    setLoading(true)
    try {
      if (isDesktopApp()) {
        const latestConsoleOutput = getLatestConsoleOutput()
        const extraInfo = latestConsoleOutput
          ? [{ Name: 'desktop_console_output.log', Value: latestConsoleOutput }]
          : undefined
        await DefaultService.generateSupportBundle({
          requestBody: { RepoID: repoId, Message: fullMessage, UrgencyLevel: urgency, ExtraInfo: extraInfo },
        })
      } else {
        await SupportService.srcHandlersSupportGetSupportBundleUploadLink({
          repoId: repoId,
          message: fullMessage,
          urgency: urgency,
        })
      }
      infoToast('Support ticket sent successfully. An engineer will reach out to you by email with more details.', true)
      setOpen(false)
    } catch (e) {
      if (isNetworkError(e)) {
        setAgentIsDownDialogIsOpen(true)
        return
      }
      errorToast('Failed to create and upload support bundle')
      log.error('Failed to create and upload support bundle from desktop app', e)
    } finally {
      setLoading(false)
    }
  }, [discordUsername, supportMessage, setOpen, repoId, urgency])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <StyledDialogContent>
        <Title>Support Ticket</Title>
        {loading ? (
          <LoadingContainer>
            <Loader />
            <Message>Uploading support ticket</Message>
          </LoadingContainer>
        ) : (
          <>
            <Message>Please provide a brief description of the issue you are experiencing:</Message>
            <StyledTextField
              value={supportMessage}
              autoFocus
              placeholder="Support message"
              rows={4}
              fullWidth
              multiline
              onChange={(event) => setSupportMessage(event.target.value)}
              margin="dense"
            />
            <StyledTextField
              value={discordUsername}
              placeholder="Discord alias (optional)"
              fullWidth
              onChange={(event) => setDiscordUsername(event.target.value)}
              margin="dense"
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="urgency-label">Urgency</InputLabel>
              <Select
                labelId="urgency-label"
                value={urgency}
                label="Urgency"
                onChange={(event) => setUrgency(Number(event.target.value))}
              >
                <MenuItem value={1}>
                  <div>
                    <strong>General guidance</strong> - Quality-of-life issue or feature request
                  </div>
                </MenuItem>
                <MenuItem value={2}>
                  <div>
                    <strong>Normal</strong> - Malfunctioning feature or time-sensitive question
                  </div>
                </MenuItem>
                <MenuItem value={3}>
                  <div>
                    <strong>High</strong> - Important functionality impaired
                  </div>
                </MenuItem>
                <MenuItem value={4}>
                  <div>
                    <strong>Urgent</strong> - Critical issue with no workaround, can't keep working
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
          </>
        )}
      </StyledDialogContent>
      {!loading && (
        <StyledDialogActions>
          <PrimaryButton onClick={sendSupportBundle} disabled={isEmpty(supportMessage)}>
            Send
          </PrimaryButton>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        </StyledDialogActions>
      )}
      <AgentDownDialog isOpen={agentIsDownDialogIsOpen} setOpen={setAgentIsDownDialogIsOpen} />
    </StyledDialog>
  )
}

const StyledDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.colors.white.primary};
`

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.white.primary};
    width: 34rem;
    max-width: 100%;
    align-items: center;
  }
`

const LoadingContainer = styled.div`
  padding: 9.1rem;
`

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue.primary};
  border: ${({ theme }) => theme.colors.blue.primary} solid 1px;
  background-color: ${({ theme }) => theme.colors.blue.hover};

  :hover {
    color: ${({ theme }) => theme.colors.white.primary};
    background-color: ${({ theme }) => theme.colors.blue.primary};
  }
`
const SupportButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
`

export const SupportButton = () => {
  const [openBundleDialog, setOpenBundleDialog] = useState(false)

  const handleClick = () => {
    setOpenBundleDialog(true)
  }

  return (
    <SupportButtonWrapper>
      <StyledAvatar onClick={handleClick}>
        <ContactSupportIcon />
      </StyledAvatar>
      <SupportBundleDialog isOpen={openBundleDialog} setOpen={setOpenBundleDialog} />
    </SupportButtonWrapper>
  )
}
