import { AccessMode } from '../../api/coreapi'
import { OptionsDropdown } from './OptionsDropdown'
import { Styleable } from '../../theme'

type AccessModeAndDelete = AccessMode | 'DELETE'

type Props = Styleable & {
  selectedMode: AccessMode
  onSelected: (mode: AccessModeAndDelete) => void
  disabled: boolean
  includeDelete: boolean
}

const AccessModeTitles: Record<AccessModeAndDelete, string> = {
  ADMIN: 'Admin',
  OWNER: 'Owner',
  READ: 'Can read',
  WRITE: 'Can write',
  DELETE: 'Delete',
}

const Options = Object.entries(AccessMode)
  .map(([_, mode]) => ({
    key: mode as string,
    title: AccessModeTitles[mode],
    mode: mode as AccessModeAndDelete,
  }))
  .filter((mode) => mode.mode !== AccessMode.OWNER)
Options.push({ key: 'DELETE', title: AccessModeTitles['DELETE'], mode: 'DELETE' })

export const AccessModeDropdown = ({ className, selectedMode, onSelected, disabled, includeDelete }: Props) => {
  return (
    <OptionsDropdown
      className={className}
      items={Options.filter((option) => includeDelete || option.mode !== 'DELETE')}
      onItemSelected={(mode) => onSelected(mode.mode)}
      button={AccessModeTitles[selectedMode]}
      disabled={disabled}
    />
  )
}
