import { ActionDialog } from '../../components/dialogs/ActionDialog'
import { AgentDownDescription } from './AgentDownDescription'

export const AgentDownDialog = ({ isOpen, setOpen }: { isOpen: boolean; setOpen: (isOpen: boolean) => void }) => (
  <ActionDialog
    title="Agent Down"
    message={<AgentDownDescription />}
    isOpen={isOpen}
    setOpen={setOpen}
    onConfirmAsync={async () => {}}
    confirmButtonLabel=""
    loadingMessage=""
    cancelButtonLabel={'Close'}
    noPrimaryAction
  />
)
