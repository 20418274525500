import { useCallback } from 'react'
import { NewResourceId, RepositoryCommitManipulationService } from '../../../api/coreapi'
import { useAnalytics } from '../../../hooks/api/useAnalytics'
import { isCommitFailedWithConflictsError } from '../../../utils/errorClassify'
import { errorToast } from '../../../utils/toast'

export const usePostCommitAsync = (
  repoId: string,
  workspaceId: string,
  commitMessage: string,
  filePaths: string[] | undefined,
  afterCommit: (commitId: string) => void
) => {
  const postAnalytics = useAnalytics()
  return useCallback(async () => {
    try {
      const { id: commitId } = (await RepositoryCommitManipulationService.srcHandlersv2WorkspaceCommitWorkspace({
        repoId,
        workspaceId,
        requestBody: {
          commit_message: commitMessage,
          include_paths: filePaths,
        },
      })) as NewResourceId
      afterCommit(commitId)
      postAnalytics('CommitPublished', { repo_id: repoId, workspace_id: workspaceId, commit_id: commitId })
    } catch (e) {
      if (isCommitFailedWithConflictsError(e)) {
        errorToast('Failed to commit your changes due to conflicts with the base branch')
        postAnalytics('CommitConflicts', {})
      } else {
        throw e
      }
    }
  }, [commitMessage, filePaths, afterCommit, repoId, workspaceId, postAnalytics])
}
