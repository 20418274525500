import React, { useCallback, useState } from 'react'
import { ApiError, DefaultService } from '../../api/agentapi'
import { errorToast, infoToast } from '../../utils/toast'
import { log } from '../../utils/log'
import { ActionDialog } from '../../components/dialogs/ActionDialog'

export const ResumeWorkspaceDialog = ({
  isOpen,
  setIsOpen,
  workspaceId,
  repoId,
}: {
  workspaceId: string
  repoId: string
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}) => {
  const [unregisterDialogOpen, setUnregisterDialogOpen] = useState(false)
  const resumeSync = useCallback(async () => {
    try {
      await DefaultService.resumeWorkspaceSync({ workspaceId })
      infoToast('Local sync resumed successfully')
    } catch (e) {
      if (e instanceof ApiError && e.status === 404) {
        setUnregisterDialogOpen(true)
      } else if (e instanceof ApiError && e.status === 409) {
        // The workspace is already syncing, so show the user a success message
        infoToast('Local sync resumed successfully')
      } else {
        errorToast()
        log.error('Unexpected error in resuming local sync', e)
      }
    }
  }, [workspaceId])
  const unregister = useCallback(async () => {
    try {
      await DefaultService.removeClonedWorkspace({ repoId, workspaceId })
      infoToast('Unregister local workspace successfully')
    } catch (e) {
      errorToast()
      log.error('Unexpected error in unregister local workspace', e)
    }
  }, [workspaceId, repoId])
  return (
    <>
      {isOpen && (
        <ActionDialog
          title="Resume Workspace Sync"
          isOpen={isOpen}
          onConfirmAsync={resumeSync}
          message="This will resume synchronization with your cloud workspace. Local changes will be uploaded, and remote changes will be downloaded."
          setOpen={setIsOpen}
          confirmButtonLabel="Resume"
          loadingMessage="Resuming the local workspace..."
        />
      )}
      {unregisterDialogOpen && (
        <ActionDialog
          title="Unable to resume workspace"
          isOpen={unregisterDialogOpen}
          onConfirmAsync={unregister}
          message={`Unable to resume the workspace as it has been moved or deleted. Would you like to remove it from the list of cloned repositories?`}
          setOpen={setUnregisterDialogOpen}
          confirmButtonLabel="Remove"
          loadingMessage="Disconnecting the local workspace..."
          isDanger={true}
        />
      )}
    </>
  )
}
