import merge from 'three-way-merge'
import { Conflicted, Outcome, Resolved } from 'three-way-merge/dist/types/src/outcomes'
import { BASE_SIDE, OTHER_SIDE } from './conflictUtils'

const joinFunction = (a: string[]) => a.join('')
const splitFunction = (s: string) => s.split(/\b/)

const resolveConflictsFunction = (results: Outcome[]) =>
  results
    .map((result: Outcome) => {
      if (result.isResolved()) {
        const joined = (result as Resolved).apply(joinFunction)
        return joined.result
      } else {
        const joined = (result as Conflicted).apply(joinFunction)
        const { left, right } = joined

        return [
          `<<<<<<< ${BASE_SIDE.toUpperCase()}`,
          left,
          '=======',
          right,
          `>>>>>>> ${OTHER_SIDE.toUpperCase()}`,
        ].join('\n')
      }
    })
    .join('\n')

export const threeWayMerge = (base: string, other: string, ancestor: string): string => {
  return merge(base, ancestor, other, {
    joinFunction,
    splitFunction,
    conflictFunction: resolveConflictsFunction,
  }).joinedResults()
}
