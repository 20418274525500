import { useViewer } from 'react-three-viewer'
import styled from '@emotion/styled'
import { RefObject, useEffect, useState } from 'react'
import { useRect } from 'react-use-rect'
import { Rect } from 'react-use-rect/src/use-rect'

type Props = {
  blob: Blob
  parentRef: RefObject<HTMLDivElement>
}

const CanvasWrap = styled.canvas`
  width: 100%;
  height: 100%;
  &:focus {
    outline: none;
  }
  canvas {
    width: 100%;
    height: 100%;
    &:focus {
      outline: none;
    }
  }
`

export const ThreeViewer = ({ blob, parentRef }: Props) => {
  const [ref, { load }] = useViewer()
  const [parentRect, setParentRect] = useState<Rect>()
  const [setRectRef] = useRect(
    (rect) => {
      setParentRect(rect)
    },
    { resize: true }
  )

  useEffect(() => {
    load(new File([blob], '', { type: blob.type }))
  }, [blob, load, parentRect])

  useEffect(() => {
    if (parentRef.current) {
      setRectRef(parentRef.current)
    }
  }, [parentRef, setRectRef])

  return (
    <div>
      <CanvasWrap ref={ref} height={parentRect?.height || 1000} width={parentRect?.width || 1000} />
    </div>
  )
}
