import { FileEntry } from '../api/coreapi'
import isNil from 'lodash/isNil'
import { groupBy } from './objectUtil'
import { getAncestorPaths, sanitizePath } from './pathUtils'
import { ChangeType, fromFileEntryStatus } from '../models/ChangeType'
import { log } from './log'
import { isDirectory } from '../models/fileMode'

export type ChangedItem = {
  changeType: ChangeType
  isDirectory: boolean
  path: string
  previousPath: string | undefined
}

export const getItemPath = (item: FileEntry) => sanitizePath(item.path)
const getItemPreviousPath = (item: FileEntry) => sanitizePath(item.prev_path || '')

const isUnexpectedChangeType = (existingItemChangeType: ChangeType, changeType: ChangeType) => {
  if (existingItemChangeType === changeType) {
    // We could get to this state when we fetch the items with paging and the tree got updated between the fetches
    return false
  }

  return existingItemChangeType !== 'Deleted' || changeType !== 'Added'
}

const compareItemChangeType = (item: FileEntry, existingChangedItem?: ChangedItem) => {
  const changeType = fromFileEntryStatus(item.status) || 'Intact'
  if (!existingChangedItem) {
    return changeType
  }
  if (isUnexpectedChangeType(existingChangedItem.changeType, changeType)) {
    log.error('Unexpected change types for already-existing compare item at same path', {
      existingChangedItem,
      item,
    })
    return changeType
  }
  return 'Modified'
}

const addImplicitModifiedDirs = (changes: Record<string, ChangedItem>): Record<string, ChangedItem> => {
  const paths = new Set<string>(Object.keys(changes))
  paths.forEach((path) => {
    getAncestorPaths(path)
      .filter((ancestor) => !paths.has(ancestor))
      .forEach((ancestorPath) => {
        paths.add(ancestorPath)
        changes[ancestorPath] = {
          path: ancestorPath,
          changeType: 'Modified',
          isDirectory: true,
          previousPath: undefined,
        }
      })
  })
  return changes
}

export const getChangesByPath = (items: FileEntry[] | undefined): Record<string, ChangedItem> | undefined =>
  isNil(items)
    ? undefined
    : addImplicitModifiedDirs(
        groupBy(items, getItemPath, (item, existingChangedItem) => ({
          changeType: compareItemChangeType(item, existingChangedItem),
          isDirectory: isDirectory(item.mode || 0),
          path: item.path,
          previousPath: getItemPreviousPath(item),
        }))
      )

export const isImplicit = (change: ChangedItem) => change.isDirectory && change.changeType === 'Modified'
