import { useMerges } from '../../hooks/api/useMerges'
import isEmpty from 'lodash/isEmpty'
import { NoContentMessage } from '../selector/NoContentMessage'
import { SelectItem } from '../selector/SelectItem'
import { Loader } from '../base/Loader'
import { routeToMerge } from '../../Routes'
import styled from '@emotion/styled'
import { Tabs } from './tabs/Tabs'
import { SIDE_PANEL_WIDTH_REM } from '../../theme'
import { useMergeTitle } from '../../hooks/api/useMergeTitle'
import { truncate } from '../../utils/textUtils'
import { Merge } from '../../api/coreapi'
import { FlexColumn } from '../base/Flex'
import { TextSmall } from '../base/TextStyle'

type Props = {
  repoId: string
  selectedMergeId?: string
}

const Container = styled(FlexColumn)`
  border-right: 1px solid ${({ theme }) => theme.colors.stroke};
  min-width: ${SIDE_PANEL_WIDTH_REM}rem;
`

const SubTitle = styled.div`
  ${TextSmall};
`

const formattedTitle = (title: { base: string; other: string }) => {
  return `Merge from ${truncate(title.other)} to ${truncate(title.base)}`
}

const MergeListItem = ({ merge, title }: { merge: Merge; title: { base: string; other: string } }) => {
  const userName = merge.user.full_name || merge.user.name || merge.id
  return (
    <FlexColumn gap={0.3}>
      <div>{formattedTitle(title)}</div>
      <SubTitle>
        {`Opened by ${userName || merge.user.email} ${userName ? `(${merge.user.email})` : ''}`.trim()}
      </SubTitle>
    </FlexColumn>
  )
}

export const MergesSidePanel = ({ repoId, selectedMergeId }: Props) => {
  const { merges, loading: mergesLoading } = useMerges(repoId)
  const { getTitle, loading: getTitleLoading } = useMergeTitle(repoId, merges)

  const loading = mergesLoading || getTitleLoading

  return (
    <Container>
      {loading ? (
        <Loader addPadding />
      ) : (
        <>
          <Tabs tabs={[{ selected: true, title: 'Unresolved Merges', redirectTo: '' }]} />
          {isEmpty(merges) ? (
            <NoContentMessage>You have no unresolved merges</NoContentMessage>
          ) : (
            <>
              {merges!.map((merge) => (
                <SelectItem
                  key={merge.id}
                  description={`Merge ${merge.id}`}
                  redirectToOnClick={routeToMerge(merge.repo_id, merge.id)}
                  title={<MergeListItem merge={merge} title={getTitle(merge)} />}
                  isSelected={selectedMergeId === merge.id}
                  clickable={selectedMergeId !== merge.id}
                />
              ))}
            </>
          )}
        </>
      )}
    </Container>
  )
}
