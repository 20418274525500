import { useDeleteAsync } from './delete/useDeleteAsync'
import { DeleteDialog } from './delete/DeleteDialog'
import { createContext, ReactNode, useCallback, useState } from 'react'
import { log } from '../../../utils/log'
import { MoveDialog } from './move/MoveDialog'
import { AgentDownDialog } from '../../../desktop/components/AgentDownDialog'
import { isNetworkError } from '../../../utils/errorClassify'
import { errorToast } from '../../../utils/toast'
import { useOpenLocalWorkspaceFolderItem } from '../../../desktop/hooks/useOpenLocalWorkspaceFolderItem'
import { StatusCodes } from 'http-status-codes'
import { ApiError } from '../../../api/agentapi'
import { ResetDialog } from './reset/ResetDialog'
import * as React from 'react'
import { useOpenLocalItemWithDefaultApp } from '../../../desktop/hooks/useOpenLocalItemWithDefaultApp'

type FileOpsActions = 'delete' | 'rename' | 'move' | 'open-folder' | 'open-with-default' | 'reset'
export type FileOpsActionExec = (action: FileOpsActions, filePath: string) => Promise<void>

type FileOpsActionContextProps = {
  doAction: FileOpsActionExec
}

export const FileOpsActionContext = createContext<FileOpsActionContextProps>({
  doAction: async () => {},
})

export const useFileOpsActions = (
  repoId: string,
  workspaceId: string
): { doAction: FileOpsActionExec; dialogs: () => ReactNode } => {
  const deleteFileAsync = useDeleteAsync(repoId, workspaceId)
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
  const [moveDialogIsOpen, setMoveDialogIsOpen] = useState(false)
  const [renameDialogIsOpen, setRenameDialogIsOpen] = useState(false)
  const [resetDialogIsOpen, setResetDialogIsOpen] = useState(false)
  const [agentIsDownDialogIsOpen, setAgentIsDownDialogIsOpen] = useState(false)
  const { openLocalWorkspaceFolderItem } = useOpenLocalWorkspaceFolderItem()
  const setAgentIsDown = useCallback(() => {
    setAgentIsDownDialogIsOpen(true)
  }, [])
  const { openLocalItemWithDefaultApp } = useOpenLocalItemWithDefaultApp(setAgentIsDown)
  const [filePath, setFilePath] = useState<string>('')

  const doAction = useCallback(
    async (action: FileOpsActions, filePath: string) => {
      setFilePath(filePath)
      switch (action) {
        case 'delete':
          setDeleteDialogIsOpen(true)
          break
        case 'rename':
          setRenameDialogIsOpen(true)
          break
        case 'move':
          setMoveDialogIsOpen(true)
          break
        case 'reset':
          setResetDialogIsOpen(true)
          break
        case 'open-folder':
          try {
            await openLocalWorkspaceFolderItem(repoId, workspaceId, filePath, true)
          } catch (e) {
            if (isNetworkError(e)) {
              setAgentIsDownDialogIsOpen(true)
              return
            }
            if (e instanceof ApiError && e.status === StatusCodes.NOT_FOUND) {
              errorToast('Path not found, make sure the workspace is cloned locally')
            } else {
              errorToast(`Error occurred while opening ${filePath}`)
              log.error('Error occurred while opening local folder', { error: e, workspaceId, filePath })
            }
          }
          break
        case 'open-with-default':
          await openLocalItemWithDefaultApp(repoId, workspaceId, filePath)
          break
        default:
          log.error(`Unknown action: ${action}`, { filePath })
          break
      }
    },
    [workspaceId, repoId, openLocalWorkspaceFolderItem, openLocalItemWithDefaultApp]
  )

  const renderDialogs = () => (
    <>
      <DeleteDialog
        isOpen={deleteDialogIsOpen}
        setOpen={setDeleteDialogIsOpen}
        filePath={filePath}
        postDeleteAsync={() => deleteFileAsync(filePath)}
      />
      <MoveDialog path={filePath} isOpen={moveDialogIsOpen} setOpen={setMoveDialogIsOpen} rename={false} />
      <MoveDialog path={filePath} isOpen={renameDialogIsOpen} setOpen={setRenameDialogIsOpen} rename={true} />
      <AgentDownDialog isOpen={agentIsDownDialogIsOpen} setOpen={setAgentIsDownDialogIsOpen} />
      <ResetDialog isOpen={resetDialogIsOpen} setOpen={setResetDialogIsOpen} resetAll={false} filePaths={[filePath]} />
    </>
  )

  return {
    doAction,
    dialogs: renderDialogs,
  }
}
