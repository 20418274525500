import config from './config'
import { sanitizeUrl } from '../utils/url'
import { IsDesktopApp } from '../desktop/components/utils/DesktopAppApi'
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop'
import { openInExternalBrowser } from '../desktop/components/utils/openInExternalBrowser'

const loginUrl = IsDesktopApp()
  ? `${sanitizeUrl(config.WEBAPP_URL!)}/desktopOauth2Login`
  : sanitizeUrl(config.WEBAPP_URL!)
const logoutUrl = IsDesktopApp()
  ? `${sanitizeUrl(config.WEBAPP_URL!)}/desktopOauth2Logout`
  : `${sanitizeUrl(config.WEBAPP_URL!)}/logout`

export const cognitoWebAppConfig = {
  aws_project_region: config.USER_POOL_REGION,
  aws_cognito_region: config.USER_POOL_REGION,
  aws_user_pools_id: config.USER_POOL_ID,
  aws_user_pools_web_client_id: config.USER_POOL_CLIENT_ID,
  oauth: {
    domain: config.AUTH_DOMAIN,
    scope: ['email', 'profile', 'openid', 'coreapi/write', 'coreapi/read', 'aws.cognito.signin.user.admin'],
    redirectSignIn: loginUrl,
    redirectSignOut: logoutUrl,
    responseType: 'code',
    urlOpener: isDesktopApp() ? openInExternalBrowser : undefined,
  },
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_SYMBOLS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
}

export const customConfig = (clientId: string, redirectUrl: string, additionalScopes?: string[]) => ({
  ...cognitoWebAppConfig,
  aws_user_pools_web_client_id: clientId,
  oauth: {
    ...cognitoWebAppConfig.oauth,
    scope: cognitoWebAppConfig.oauth.scope
      .concat(additionalScopes || [])
      .filter((scope) => scope !== 'aws.cognito.signin.user.admin'),
    redirectSignIn: redirectUrl,
    redirectSignOut: redirectUrl,
  },
})
