import { Repo } from '../../api/coreapi'
import { Ellipsis, ReverseEllipsis } from '../../components/base/Ellipsis'
import { TextRegular, TextSmall, TextTitle } from '../../components/base/TextStyle'
import styled from '@emotion/styled'
import { keyframes, Switch, Tooltip } from '@mui/material'
import { FlexColumn, FlexFiller, FlexRow, FlexRowStyle } from '../../components/base/Flex'
import TimeIcon from '@mui/icons-material/AccessTime'
import SyncIcon from '@mui/icons-material/Sync'
import React, { useCallback, useState } from 'react'
import { Popover } from 'antd'
import { changeColorOpacity } from '../../utils/colorUtils'
import trimEnd from 'lodash/trimEnd'
import PowerOffIcon from '@mui/icons-material/PowerOff'
import { Styleable } from '../../theme'
import { Anchor } from '../../components/base/Anchor'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { GitSyncLevel } from '../../models/GitSyncLevel'
import { ApiError, DefaultService, WorkspaceConfiguration } from '../../api/agentapi'
import { CodeRef } from '../../components/base/CodeRef'
import { ActionDialog } from '../../components/dialogs/ActionDialog'
import { errorToast, infoToast } from '../../utils/toast'
import { log } from '../../utils/log'
import { useNavigate } from 'react-router'
import { routeToWorkspaceEdit } from '../../Routes'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import SyncProblemIcon from '@mui/icons-material/SyncProblem'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import GroupsIcon from '@mui/icons-material/Groups'
import { useSyncProgress } from '../hooks/useSyncProgress'
import { PauseCircle, MoreVert, RefreshOutlined } from '@mui/icons-material'
import { OptionsDropdown } from '../../components/dropdown/OptionsDropdown'
import { getRevealActionName, useOpenLocalWorkspaceFolderItem } from '../hooks/useOpenLocalWorkspaceFolderItem'
import { RepoCollaboratorsDialog } from '../../components/dialogs/RepoCollaboratorsDialog'
import { AnalyticsEvent } from '../../models/Analytics'
import { SyncStatusType } from '../../components/header/syncstatus/SyncStatusContext'
import { ResumeWorkspaceDialog } from './ResumeWorkspaceDialog'

const Container = styled.div`
  width: calc(50% - ${({ theme }) => theme.padding.m}rem);
  background: ${({ theme }) => theme.colors.card.primary};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.xl}rem;
  border-radius: 0.7rem;
  box-sizing: border-box;
`

const Row = styled.div<{ isFirst?: boolean }>`
  ${FlexRowStyle};
  margin-top: ${({ isFirst }) => (isFirst ? 'unset' : '0.5rem')};
  width: 100%;
  gap: 1rem;
  align-items: center;
`

const EllipsisTitleButton = styled.div`
  ${TextTitle};
  ${Ellipsis};
  color: ${({ theme }) => theme.colors.black.primary};
  cursor: pointer;
`

const InfoText = styled.div`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.black.secondary};
`

const StyledTimeIcon = styled(TimeIcon)`
  ${TextSmall};
  margin-bottom: 2px;
`

const StyledSyncIcon = StyledTimeIcon.withComponent(SyncIcon)

const SettingsButton = styled.div`
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
  align-self: center;
`

const DropDownOption = styled(FlexRow)`
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
`

const PopoverText = styled.div`
  cursor: default;
  color: ${({ theme }) => theme.colors.white.primary};

  a {
    cursor: pointer;

    :hover {
      color: ${({ theme }) => changeColorOpacity(theme.colors.white.primary, 0.8)};
    }
  }
`

const SyncedPopover = ({ link }: { link: string }) => (
  <PopoverText>
    Synced with{' '}
    <Anchor target={link} description="Repo git sync url">
      {link}
    </Anchor>
  </PopoverText>
)

const StyledMoreVert = styled(MoreVert)`
  padding: 0, 0.5rem;
`

type Props = Styleable & {
  repo: Repo
  workspace: WorkspaceConfiguration
}

export const ClonedRepoCard = ({ className, repo, workspace }: Props) => {
  const navigate = useNavigate()
  const { syncStatus, syncingInfo } = useSyncProgress({ repoId: workspace.RepoID, workspaceId: workspace.WorkspaceID })
  return (
    <Container className={className}>
      <Row isFirst>
        <WorkspaceSyncStatus syncStatus={syncStatus} syncingInfo={syncingInfo} />
        <EllipsisTitleButton
          title={repo.repo_name}
          onClick={() => navigate(routeToWorkspaceEdit(repo.repo_id, workspace.WorkspaceID))}
        >
          {repo.repo_name}
        </EllipsisTitleButton>
        <FlexFiller />
        {syncStatus === SyncStatusType.Paused && <ResumeAction workspace={workspace} />}
        {syncStatus !== SyncStatusType.Paused && <PauseAction workspace={workspace} />}
        <MoreActionsDropdown workspace={workspace} />
      </Row>
      <Row>
        {repo.sync_git_repo_url && repo.sync_git_level === GitSyncLevel.FullHistoryWithSync && (
          <Popover content={<SyncedPopover link={trimEnd(repo.sync_git_repo_url, '.git')} />} placement="bottom">
            <InfoText>
              <StyledSyncIcon /> Synced to Git
            </InfoText>
          </Popover>
        )}
        <FlexFiller />
      </Row>
      <WorkspaceInfo workspace={workspace} syncingInfo={syncingInfo} />
    </Container>
  )
}

const SyncToggle = ({
  workspace,
  toggleAction,
  toggleState,
  analyticsEventName,
}: {
  workspace: WorkspaceConfiguration
  toggleAction: () => void
  toggleState: boolean
  analyticsEventName: AnalyticsEvent
}) => {
  const postAnalytics = useAnalytics()
  const onChange = () => {
    postAnalytics(analyticsEventName, { repo_id: workspace.RepoID, workspace_id: workspace.WorkspaceID })
    toggleAction()
  }
  return (
    <FlexRow centered={true}>
      Sync:
      <Switch checked={toggleState} color="primary" onChange={onChange} />
    </FlexRow>
  )
}

const PauseAction = ({ workspace }: { workspace: WorkspaceConfiguration }) => {
  const [pauseDialogOpen, setPauseDialogOpen] = useState(false)
  const pauseSync = useCallback(async () => {
    try {
      await DefaultService.pauseWorkspaceSync({ workspaceId: workspace.WorkspaceID })
      infoToast('Local sync paused successfully')
    } catch (e) {
      if (e instanceof ApiError && e.status === 409) {
        errorToast('Unable to pause local sync, the workspace is already paused.')
      } else if (e instanceof ApiError && e.status === 404) {
        errorToast('Unable to pause local sync, the workspace might have been deleted.')
      } else {
        errorToast()
        log.error('Unexpected error in pausing local sync', e)
      }
    }
  }, [workspace])
  return (
    <>
      {pauseDialogOpen && (
        <ActionDialog
          title="Pause Workspace Sync"
          isOpen={pauseDialogOpen}
          onConfirmAsync={pauseSync}
          message="This will pause synchronization with your cloud workspace. Local changes won't be uploaded, and remote changes won't be downloaded. You can resume sync at anytime."
          setOpen={setPauseDialogOpen}
          confirmButtonLabel="Pause"
          loadingMessage="Pausing the local workspace..."
        />
      )}
      <Tooltip title="Pause Sync" arrow>
        <SyncToggle
          analyticsEventName={'PauseSyncClicked'}
          workspace={workspace}
          toggleState={true}
          toggleAction={() => setPauseDialogOpen(true)}
        />
      </Tooltip>
    </>
  )
}

function ResumeAction({ workspace }: { workspace: WorkspaceConfiguration }) {
  const [resumeDialogOpen, setResumeDialogOpen] = useState(false)
  return (
    <>
      <ResumeWorkspaceDialog
        workspaceId={workspace.WorkspaceID}
        repoId={workspace.RepoID}
        isOpen={resumeDialogOpen}
        setIsOpen={setResumeDialogOpen}
      />
      <Tooltip title="Resume Sync" arrow>
        <SyncToggle
          analyticsEventName={'ResumeSyncClicked'}
          workspace={workspace}
          toggleState={false}
          toggleAction={() => setResumeDialogOpen(true)}
        />
      </Tooltip>
    </>
  )
}

const MoreActionsDropdown = ({ workspace }: { workspace: WorkspaceConfiguration }) => {
  const postAnalytics = useAnalytics()
  const [unregisterDialogOpen, setUnregisterDialogOpen] = useState(false)
  const [collaboratorsDialogOpen, setCollaboratorsDialogOpen] = useState(false)
  const [rescanDialogOpen, setRescanDialogOpen] = useState(false)
  const rescanWorkspace = useCallback(async () => {
    if (workspace.Paused) {
      errorToast('Cannot rescan a paused workspace')
    } else {
      try {
        await DefaultService.notifyScanRequired({ workspaceId: workspace.WorkspaceID })
        infoToast('Rescan workspace triggered successfully')
      } catch (e) {
        if (e instanceof ApiError && e.status === 404) {
          errorToast('Unable to send rescan call to the workspace, it might have been deleted or paused.')
        } else if (e instanceof ApiError && e.status === 503) {
          errorToast('Cannot rescan. ' + e.message)
        } else {
          errorToast()
          log.error('Unexpected error in rescan workspace', e)
        }
      }
    }
  }, [workspace])
  const unregister = useCallback(async () => {
    try {
      await DefaultService.removeClonedWorkspace({ repoId: workspace.RepoID, workspaceId: workspace.WorkspaceID })
      infoToast('Unregister local workspace successfully')
    } catch (e) {
      errorToast()
      log.error('Unexpected error in unregister local workspace', e)
    }
  }, [workspace])
  const moreOptionsItems = [
    {
      key: 'collaborators',
      title: (
        <DropDownOption>
          <GroupsIcon /> Repo Collaborators
        </DropDownOption>
      ),
      onSelected: () => {
        postAnalytics('RepoCollaboratorsDialogOpened', {
          repo_id: workspace.RepoID,
          workspace_id: workspace.WorkspaceID,
        })
        setCollaboratorsDialogOpen(true)
      },
    },
  ]
  if (!workspace.Paused) {
    moreOptionsItems.push({
      key: 'rescan',
      title: (
        <DropDownOption>
          <RefreshOutlined /> Rescan
        </DropDownOption>
      ),
      onSelected: () => {
        postAnalytics('RescanWorkspaceClicked', {
          repo_id: workspace.RepoID,
          workspace_id: workspace.WorkspaceID,
        })
        setRescanDialogOpen(true)
      },
    })
  }
  moreOptionsItems.push({
    key: 'unregister',
    title: (
      <DropDownOption>
        <PowerOffIcon /> Disconnect
      </DropDownOption>
    ),
    onSelected: () => {
      postAnalytics('WorkspaceUnregisterDialogOpened', {
        repo_id: workspace.RepoID,
        workspace_id: workspace.WorkspaceID,
      })
      setUnregisterDialogOpen(true)
    },
  })

  return (
    <>
      {unregisterDialogOpen && (
        <ActionDialog
          title="Disconnect Workspace"
          isOpen={unregisterDialogOpen}
          onConfirmAsync={unregister}
          message="This disconnects your local workspace from Diversion. Your files will stay in place, but they will no longer synchronize to your cloud workspace.
Cannot be undone - you’ll need to clone again."
          setOpen={setUnregisterDialogOpen}
          confirmButtonLabel="Disconnect"
          loadingMessage="Disconnecting the local workspace..."
          isDanger
        />
      )}
      {rescanDialogOpen && (
        <ActionDialog
          title="Rescan Local Workspace"
          isOpen={rescanDialogOpen}
          onConfirmAsync={rescanWorkspace}
          message="This will run a full scan of your local workspace. Useful if file changes were not detected correctly."
          setOpen={setRescanDialogOpen}
          confirmButtonLabel="Rescan"
          loadingMessage="Rescanning the workspace..."
        />
      )}
      {collaboratorsDialogOpen && (
        <RepoCollaboratorsDialog
          isOpen={collaboratorsDialogOpen}
          setOpen={setCollaboratorsDialogOpen}
          repoId={workspace.RepoID}
          repoName={workspace.RepoName!}
        />
      )}
      <OptionsDropdown
        items={moreOptionsItems}
        button={
          <SettingsButton>
            <StyledMoreVert />
          </SettingsButton>
        }
      />
    </>
  )
}

const ReverseOverflowText = styled.div`
  ${ReverseEllipsis};
  ${TextRegular};
  font-weight: 300;
  max-width: 24rem;
`

const WorkspaceInfoText = styled.div<{ clickable?: boolean }>`
  ${TextRegular};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'unset')};
  min-height: 1.5rem;
`
interface RepoWebActionsProps {
  workspace: WorkspaceConfiguration
  syncingInfo?: string
}
const WorkspaceInfo = ({ workspace, syncingInfo }: RepoWebActionsProps) => {
  const { openLocalWorkspaceFolderItem } = useOpenLocalWorkspaceFolderItem()
  const openLocalWorkspace = useCallback(async () => {
    try {
      await openLocalWorkspaceFolderItem(workspace.RepoID, workspace.WorkspaceID)
    } catch (e: any) {
      const msg = 'Failed opening local workspace'
      log.warn(msg, e)
      errorToast(msg)
    }
  }, [openLocalWorkspaceFolderItem, workspace])
  return (
    <FlexColumn>
      <FlexFiller />
      <WorkspaceInfoText>
        Branch name: <CodeRef>{workspace.BranchName}</CodeRef>
      </WorkspaceInfoText>
      <Tooltip title={getRevealActionName()} arrow>
        <WorkspaceInfoText clickable={true} onClick={openLocalWorkspace}>
          Path: <CodeRef>{workspace.Path}</CodeRef>
        </WorkspaceInfoText>
      </Tooltip>
      {!!syncingInfo ? (
        <WorkspaceInfoText>
          <FlexRow gap={0.25}>
            Syncing: <ReverseOverflowText>{syncingInfo}</ReverseOverflowText>
          </FlexRow>
        </WorkspaceInfoText>
      ) : (
        <WorkspaceInfoText></WorkspaceInfoText>
      )}
    </FlexColumn>
  )
}

const blink = keyframes`
  from { opacity: 0.2; }
  to { opacity: 1; }
`

const BlinkedCloudSync = styled(CloudSyncIcon)`
  animation: ${blink} 1s linear infinite;
  animation-direction: alternate;
`

const ColorSyncProblemIcon = styled(SyncProblemIcon)`
  color: ${({ theme }) => theme.colors.syncStatus.error};
`

const ColorSyncPausedIcon = styled(PauseCircle)`
  color: ${({ theme }) => theme.colors.syncStatus.paused};
`

type WorkspaceSyncStatusProps = {
  syncStatus: SyncStatusType
  syncingInfo?: string
}
const WorkspaceSyncStatus = ({ syncStatus, syncingInfo }: WorkspaceSyncStatusProps) => {
  return syncStatus === SyncStatusType.Error ? (
    <Tooltip title={!!syncingInfo ? `Sync error: ${syncingInfo}` : 'Sync error'} arrow>
      <ColorSyncProblemIcon />
    </Tooltip>
  ) : syncStatus === SyncStatusType.Synced ? (
    <Tooltip title={'Workspace is synced'} arrow>
      <PublishedWithChangesIcon />
    </Tooltip>
  ) : syncStatus === SyncStatusType.Paused ? (
    <Tooltip title={'Sync paused'} arrow>
      <ColorSyncPausedIcon />
    </Tooltip>
  ) : (
    <Tooltip title={`Syncing...`} arrow>
      <BlinkedCloudSync />
    </Tooltip>
  )
}
