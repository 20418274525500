import { Tree } from 'antd'
import styled from '@emotion/styled'
import { TreeProps } from 'antd/lib/tree'
import { TreeNode } from '../../models/Tree'
import * as React from 'react'

const StyledAntTree = <RecordType extends object = any>(T: typeof Tree) =>
  styled((props: TreeProps<RecordType> & { className?: string }) => {
    return <T {...props} />
  })``

export const AntTree = StyledAntTree<TreeNode>(Tree)
