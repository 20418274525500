import { useMemo } from 'react'
import uniq from 'lodash/uniq'
import { tryGet } from '../../utils/objectUtil'
import { useApi } from '../useApi'
import { Branch, Commit, Merge, RepositoryCommitManipulationService, Workspace } from '../../api/coreapi'
import { applyIfNotEmpty, stripPrefixOfCommitId, truncateCommitMessage } from '../../utils/textUtils'
import { useBranchesById } from './useBranches'
import { useWorkspaces } from './useWorkspaces'
import { workspaceName } from '../../components/base/WorkspaceName'
import { isPatchId, isShelfId } from '../../utils/idUtils'

type ResolvedTitle = {
  base: string
  other: string
}

const getRefTitle = (
  refId: string,
  workspaceById: Record<string, Workspace>,
  commitById: Record<string, Commit>,
  branchById: Record<string, Branch>
) => {
  const shelf = isShelfId(refId)
  const patch = isPatchId(refId)
  const workspace = workspaceById[refId]
  const branch = branchById[refId]
  return shelf
    ? 'Shelf'
    : workspace
      ? `Workspace ${workspaceName(workspace, tryGet(branchById, workspace.branch_id))}`
      : branch
        ? `Branch ${branch.branch_name}`
        : patch
          ? 'Patch'
          : `${stripPrefixOfCommitId(refId)}${
              applyIfNotEmpty(commitById[refId] && truncateCommitMessage(commitById[refId]!!), (m) => ` (${m})`) || ''
            }`.trim()
}

const getTitle = (
  merge: Merge,
  workspaceById: Record<string, Workspace>,
  commitById: Record<string, Commit>,
  branchById: Record<string, Branch>
): ResolvedTitle => {
  const { base_ref, other_ref } = merge
  const other = getRefTitle(other_ref, workspaceById, commitById, branchById)
  const base = getRefTitle(base_ref, workspaceById, commitById, branchById)
  return { other, base }
}

export const useMergeTitle = (repoId: string, merges?: Merge[]) => {
  const { workspacesById, workspacesLoading } = useWorkspaces(repoId)

  const allRefIds = useMemo(() => uniq(merges?.flatMap((merge) => [merge.base_ref, merge.other_ref])).sort(), [merges])
  const { data: commitById, loading: commitsLoading } = useApi<Record<string, Commit>>(
    ['repos', repoId, 'commits', 'bulk', ...allRefIds],
    allRefIds.length > 0
      ? () =>
          RepositoryCommitManipulationService.srcHandlersv2CommitGetBulk({
            repoId,
            refIds: allRefIds,
          })
      : null
  )

  const { branchesById, loading: branchesLoading } = useBranchesById(repoId)

  return {
    loading: workspacesLoading || commitsLoading || branchesLoading,
    getTitle: (merge: Merge) => getTitle(merge, workspacesById, commitById || {}, branchesById),
  }
}
