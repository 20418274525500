import React from 'react'
import { Box } from '@mui/material'
import styled from '@emotion/styled'

const CenteredBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const CenteredBoxComponent = ({ children }: { children: React.ReactNode }) => {
  return <CenteredBox>{children}</CenteredBox>
}

export default CenteredBoxComponent
