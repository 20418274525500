import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import styled from '@emotion/styled'
import { CopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { Styleable } from '../../theme'

type Props = Styleable & {
  copyToClipboard: CopyToClipboardAction
  text: string
  title: string
}

const StyledSpan = styled.span`
  cursor: pointer;
  margin-left: 0.5rem;

  > * {
    height: 1rem !important;
  }
`

export const CopyIcon = ({ className, copyToClipboard, text, title }: Props) => (
  <StyledSpan
    className={className}
    title={title}
    onClick={(ev) => {
      ev.stopPropagation()
      ev.preventDefault()
      copyToClipboard(text, 'Copied to clipboard')
    }}
  >
    <ContentCopyIcon />
  </StyledSpan>
)
