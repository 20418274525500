import { IntroBody, IntroTitle } from '../IntroPage'
import { InstallerTabs } from '../InstallerTabs'

export const DownloadPage = () => {
  return (
    <>
      <IntroTitle>Download and install Diversion for desktop</IntroTitle>
      <IntroBody>
        Install the desktop app to perform version control operations and to sync between your local machine and the
        cloud. It also comes with a convenient CLI if you prefer the terminal 👩‍💻.
      </IntroBody>
      <InstallerTabs />
    </>
  )
}
