import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import { ChangeType, getChangeTypeColor } from '../../models/ChangeType'
import { changeColorOpacity } from '../../utils/colorUtils'
import { GetStatusIconStyle } from '../base/StatusIcon'
import { TreeNode } from '../../models/Tree'
import { getIcon } from './ChangeTypeIcons'
import { useHideIntact } from './useHideIntact'
import { pluralize, RIGHT_ARROW } from '../../utils/textUtils'
import { FlexFiller, FlexRowStyle } from '../base/Flex'
import * as React from 'react'
import { useContext } from 'react'
import { Styleable, TREE_LEFT_MARGIN_REM_PADDED } from '../../theme'
import { RefFileStatus } from '../../api/coreapi'
import isEmpty from 'lodash/isEmpty'
import WarnIcon from '@mui/icons-material/Error'
import { Tooltip } from '@mui/material'
import size from 'lodash/size'
import { OtherRefsFileStatusContext } from '../dialogs/FileStatusInOtherRefsDialog'
import { FileOpsActionContext } from '../sidepanel/workspace/useFileOpsActions'
import { HoveredDropdown, menuAction } from '../dropdown/HoveredDropdown'
import { IsDesktopApp } from '../../desktop/components/utils/DesktopAppApi'
import { ActionValidationContext, ActionValidationState } from '../sidepanel/workspace/actionValidationContext'
import { getRevealActionName } from '../../desktop/hooks/useOpenLocalWorkspaceFolderItem'
import ResetIcon from '@mui/icons-material/Reply'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import { useUrlState } from '../../hooks/useUrlState'
import { useIsLocallyCloned } from '../../desktop/hooks/useIsLocallyCloned'

const getNodeColor = (theme: Theme, isSelected: boolean, isSearchResult: boolean) =>
  isSelected ? theme.colors.blue.hover : isSearchResult ? theme.colors.blue.light : 'unset'

const NodeRow = styled.div<{
  changeType: ChangeType
  isSelected: boolean
  isSearchResult: boolean
  treeCheckable: boolean
}>`
  ${FlexRowStyle};
  color: ${({ theme, changeType }) => getChangeTypeColor(changeType, theme) || theme.colors.black.primary};
  background-color: ${({ theme, isSelected, isSearchResult }) => getNodeColor(theme, isSelected, isSearchResult)};
  border: ${({ theme, isSearchResult }) => (isSearchResult ? `1px solid ${theme.colors.stroke}` : 'unset')};
  align-items: center;
  border-radius: 0.2rem;
  padding: 0 0.5rem;
  margin-left: ${({ treeCheckable }) => (treeCheckable ? 0 : -0.5)}rem;

  ${({ theme, changeType, isSelected }) =>
    !isSelected &&
    css`
      :hover {
        background-color: ${changeColorOpacity(
          getChangeTypeColor(changeType, theme) || theme.colors.black.secondary,
          0.1
        )};
      }
    `}
`

const StyledIcon = styled.div<{ changeType: ChangeType }>`
  ${({ theme, changeType }) =>
    GetStatusIconStyle(getChangeTypeColor(changeType, theme) || theme.colors.black.primary, theme)};
  margin-left: 0.5rem;
`

const ClashIcon = styled(WarnIcon)`
  color: ${({ theme }) => theme.colors.warning.primary};
`

type ExtendedTreeNodeProps = Styleable &
  TreeNode & {
    isSelected: boolean
    treeCheckable: boolean
    changedOnly: boolean
    isSearchResult: boolean
    onClick?: () => void
    otherStatuses?: RefFileStatus[]
    enableWorkspaceActions: boolean
  }
export const NodeTitle = ({
  className,
  title,
  path,
  prevPath,
  changeType,
  isSelected,
  treeCheckable,
  changedOnly,
  isSearchResult,
  onClick,
  otherStatuses,
  enableWorkspaceActions,
  isDirectory,
}: ExtendedTreeNodeProps) => {
  const icon = getIcon(changeType)
  const ref = useHideIntact(changeType, changedOnly, path)
  const { setFileStatus } = useContext(OtherRefsFileStatusContext)
  const { doAction } = useContext(FileOpsActionContext)
  const { actionValidationState } = useContext(ActionValidationContext)
  const { workspaceId } = useUrlState()
  const { isLocallyCloned } = useIsLocallyCloned(workspaceId)
  let basicWebActions: Record<string, menuAction> = {
    Delete: { icon: <DeleteForeverOutlinedIcon />, action: () => doAction('delete', path) },
    Rename: { icon: <DriveFileRenameOutlineOutlinedIcon />, action: () => doAction('rename', path) },
    Move: { icon: <DriveFileMoveOutlinedIcon />, action: () => doAction('move', path) },
    Reset: { icon: <ResetIcon />, action: () => doAction('reset', path) },
  }
  const localActions: Record<string, menuAction> = {}
  if (IsDesktopApp() && isLocallyCloned) {
    if (!isDirectory) {
      localActions['Open with Default App'] = {
        icon: <FileOpenIcon />,
        action: () => doAction('open-with-default', path),
      }
    }
    localActions[getRevealActionName()] = { icon: <LaunchIcon />, action: () => doAction('open-folder', path) }
  }
  const actions = { ...localActions, ...basicWebActions }
  return (
    <NodeRow
      ref={ref}
      className={className}
      title={prevPath ? `${prevPath} ${RIGHT_ARROW} ${path}` : path}
      changeType={changeType}
      isSelected={isSelected}
      isSearchResult={isSearchResult}
      treeCheckable={treeCheckable}
      onClick={onClick}
    >
      <div>{title}</div>
      <FlexFiller />
      <HoveredDropdown
        enabled={enableWorkspaceActions && actionValidationState === ActionValidationState.ReadyForAction}
        parentRef={ref}
        actions={actions}
      />
      {!isEmpty(otherStatuses) && (
        <Tooltip
          title={`This file is changed in ${pluralize(size(otherStatuses), 'other ref')}, click to see more details`}
          arrow
        >
          <ClashIcon onClick={() => setFileStatus({ path, file_statuses: otherStatuses! })} />
        </Tooltip>
      )}
      {icon && (
        <StyledIcon title={changeType} changeType={changeType}>
          {icon}
        </StyledIcon>
      )}
    </NodeRow>
  )
}

const RootNodeStyle = styled(NodeTitle)`
  margin-left: ${TREE_LEFT_MARGIN_REM_PADDED}rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
`

export const RootNode = ({ isSelected, onSelected }: { isSelected: boolean; onSelected?: (key: string) => void }) => {
  return (
    <RootNodeStyle
      key=""
      title="."
      path=""
      isDirectory
      isLeaf
      changeType="Intact"
      disableCheckbox
      isSelected={isSelected}
      treeCheckable={false}
      changedOnly={false}
      isSearchResult={false}
      onClick={() => onSelected?.('')}
      enableWorkspaceActions={false}
    />
  )
}
