import { ActionDialog } from '../dialogs/ActionDialog'
import { BranchEx } from '../../models/BranchEx'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { useCallback } from 'react'
import { RepositoryBranchManipulationService } from '../../api/coreapi'
import { errorToast, infoToast } from '../../utils/toast'
import { useUrlState } from '../../hooks/useUrlState'
import { useNavigate } from 'react-router'
import { routeToRepo } from '../../Routes'
import { useRefreshBranches } from '../../hooks/api/useBranches'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  branch: BranchEx | undefined
}

export const useDeleteAsync = () => {
  const navigate = useNavigate()
  const postAnalytics = useAnalytics()
  const { repoId, branchId: viewedBranchId } = useUrlState()
  const refreshBranches = useRefreshBranches(repoId)
  return useCallback(
    async (repoId: string, branchId: string) => {
      await RepositoryBranchManipulationService.srcHandlersv2BranchDelete({ repoId, branchId })
      infoToast('Branch deleted successfully', true)
      postAnalytics('BranchDeleted', {
        repo_id: repoId,
        branch_id: branchId,
      })
      refreshBranches()
      if (viewedBranchId === branchId) {
        navigate(routeToRepo(repoId))
      }
    },
    [navigate, postAnalytics, refreshBranches, viewedBranchId]
  )
}

export const DeleteDialog = ({ isOpen, setOpen, branch }: Props) => {
  const deleteAsync = useDeleteAsync()
  return (
    <ActionDialog
      title="Delete"
      isOpen={isOpen}
      onConfirmAsync={async () => {
        if (!branch) {
          return
        }
        if (branch.isDefault) {
          errorToast('Cannot delete the default branch')
          return
        }
        await deleteAsync(branch.repoId, branch.branch_id)
      }}
      message={`Are you sure you want to delete the branch '${branch?.branch_name}'? Workspaces that are currently checked out to this branch will remain in a detached state.`}
      setOpen={setOpen}
      confirmButtonLabel="Delete"
      loadingMessage="Deleting branch..."
      isDanger
    />
  )
}
