import { IsDesktopApp } from '../components/utils/DesktopAppApi'
import { useCallback } from 'react'
import { log } from '../../utils/log'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { DefaultService } from '../../api/agentapi'

export const useOpenLocalWorkspaceFolderItem = () => {
  const postAnalytics = useAnalytics()
  const openLocalWorkspaceFolderItem = useCallback(
    async (repoId: string, workspaceId: string, subPath?: string, selected: boolean = false) => {
      if (!IsDesktopApp()) {
        log.error('Open local workspace item is not supported in web app', { workspaceId, path: subPath })
        return
      }
      postAnalytics('OpenLocalWorkspace', { repo_id: repoId, workspace_id: workspaceId, path: subPath })
      return DefaultService.openLocalWorkspace({ requestBody: { workspaceId, subPath, selected } })
    },
    [postAnalytics]
  )

  return { openLocalWorkspaceFolderItem }
}

export const getRevealActionName = () => {
  return 'Show in ' + getFileManagerName()
}

export const getFastUploadActionName = () => {
  return 'FAST file upload via ' + getFileManagerName() + ' (recommended)'
}

export const getFileManagerName = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.includes('win')) {
    return 'Explorer'
  }
  if (userAgent.includes('mac')) {
    return 'Finder'
  }
  return 'File Manager'
}
