import { useParams } from 'react-router'

export type UrlParams = {
  repoId?: string
  mergeId?: string
  conflictId?: string
  branchId?: string
  commitId?: string
  compareCommitId?: string
  workspaceId?: string
  '*'?: string
}

export const useUrlState = () => useParams<UrlParams>()
