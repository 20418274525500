import { Styleable } from '../../theme'

export const MergeConflictsImage = ({ className }: Styleable) => (
  <img
    src={require('../../assets/images/merge.png')}
    alt="Merge Conflicts"
    width={16.5}
    height={24}
    className={className}
  />
)
