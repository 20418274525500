import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { ActionDialog } from './ActionDialog'
import { RepositoryManipulationService } from '../../api/coreapi'
import { infoToast } from '../../utils/toast'
import { FlexColumn } from '../base/Flex'
import { CodeRef } from '../base/CodeRef'

type DeleteRepoDialogProps = {
  repoId: string
  repoName: string
  afterDelete: () => void
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const Bold = styled.div`
  font-weight: 500;
`

export const DeleteRepoDialog = ({ repoId, repoName, afterDelete, isOpen, setIsOpen }: DeleteRepoDialogProps) => {
  const postAnalytics = useAnalytics()

  useEffect(() => {
    if (isOpen) {
      postAnalytics('DeleteRepoDialogOpened', { repo_id: repoId })
    }
  }, [isOpen, postAnalytics, repoId])

  return (
    <ActionDialog
      title="Delete Repository"
      isOpen={isOpen}
      setOpen={setIsOpen}
      onConfirmAsync={async () => {
        await RepositoryManipulationService.srcHandlersv2RepoDelete({
          repoId,
        })
        infoToast(`Repository ${repoName} deleted`, true)
        afterDelete()
      }}
      message={
        <FlexColumn gap={1}>
          <div>
            Are you sure you want to delete the repository <CodeRef>{repoName}</CodeRef>
          </div>
          <Bold>Warning! Once you delete a repository, it’s really gone - no respawns.</Bold>
        </FlexColumn>
      }
      confirmButtonLabel="Delete"
      loadingMessage="Deleting repository..."
      isDanger
    />
  )
}
