import { ActionDialog } from '../../../dialogs/ActionDialog'
import { Checkbox } from '../../../base/Checkbox'
import { useContext, useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from '../../../base/Flex'
import { usePostResetAsync } from './usePostResetAsync'
import { useUrlState } from '../../../../hooks/useUrlState'
import { ActionValidationContext } from '../actionValidationContext'
import { pluralize } from '../../../../utils/textUtils'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  filePaths?: string[]
  resetAll?: boolean
}

export const ResetDialog = ({ isOpen, setOpen, resetAll, filePaths }: Props) => {
  const [deleteAdded, setDeleteAdded] = useState(false)
  const { repoId, workspaceId } = useUrlState()
  const { revalidateState } = useContext(ActionValidationContext)
  const onResetConfirm = usePostResetAsync(repoId, workspaceId, !!resetAll, filePaths, async () => revalidateState())
  useEffect(() => {
    setDeleteAdded(false)
  }, [isOpen])

  useEffect(() => {
    if (!resetAll && (!filePaths || filePaths.length === 0)) {
      //Close the dialog if there are no files to reset
      setOpen(false)
    }
  }, [filePaths, resetAll, setOpen])
  return (
    <ActionDialog
      title="Discard"
      isOpen={isOpen}
      onConfirmAsync={() => onResetConfirm(deleteAdded)}
      message={
        <FlexColumn gap={1}>
          {resetAll && <div>You are about to discard all changes, do you want to proceed?</div>}
          {!resetAll && (
            <div>
              You are about to discard changes on {pluralize(filePaths?.length!, 'selected path')}, do you want to
              proceed?
            </div>
          )}
          <FlexRow gap={1}>
            <Checkbox title="Delete new files" checked={deleteAdded} setChecked={setDeleteAdded} />
            <div onClick={() => setDeleteAdded((b) => !b)}>Delete new files (this will also delete them locally) </div>
          </FlexRow>
        </FlexColumn>
      }
      setOpen={setOpen}
      confirmButtonLabel="Discard"
      loadingMessage="Discarding your changes..."
      isDanger
    />
  )
}
