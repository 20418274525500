import { useEffect, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'

export const usePageTitle = (title: string, restoreOnUnmount: boolean | undefined = true) => {
  const originalTitle = useRef(document.title)

  useEffect(() => {
    if (isEmpty(title)) {
      return
    }
    if (document.title !== title) {
      document.title = title
    }

    const prevTitle = originalTitle.current
    if (restoreOnUnmount) {
      return () => {
        document.title = prevTitle
      }
    } else {
      return
    }
  }, [restoreOnUnmount, title])
}
