import { ReactNode, useState } from 'react'
import styled from '@emotion/styled'
import { ExpandIcon } from '../../base/ExpandIcon'
import { SIDE_PANEL_WIDTH_REM } from '../../../theme'
import { BoxPadding } from '../../base/PaddingStyle'
import { FlexRow } from '../../base/Flex'

type Props = {
  title: string
  children: ReactNode
}

const Title = styled(FlexRow)`
  width: ${SIDE_PANEL_WIDTH_REM}rem;
  ${BoxPadding};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black.primary};
  background-color: ${({ theme }) => theme.colors.blue.light};
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-left: none;
`

const Text = styled.div`
  flex: 1;
  ${BoxPadding};
`

export const Section = ({ title, children }: Props) => {
  const [expanded, setExpanded] = useState(true)
  return (
    <>
      <Title onClick={() => setExpanded((current) => !current)}>
        <Text>{title}</Text>
        <div>
          <ExpandIcon expanded={expanded} />
        </div>
      </Title>
      {expanded && children}
    </>
  )
}
