import { Branch, Commit, Tag } from '../../api/coreapi'
import { formatTimestamp, timeAgo } from '../../utils/dateUtils'
import styled from '@emotion/styled'
import { RefNameText, TextBig, TextRegular, TextSmall } from '../base/TextStyle'
import { Ellipsis } from '../base/Ellipsis'
import { CopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { CopyIcon } from '../base/CopyIcon'
import { EMPTY_COMMIT_MESSAGE_PLACEHOLDER, truncateCommitMessage } from '../../utils/textUtils'
import { useNavigate } from 'react-router'
import { appendFilePath, routeToBranchView, routeToCommitView } from '../../Routes'
import { UserAvatar } from '../base/UserAvatar'
import { Tooltip } from '@mui/material'
import { Styleable } from '../../theme'
import { css } from '@emotion/react'
import { FlexColumn, FlexFiller, FlexRowStyle } from '../base/Flex'
import { useUrlState } from '../../hooks/useUrlState'

type Props = Styleable & {
  repoId: string
  commit: Commit
  tags?: Tag[]
  branch?: Branch
  copyToClipboard: CopyToClipboardAction
  linkeable: boolean
  highlight: boolean
  showUserImage?: boolean
  showUserName?: boolean
}

const Container = styled.div`
  ${FlexRowStyle};
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

const Description = styled(FlexColumn)`
  justify-content: center;
  ${TextRegular};
  gap: 0.5rem;
  line-height: 1.2rem;
`

const CommitMessage = styled.div<{ highlight: boolean }>`
  ${TextBig};
  ${FlexRowStyle};
  align-items: center;
  gap: 1rem;
  width: fit-content;
  background: ${({ theme, highlight }) => (highlight ? theme.colors.blue.hover : 'unset')};
  padding: ${({ highlight }) => (highlight ? '0 0.5rem' : 0)};
  border-radius: 1rem;
`

const CommitSubTitle = styled.div`
  ${FlexRowStyle};
  gap: 1.5rem;
  color: ${({ theme }) => theme.colors.black.secondary};
  ${TextSmall};
`

const Text = styled.div<{ refText?: boolean; clickable?: boolean }>`
  ${Ellipsis};
  ${({ refText }) =>
    refText &&
    css`
      ${RefNameText};
    `}
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`

const CommitTag = styled.div`
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.blue.primary};
  color: ${({ theme }) => theme.colors.blue.contrastText};
`

const StyledBranch = styled.div`
  ${TextRegular};
  font-weight: 300;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 6rem;
  background-color: ${({ theme }) => theme.colors.blue.hover};
  cursor: pointer;
  text-align: center;
  ${Ellipsis};
`

export const CommitCard = ({
  className,
  repoId,
  commit,
  copyToClipboard,
  tags,
  branch,
  linkeable,
  highlight,
  showUserImage,
  showUserName
}: Props) => {
  const navigate = useNavigate()
  const { '*': filePath } = useUrlState()
  const truncatedCommitMessage = truncateCommitMessage(commit)
  const fullCommitMessage = commit.commit_message || EMPTY_COMMIT_MESSAGE_PLACEHOLDER
  const commitViewRoute = branch
    ? routeToBranchView(repoId, branch.branch_id, commit.commit_id)
    : routeToCommitView(repoId, commit.commit_id)
  return (
    <Container className={className}>
      {showUserImage && (
        <UserAvatar
          picture={commit.author.image}
          name={commit.author.full_name || ''}
          email={commit.author.email || ''}
        />
      )}
      <Description>
        <CommitMessage highlight={highlight} title={fullCommitMessage} >
          <CopyIcon copyToClipboard={copyToClipboard} text={fullCommitMessage} title="Copy commit message" />
          {truncatedCommitMessage}
          {tags?.map((tag) => (
            <CommitTag title="Tag" key={tag.id}>
              {tag.name}
            </CommitTag>
          ))}
        </CommitMessage>
        <CommitSubTitle>
          <Tooltip title={timeAgo(commit.created_ts)} arrow>
            <Text>{formatTimestamp(commit.created_ts)}</Text>
          </Tooltip>
          <Text
            refText
            clickable={linkeable}
            onClick={(ev) => {
              if (!linkeable) {
                return
              }
              ev.stopPropagation()
              navigate(filePath ? appendFilePath(commitViewRoute, filePath) : commitViewRoute)
            }}
          >
            <CopyIcon copyToClipboard={copyToClipboard} text={commit.commit_id} title="Copy commit id" />
            <Tooltip title={linkeable ? 'Go to commit' : truncatedCommitMessage} arrow>
              <span>{commit.commit_id}</span>
            </Tooltip>
          </Text>
          {showUserName && <Text>{commit.author.full_name || commit.author.email}</Text>}
        </CommitSubTitle>
      </Description>
      <FlexFiller />
      {branch && (
        <Tooltip title={branch.branch_name} arrow>
          <StyledBranch
            onClick={(ev) => {
              ev.stopPropagation()
              navigate(routeToBranchView(repoId, branch?.branch_id, commit.commit_id))
            }}
          >
            {branch.branch_name}
          </StyledBranch>
        </Tooltip>
      )}
    </Container>
  )
}
