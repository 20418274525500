import { ForwardedRef, forwardRef, ReactNode, useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Ellipsis } from '../base/Ellipsis'
import { SIDE_PANEL_WIDTH_REM, Z_INDEX_TOPMOST } from '../../theme'
import { Loader } from '../base/Loader'
import { OverlayFiller } from '../base/OverlayFiller'
import { TextSmall } from '../base/TextStyle'
import { ExpandIcon } from '../base/ExpandIcon'
import { BoxPadding } from '../base/PaddingStyle'
import { stopClickPropagation } from '../../utils/onClickUtils'
import { Rect, useRect } from 'react-use-rect'
import { FlexRowStyle } from '../base/Flex'

export type ExpandableProps = {
  expanded: boolean
  setExpanded: (expanded: boolean) => void
}

type RefDropdownProps = ExpandableProps & {
  loading: boolean
  title: string
  selectedTitle?: string | JSX.Element
  children: ReactNode
  offsetLeftPx: number
  disableExpandStateControl?: boolean
  setDropdownWidth?: (width: number) => void
}

const ExpandHeaderContainer = styled.div<{ disableExpandStateControl: boolean }>`
  ${BoxPadding};
  ${FlexRowStyle};
  width: ${SIDE_PANEL_WIDTH_REM}rem;
  cursor: ${({ disableExpandStateControl }) => (disableExpandStateControl ? 'unset' : 'pointer')};
`

const ExpandHeaderTitleSection = styled.div`
  ${Ellipsis};
  text-align: start;
  flex: 1;
  padding: 0 ${({ theme }) => theme.padding.m}rem;
`

const Title = styled.span`
  ${TextSmall};
`

const SelectedItem = styled.div`
  ${Ellipsis};
`

const ExpandButton = styled.div`
  display: flex;
  align-items: center;
`

const ExpandHeader = forwardRef(
  (
    {
      loading,
      expanded,
      setExpanded,
      title,
      selectedTitle,
      disableExpandStateControl,
    }: Omit<RefDropdownProps, 'children'>,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <ExpandHeaderContainer
      ref={ref}
      disableExpandStateControl={disableExpandStateControl || loading}
      onClick={() => {
        !loading && !disableExpandStateControl && setExpanded(!expanded)
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <ExpandHeaderTitleSection>
            <Title>{title}</Title>
            {selectedTitle && <SelectedItem>{selectedTitle}</SelectedItem>}
          </ExpandHeaderTitleSection>
          {!disableExpandStateControl && (
            <ExpandButton>
              <ExpandIcon expanded={expanded} />
            </ExpandButton>
          )}
        </>
      )}
    </ExpandHeaderContainer>
  )
)

const StyledOverlayFiller = styled(OverlayFiller)`
  z-index: ${Z_INDEX_TOPMOST};
`

const ContentContainer = styled.div<{ offsetLeftPx: number; widthPx: number }>`
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: ${({ offsetLeftPx }) => offsetLeftPx}px;
  width: ${({ widthPx }) => widthPx}px;
  background: ${({ theme }) => theme.colors.white.primary};
  z-index: ${Z_INDEX_TOPMOST};
`

export const RefDropdown = (props: RefDropdownProps) => {
  const [dropdownWidth, setDropdownWidth] = useState<number>(0)
  const [headerRect, setHeaderRect] = useState<Rect>()
  const [headerRef] = useRect(setHeaderRect, { resize: true })
  const { disableExpandStateControl, setExpanded } = props
  const onClickOutsideDropdown = useCallback(
    () => !disableExpandStateControl && setExpanded(false),
    [disableExpandStateControl, setExpanded]
  )
  const { offsetLeftPx, setDropdownWidth: propagatedSetDropdownWidth } = props
  useEffect(() => {
    if (!headerRect) {
      return
    }
    const width = headerRect.right - offsetLeftPx
    setDropdownWidth(width)
    propagatedSetDropdownWidth && propagatedSetDropdownWidth(width)
  }, [headerRect, offsetLeftPx, propagatedSetDropdownWidth])
  return (
    <>
      {props.expanded && !props.loading && (
        <StyledOverlayFiller onClick={onClickOutsideDropdown}>
          <ContentContainer widthPx={dropdownWidth} offsetLeftPx={props.offsetLeftPx} onClick={stopClickPropagation}>
            {props.children}
          </ContentContainer>
        </StyledOverlayFiller>
      )}
      <ExpandHeader ref={headerRef} {...props} />
    </>
  )
}
