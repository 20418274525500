import styled from '@emotion/styled'
import { FlexRow } from './Flex'
import { BannerPadding } from './PaddingStyle'

export const WarningContainer = styled(FlexRow)`
  ${BannerPadding};
  text-align: center;
  align-items: center;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colors.blue.light};
  color: ${({ theme }) => theme.colors.black.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
`
