import { createContext, useContext, useEffect } from 'react'
import { Outlet } from 'react-router'

type MergesContext = {
  inMergesContext: boolean
  setInMergesContext: (inContext: boolean) => void
}

export const InMergesContext = createContext<MergesContext>({
  inMergesContext: false,
  setInMergesContext: () => {},
})

export const MergesRoute = () => {
  const { setInMergesContext } = useContext(InMergesContext)

  useEffect(() => {
    setInMergesContext(true)
    return () => setInMergesContext(false)
  }, [setInMergesContext])

  return <Outlet />
}
