import styled from '@emotion/styled'
import ResetIcon from '@mui/icons-material/Reply'
import { Styleable } from '../../../../theme'
import { css } from '@emotion/react'
import { GetStatusIconStyle } from '../../../base/StatusIcon'
import { Tooltip } from '@mui/material'

const RESET_BUTTON_SIZE_PX = 22

const StyledButton = styled.div<{ enabled: boolean }>`
  ${({ theme }) =>
    GetStatusIconStyle(theme.colors.red.primary, theme, RESET_BUTTON_SIZE_PX, theme.colors.red.contrastText)};
  background-color: ${({ theme, enabled }) => (enabled ? theme.colors.red.primary : theme.colors.black.secondary)};
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};
  border-radius: 0.2rem;
  ${({ enabled, theme }) =>
    enabled &&
    css`
      :hover {
        background-color: ${theme.colors.red.dark};
      }
    `}
`

type Props = Styleable & {
  enabled: boolean
  onClick: () => void
}

export const ResetButton = ({ className, enabled, onClick }: Props) => {
  return (
    <Tooltip title="Reset" arrow>
      <StyledButton
        className={className}
        enabled={enabled}
        onClick={() => {
          enabled && onClick()
        }}
      >
        <ResetIcon />
      </StyledButton>
    </Tooltip>
  )
}
